/* @flow */

import {useQuery} from '@apollo/react-hooks';

import type {
    GetCallActivity as GetCallActivityQuery,
    GetCallActivityVariables,
    GetCallActivity_activity as Activity,
} from 'nutshell-graphql-types';

import {safelyGetGraphQLErrorMessage, makeDataSafe} from 'nutshell-core/utils/graphql-errors';

import GET_CALL_ACTIVITY from '../queries/get-call-activity.graphql';

export const useGetCallActivity = (
    activityId: ?string
): ({
    activity: ?Activity,
    isLoading: boolean,
    error: ?string,
    refetch: () => Promise<*>,
    startPolling: (number) => void,
    stopPolling: () => void,
}) => {
    const {loading, data, error, refetch, startPolling, stopPolling} = useQuery<
        GetCallActivityQuery,
        GetCallActivityVariables,
    >(GET_CALL_ACTIVITY, {
        variables: {
            // $FlowIgnore no id is covered by skip
            activityId,
        },
        skip: !activityId,
    });

    const {activity} = makeDataSafe(data);

    return {
        activity,
        isLoading: loading,
        error: safelyGetGraphQLErrorMessage(error),
        refetch,
        startPolling,
        stopPolling,
    };
};
