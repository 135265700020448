/* @flow */

import * as React from 'react';

import {Button} from 'shells/button';

import type {Item} from './types';

import './bulk-select-button.css';

type Props = {
    selectableItemsList: ?(Item[]),
    onClick: (isSelectingAll: boolean) => void,
};

export function BulkSelectButton(props: Props) {
    const numSelectedItems = props.selectableItemsList
        ? props.selectableItemsList.filter((item) => item.isChecked).length
        : 0;

    // Determines whether all items will be selected or unselected when the button is clicked
    const isSelectingAll = numSelectedItems === 0;

    const shouldShowButton = props.selectableItemsList && props.selectableItemsList.length > 3;

    return (
        <div styleName='container'>
            {shouldShowButton ? (
                <Button
                    variant='text-primary'
                    noPadding={true}
                    onClick={() => props.onClick(isSelectingAll)}
                >
                    {isSelectingAll ? 'Select all' : 'Unselect All'}
                </Button>
            ) : undefined}
        </div>
    );
}
