/* @flow */

import * as React from 'react';

type Props = {
    width?: number,
};

export function SmsDripSequence(props: Props) {
    return (
        <svg
            width={props.width}
            viewBox='0 0 150 184'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect
                x='-0.35273'
                y='0.35273'
                width='93.5802'
                height='178.437'
                rx='9.64727'
                transform='matrix(-1 -8.74228e-08 -8.74228e-08 1 93.5802 4.78564)'
                fill='#4D4540'
                stroke='#4D4540'
                strokeWidth='0.705459'
            />
            <rect
                x='-1'
                y='1'
                width='92.2857'
                height='177.143'
                rx='9'
                transform='matrix(-1 -8.74228e-08 -8.74228e-08 1 97.0005 0.071289)'
                fill='#EBE8E8'
                stroke='#4D4540'
                strokeWidth='2'
            />
            <rect
                x='-0.35273'
                y='0.35273'
                width='26.3421'
                height='4.31533'
                rx='2.15766'
                transform='matrix(-1 -8.74228e-08 -8.74228e-08 1 64.6797 6.35937)'
                fill='#4D4540'
                stroke='#4D4540'
                strokeWidth='0.705459'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M29 56.0713C35.6274 56.0713 41 50.6987 41 44.0713C41 37.4439 35.6274 32.0713 29 32.0713C22.3726 32.0713 17 37.4439 17 44.0713C17 50.6987 22.3726 56.0713 29 56.0713ZM32.7138 47.599C34.3598 48.02 34.9148 48.4028 34.9148 49.0344C34.9148 49.0344 35.087 49.1301 35.1062 50.1636H22.4746C22.4746 49.1301 22.6469 49.0535 22.6469 49.0535C22.6469 48.422 23.2019 48.0392 24.8478 47.6181C25.6899 47.4076 26.4172 46.91 26.4172 46.91C26.532 46.8334 26.4746 46.3358 26.4746 46.3358L27.221 46.2784C27.221 46.2784 27.3359 46.355 27.221 45.532C27.221 45.532 26.5894 45.3598 26.5512 44.0583C26.5512 44.0583 26.0727 44.2114 26.0344 43.4267C26.0287 43.2668 25.9872 43.1137 25.9475 42.9674C25.8543 42.6235 25.7713 42.3173 26.1875 42.0488L25.8813 41.2258C25.8813 41.2258 25.2689 37.9339 27.0105 38.4124C26.2832 37.532 31.1636 36.6708 31.489 39.4459C31.489 39.4459 31.7186 40.9387 31.489 41.9722C31.489 41.9722 32.1971 41.8765 31.7186 43.2545C31.7186 43.2545 31.4507 44.2497 31.0488 44.02C31.0488 44.02 31.1062 45.2641 30.4746 45.4746C30.4746 45.4746 30.5129 46.1445 30.5129 46.1827L31.1253 46.2784C31.1253 46.2784 31.0296 46.8334 31.1445 46.8909C31.1445 46.8909 31.8526 47.3885 32.7138 47.599Z'
                fill='#BF4200'
            />
            <path
                d='M22.2709 80.8246C22.2637 78.314 24.293 76.2729 26.8036 76.2657L114.25 76.0131C116.761 76.0059 118.802 78.0352 118.809 80.5457L118.875 103.559C118.883 106.069 116.853 108.11 114.343 108.117L24.6234 108.377C23.3681 108.38 22.3476 107.366 22.344 106.11L22.2709 80.8246Z'
                fill='white'
                stroke='#4D4540'
                strokeWidth='2'
                strokeDasharray='6 6'
            />
            <circle
                cx='70.0167'
                cy='91.9732'
                r='2.39431'
                fill='#4D4540'
                stroke='#4D4540'
                strokeDasharray='6 6'
            />
            <circle
                cx='81.594'
                cy='91.9732'
                r='2.39431'
                fill='#4D4540'
                stroke='#4D4540'
                strokeDasharray='6 6'
            />
            <circle
                cx='58.4395'
                cy='91.9732'
                r='2.39431'
                fill='#4D4540'
                stroke='#4D4540'
                strokeDasharray='6 6'
            />
            <path
                d='M22.2709 130.208C22.2637 127.697 24.293 125.656 26.8036 125.649L114.25 125.396C116.761 125.389 118.802 127.418 118.809 129.929L118.875 152.942C118.883 155.452 116.853 157.493 114.343 157.501L24.6234 157.76C23.3681 157.763 22.3476 156.749 22.344 155.493L22.2709 130.208Z'
                fill='white'
                stroke='#4D4540'
                strokeWidth='2'
                strokeDasharray='6 6'
            />
            <circle
                cx='70.0167'
                cy='141.356'
                r='2.39431'
                fill='#4D4540'
                stroke='#4D4540'
                strokeDasharray='6 6'
            />
            <circle
                cx='81.594'
                cy='141.356'
                r='2.39431'
                fill='#4D4540'
                stroke='#4D4540'
                strokeDasharray='6 6'
            />
            <circle
                cx='58.4395'
                cy='141.356'
                r='2.39431'
                fill='#4D4540'
                stroke='#4D4540'
                strokeDasharray='6 6'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M57.0463 24.2526C54.5358 24.2598 52.5064 26.3009 52.5137 28.8115L52.5867 54.0972C52.5903 55.3524 53.6109 56.3671 54.8662 56.3635L60.7501 56.3465V61.1162C60.7501 61.886 61.5834 62.3671 62.2501 61.9822L72.0001 56.353C72.0217 56.3406 72.0426 56.3275 72.0628 56.3138L144.585 56.1044C147.096 56.0971 149.125 54.056 149.118 51.5455L149.052 28.5326C149.044 26.0221 147.003 23.9928 144.493 24L57.0463 24.2526Z'
                fill='#FE5800'
            />
            <circle cx='112.049' cy='40.9656' r='2.89431' fill='white' />
            <circle cx='100.472' cy='40.9656' r='2.89431' fill='white' />
            <circle cx='88.8943' cy='40.9656' r='2.89431' fill='white' />
        </svg>
    );
}
