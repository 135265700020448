/* @flow */

import * as React from 'react';
import {FileUpload} from 'shells/form';
import {Button} from 'shells/button';
import {ModalSubheader} from 'shells/typography';
import {FooterBar} from 'shells/modal';

import './file-upload-modal-page.css';

type Props = {
    subheaderText?: React.Node,
    isUnsubscribe?: boolean,
    audienceId?: string,
};

export function FileUploadModalPage(props: Props) {
    const [file, setFile] = React.useState(null);

    return (
        <>
            {props.subheaderText ? (
                <ModalSubheader as='div'>{props.subheaderText}</ModalSubheader>
            ) : undefined}
            <form
                styleName='form'
                method='post'
                encType='multipart/form-data'
                action='/setup/import-account-contact-csv/upload'
            >
                <FileUpload
                    accept='.csv,.xls,.xlsb,.xlsx,.xlr,.wks,.numbers,.ods,.sxc'
                    name='qqfile' // This is required for our CSV import
                    id='file'
                    maxFileSize='10MB'
                    onChange={setFile}
                    enableDropping={true}
                />
                {props.isUnsubscribe ? (
                    <input type='hidden' name='unsubscribe' value='1' />
                ) : undefined}
                {props.audienceId ? (
                    <input type='hidden' name='audienceId' value={props.audienceId.split('-')[0]} />
                ) : undefined}
                <FooterBar
                    right={
                        <Button type='submit' disabled={!file} variant='primary' size='big'>
                            Next
                        </Button>
                    }
                />
            </form>
        </>
    );
}
