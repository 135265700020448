/* @flow */
import * as React from 'react';

import {Checkbox} from 'shells/checkbox';

type Props = {
    shouldIncludeSummary: boolean,
    setShouldIncludeSummary: (boolean) => void,
};

export function IncludeSummaryCheckbox(props: Props) {
    return (
        <div className='mt-16'>
            <Checkbox
                label='Include this summary ✨'
                checked={props.shouldIncludeSummary}
                onChange={(checked) => props.setShouldIncludeSummary(checked)}
            />
        </div>
    );
}
