/* @flow */

import * as React from 'react';

import type {SelectMenuOption} from '../../../select-menu';
import type {EventType} from '../../types';
import {TrashIcon} from '../../../icon';
import {ActionMenu} from '../../../action-menu';
import {Dialog} from '../../../dialog';
import {AttachmentList, type Attachment} from '../../../attachment-list';
import {Popover} from '../../../popover';
import {getNounForEntryType} from './helpers';

// TimelineEntryActionMenuProps
export type Props = {|
    id: string,
    entryType: EventType,
    customActionMenuOptions?: SelectMenuOption[],
    handleDelete?: (eventId: string) => any,
    attachmentsToView?: Attachment[],
    setIsViewingAttachments?: (boolean) => void,
    getButtonRef?: any,
|};

export function TimelineEntryActionMenu(props: Props) {
    const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
    const [isConfirmingDelete, setIsConfirmingDelete] = React.useState<boolean>(false);

    const actionMenuRef = React.useRef(null);

    const {
        id,
        entryType,
        customActionMenuOptions,
        handleDelete,
        attachmentsToView,
        setIsViewingAttachments,
    } = props;

    const options = customActionMenuOptions || [];

    let actionMenuOptions = options;
    if (handleDelete) {
        // All deletable entries get delete option last, but we need to ensure we don't already have an
        // old delete option from a previous render because that one won't work
        actionMenuOptions = options.filter((option) => option.label !== 'Delete');
        actionMenuOptions.push({
            label: 'Delete',
            icon: <TrashIcon size={15} wrapWithDiv={false} />,
            handleSelect: () => setIsConfirmingDelete(true),
            variant: 'destructive',
        });
    }

    return (
        <>
            {actionMenuOptions ? (
                <ActionMenu
                    options={actionMenuOptions}
                    getRef={(ref) => {
                        actionMenuRef.current = ref;
                        if (props.getButtonRef) {
                            props.getButtonRef.current = ref;
                        }
                    }}
                    size='small'
                    hasHoverBackground={true}
                />
            ) : undefined}
            {attachmentsToView && setIsViewingAttachments ? (
                <Popover
                    anchor={actionMenuRef.current}
                    onBlur={(e) => {
                        e.stopPropagation();
                        setIsViewingAttachments(false);
                    }}
                >
                    <AttachmentList attachments={attachmentsToView} />
                </Popover>
            ) : undefined}
            {handleDelete && isConfirmingDelete ? (
                <Dialog
                    anchor={actionMenuRef.current}
                    question={`Are you sure you want to delete this ${getNounForEntryType(
                        entryType
                    )}?`}
                    primaryText={isDeleting ? 'Deleting…' : 'Yes, delete'}
                    secondaryText='No, don’t delete'
                    onPrimary={() => {
                        setIsDeleting(true);
                        handleDelete(id);
                    }}
                    onSecondary={() => setIsConfirmingDelete(false)}
                    isWarning={true}
                />
            ) : undefined}
        </>
    );
}
