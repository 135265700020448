/* @flow */

import * as React from 'react';

import {Stack, Divider} from 'shells/layout';
import {colors} from 'shells/colors';

type Props = {icon?: React.ComponentType<*>, children: React.Node};

export function ControlWithIcon(props: Props) {
    const Icon = props.icon;

    return (
        <>
            {Icon ? (
                <Stack spacing={8} isHorizontal={true}>
                    <div
                        style={{
                            marginTop: 2,
                            marginLeft: 8,
                        }}
                    >
                        <Icon color={colors.grey} size={13} wrapWithDiv={false} />
                    </div>
                    <Divider variant='line--vertical' />
                </Stack>
            ) : undefined}
            {props.children}
        </>
    );
}
