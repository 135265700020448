/* eslint-disable no-param-reassign */

/*
 * NOTE: These happen _before_ babel polyfills are loaded!
 */

/*
 * CustomEvent
 *
 * from https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
 */
(function () {
    if (typeof window.CustomEvent === 'function') return false;

    function CustomEvent(event, params) {
        params = params || {bubbles: false, cancelable: false, detail: undefined};
        const evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);

        return evt;
    }

    CustomEvent.prototype = window.Event.prototype;

    window.CustomEvent = CustomEvent;
})();

/*
 * Headers.entries
 *
 * Check for browsers that don't implement `Headers.entries()` (like older Edge),
 * and if not, force the polyfill to be used.
 */
(function () {
    if (window.fetch && Headers && typeof Headers.prototype.entries === 'undefined') {
        window.fetch = undefined;
    }
})();
