/* @flow */

import * as React from 'react';

import {Button, SaveButton} from '../button';
import {Body} from '../typography';
import {FooterBar} from './footer-bar';
import {Modal} from './modal';

import './two-pane-modal-form.css';

type Props = {
    rightSection: React.Node,
    leftSection: React.Node,
    rightPaneWidth?: number,
    headerText: string,
    submitButtonText: string,
    errorMessage?: ?string,
    saveButtonDisabled?: boolean,
    isSaving?: boolean,
    onSubmit: () => void,
    onClose: () => void,
};

export function TwoPaneModalForm(props: Props) {
    const {
        rightSection,
        leftSection,
        onSubmit,
        onClose,
        submitButtonText,
        errorMessage,
        isSaving,
        saveButtonDisabled,
        ...restProps
    } = props;

    return (
        <Modal {...restProps} onClose={onClose}>
            <form onSubmit={onSubmit} className='flex gap-48'>
                {leftSection}
                <div className='flex-grow'>{rightSection}</div>
            </form>
            <FooterBar
                absolutePosition={true}
                left={
                    <Button variant='text-secondary' noPadding={true} size='big' onClick={onClose}>
                        Cancel
                    </Button>
                }
                right={
                    <div className='flex gap-16 align-center'>
                        <div styleName='error-message'>
                            {errorMessage && <Body color='error'>{errorMessage}</Body>}
                        </div>
                        <SaveButton
                            size='big'
                            variant='primary'
                            onClick={onSubmit}
                            errorSaving={Boolean(errorMessage)}
                            isSaving={isSaving || false}
                            disabled={saveButtonDisabled}
                            buttonText={submitButtonText}
                        />
                    </div>
                }
            />
        </Modal>
    );
}
