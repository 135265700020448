/* @flow */

import * as React from 'react';
import debounce from 'lodash/debounce';

import type {EventFragmentSparse, SessionUser} from 'nutshell-graphql-types';

import {ListViewInfiniteLoading} from 'shells/list-view';
import {useResizeObserver} from 'shells/utils/hooks';

import {GET_EVENTS_LIMIT} from './graphql/graphql-wrappers/use-get-timeline-events';
import {getEventsForTimeline} from './helpers';
import type {EventForTimeline} from './types';
import {TimelineErrorState} from './timeline-error-state';
import {TimelineEmptyState} from './timeline-empty-state';
import {TimelineEvents} from './timeline-events';

import './timeline.css';

const LOAD_MORE_PIXEL_THRESHOLD = 200;
const SMALL_TIMELINE_THRESHOLD = 425;

type TimelineEntryContextValue = {
    lastEntryId: ?string,
    isTimelineSmall: ?boolean,
};

// Context for timeline entries
export const TimelineEntryContext: React.Context<TimelineEntryContextValue> = React.createContext({
    lastEntryId: undefined,
    isTimelineSmall: undefined,
});

type Props = {
    events: EventFragmentSparse[],
    user: ?SessionUser,
    entityPageId?: ?string,
    entityPageName?: ?string,
    isLoading: boolean,
    isFirstLoad: boolean,
    refetchEvents: () => Promise<*>,
    onFetchMoreEvents: () => Promise<*>,
    hasNextPage: boolean,
    scrollContainerRef?: ?HTMLDivElement,
    onDelete: (eventId: string) => Promise<*>,
    onSetPinnedStatus?: ({
        entityToPinId: string,
        pinToEntityId: string,
        status: boolean,
        userName: string,
        changeLogId: string,
    }) => Promise<*>,
    numPinnedEvents?: number,
    isDashboard?: boolean,
    error?: ?string,
    onRemoveAllFilters?: () => void,
    filterBarHeight?: ?number,
};

export function Timeline(props: Props) {
    const {
        events,
        user,
        entityPageId,
        entityPageName,
        isLoading,
        isFirstLoad,
        refetchEvents,
        onFetchMoreEvents,
        hasNextPage,
        scrollContainerRef,
        onDelete: onDeleteEvent,
        onSetPinnedStatus,
        numPinnedEvents,
        isDashboard,
        error,
        onRemoveAllFilters,
        filterBarHeight,
    } = props;

    const [shouldFetchMore, setShouldFetchMore] = React.useState<boolean>(false);
    const [filteredEventIds, setFilteredEventIds] = React.useState<string[]>([]);
    const timelineContainerRef = React.useRef();
    const [width] = useResizeObserver(timelineContainerRef.current);

    const onDelete = React.useCallback(onDeleteEvent, []);

    const timelineEvents: ?(EventForTimeline[]) = React.useMemo(() => {
        return getEventsForTimeline(events, isDashboard, user, filteredEventIds);
    }, [events, isDashboard, user, filteredEventIds]);

    const canFetchMore = React.useMemo(() => {
        return hasNextPage && !isLoading && !shouldFetchMore;
    }, [hasNextPage, isLoading, shouldFetchMore]);

    const SpecialTimelineState = () => {
        if (error) {
            return <TimelineErrorState />;
        } else if (
            !isLoading &&
            !isFirstLoad &&
            !hasNextPage &&
            timelineEvents &&
            !timelineEvents.length
        ) {
            return <TimelineEmptyState onRemoveAllFilters={onRemoveAllFilters} />;
        } else {
            return null;
        }
    };

    /**
     * Callback that calculates and returns true if timeline container is scrolled to the bottom
     *
     * @type {(function(): boolean)|*}
     */
    const isScrolledToBottom = React.useCallback((): boolean => {
        // If a scrollContainerRef is passed in, we will use that - otherwise we'll use the timelineContainerRef
        const scrollRef = scrollContainerRef ? scrollContainerRef : timelineContainerRef.current;

        // When using a passed in scrollContainerRef, we will measure against the window
        const useWindow = Boolean(scrollContainerRef);

        if (scrollRef) {
            const boundingClientRect = scrollRef.getBoundingClientRect();
            const scrollContainerHeight = boundingClientRect.height;

            if (useWindow) {
                const windowHeight = window.innerHeight;
                const distanceFromTop = boundingClientRect.top * -1;

                return (
                    scrollContainerHeight - distanceFromTop <=
                    windowHeight + LOAD_MORE_PIXEL_THRESHOLD
                );
            } else {
                const {scrollTop, scrollHeight} = scrollRef;

                return (
                    scrollTop + scrollContainerHeight >= scrollHeight - LOAD_MORE_PIXEL_THRESHOLD
                );
            }
        }

        return false;
    }, [scrollContainerRef]);

    /**
     * Effect handles checking for cases where we should just automatically fetch more events without
     * the user needing to trigger the onScroll effect. Including the case where the scroll container
     * is not tall enough for the event to be triggered at all.
     */
    React.useEffect(() => {
        if (
            canFetchMore &&
            ((timelineEvents && timelineEvents.length < GET_EVENTS_LIMIT) || isScrolledToBottom())
        ) {
            setShouldFetchMore(true);
        }
    }, [canFetchMore, isScrolledToBottom, timelineEvents]);

    /**
     * Effect handles fetching more when shouldFetchMore state is triggered
     */
    React.useEffect(() => {
        if (shouldFetchMore) {
            onFetchMoreEvents().then(() => {
                setShouldFetchMore(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldFetchMore]);

    /**
     * Effect to handle onScroll listener to fetch more events
     */
    React.useEffect(() => {
        // Define scroll handler, which uses debounce to reduce number of times it runs
        const handleScroll = debounce(() => {
            if (isScrolledToBottom() && canFetchMore) {
                setShouldFetchMore(true);
            }
        }, 100);

        // Add event listeners once minimum number of events have been pulled
        if (isDashboard && timelineContainerRef && timelineContainerRef.current) {
            // For the dashboard, the scroll container is the timeline container
            timelineContainerRef.current.addEventListener('scroll', handleScroll);
        } else {
            // For other pages, the scroll container is the entire window
            window.addEventListener('scroll', handleScroll);
        }

        // Remove event listeners when this effect returns
        return () => {
            if (isDashboard && timelineContainerRef && timelineContainerRef.current) {
                timelineContainerRef.current.removeEventListener('scroll', handleScroll);
            } else {
                window.removeEventListener('scroll', handleScroll);
            }
        };
    }, [isScrolledToBottom, canFetchMore, isDashboard]);

    return (
        <TimelineEntryContext.Provider
            value={{
                lastEntryId:
                    timelineEvents && timelineEvents.length && !hasNextPage
                        ? timelineEvents[timelineEvents.length - 1].id
                        : undefined,
                isTimelineSmall: Boolean(width && width <= SMALL_TIMELINE_THRESHOLD),
            }}
        >
            <div
                ref={(c) => {
                    timelineContainerRef.current = c;
                }}
                styleName={isDashboard ? 'timeline-container--dashboard' : 'timeline-container'}
                style={filterBarHeight ? {height: `calc(100% - ${filterBarHeight}px)`} : undefined}
            >
                <SpecialTimelineState />
                {!isFirstLoad && timelineEvents && user ? (
                    <TimelineEvents
                        timelineEvents={timelineEvents}
                        currentUser={user}
                        entityPageId={entityPageId}
                        entityPageName={entityPageName}
                        onDeleteEvent={onDelete}
                        onSetPinnedStatus={onSetPinnedStatus}
                        onRefetchEvents={refetchEvents}
                        numPinnedEvents={numPinnedEvents}
                        isDashboard={isDashboard}
                        onFilterOutEventId={(eventId) =>
                            setFilteredEventIds((prevIds) => [...prevIds, eventId])
                        }
                    />
                ) : undefined}
                {isFirstLoad || (!isFirstLoad && hasNextPage) ? (
                    <ListViewInfiniteLoading />
                ) : undefined}
            </div>
        </TimelineEntryContext.Provider>
    );
}
