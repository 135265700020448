import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import SmsBroadcast from './icon-sms-broadcast.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <SmsBroadcast {...validProps} />;
}
SvgComponent.iconName = 'sms-broadcast';

export const SmsBroadcastIcon = svgIconWrapper(SvgComponent);
