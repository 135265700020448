/* @flow */

import * as React from 'react';

type Props = {
    width?: number,
};

export function ChatCustomIconUpsell(props: Props) {
    return (
        <svg
            width={props.width}
            viewBox='0 0 213 341'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g filter='url(#a)'>
                <rect width='208.727' height='264.281' rx='4' fill='#fff' />
            </g>
            <path d='M0 4a4 4 0 0 1 4-4h201a4 4 0 0 1 4 4v31H0z' fill='#BABABA' />
            <g filter='url(#b)' shapeRendering='crispEdges'>
                <circle cx='179' cy='306.732' r='30' fill='#fff' fillOpacity='.75' />
                <circle
                    cx='179'
                    cy='306.732'
                    r='29'
                    stroke='#7A7370'
                    strokeWidth='2'
                    strokeDasharray='6 6'
                />
            </g>
            <path
                d='M171.2 318.045c-3.975 0-7.2-3.401-7.2-7.594 0-3.312 2.01-6.128 4.81-7.166q-.009-.214-.01-.428c0-4.661 3.58-8.437 8-8.437 2.965 0 5.55 1.698 6.935 4.229a4.6 4.6 0 0 1 2.665-.854c2.65 0 4.8 2.267 4.8 5.062 0 .644-.115 1.256-.32 1.825 2.92.622 5.12 3.349 5.12 6.613 0 3.728-2.865 6.75-6.4 6.75zm3.95-11.443a1.31 1.31 0 0 0 0 1.787c.47.491 1.23.496 1.695 0l1.95-2.056.005 7.071c0 .702.535 1.266 1.2 1.266s1.2-.564 1.2-1.266v-7.071l1.95 2.056c.47.496 1.23.496 1.695 0 .465-.495.47-1.297 0-1.787l-4-4.219a1.15 1.15 0 0 0-1.695 0z'
                fill='#7A7370'
            />
            <path
                d='M15 67.047A9.047 9.047 0 0 1 24.047 58h162.906A9.047 9.047 0 0 1 196 67.047v13.906A9.047 9.047 0 0 1 186.953 90H19.523A4.523 4.523 0 0 1 15 85.477z'
                fill='#F7F7F7'
            />
            <path
                d='M14.755 78.824c.008-.883.94-1.442 1.68-1.008l11.717 6.894c.74.435.73 1.54-.016 1.988L16.302 93.8c-.746.448-1.67-.096-1.663-.98zM15 177.047A9.047 9.047 0 0 1 24.047 168h69.906a9.047 9.047 0 0 1 9.047 9.047v13.906A9.047 9.047 0 0 1 93.953 200h-74.43A4.523 4.523 0 0 1 15 195.477z'
                fill='#F7F7F7'
            />
            <path
                d='M14.755 188.824c.008-.883.94-1.443 1.68-1.008l11.717 6.894c.74.435.73 1.539-.016 1.988l-11.834 7.103c-.746.448-1.67-.096-1.663-.979zM196 125.047a9.046 9.046 0 0 0-9.047-9.047H94.047A9.047 9.047 0 0 0 85 125.047v13.906A9.047 9.047 0 0 0 94.047 148h97.43a4.523 4.523 0 0 0 4.523-4.523z'
                fill='#F7F7F7'
            />
            <path
                d='M196.245 136.824c-.008-.883-.94-1.443-1.679-1.008l-11.718 6.894c-.739.435-.73 1.539.016 1.988l11.834 7.103c.746.448 1.67-.096 1.663-.979z'
                fill='#F7F7F7'
            />
            <path stroke='#F7F7F7' d='M1 216.5h207' />
            <path
                d='m189.594 232.126-18.102 10.443a.939.939 0 0 0 .086 1.688l4.151 1.741 11.221-9.888c.214-.192.519.101.335.324l-9.408 11.462v3.144c0 .922 1.113 1.285 1.66.617l2.48-3.019 4.866 2.039a.94.94 0 0 0 1.289-.711l2.812-16.871c.133-.789-.715-1.359-1.39-.969'
                fill='#BABABA'
            />
            <filter
                id='a'
                x='0'
                y='0'
                width='212.727'
                height='268.281'
                filterUnits='userSpaceOnUse'
                colorInterpolationFilters='sRGB'
            >
                <feFlood floodOpacity='0' result='BackgroundImageFix' />
                <feColorMatrix
                    in='SourceAlpha'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                    result='hardAlpha'
                />
                <feOffset dx='4' dy='4' />
                <feComposite in2='hardAlpha' operator='out' />
                <feColorMatrix values='0 0 0 0 0.69247 0 0 0 0 0.830552 0 0 0 0 0.899593 0 0 0 1 0' />
                <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_3787_11855' />
                <feBlend in='SourceGraphic' in2='effect1_dropShadow_3787_11855' result='shape' />
            </filter>
            <filter
                id='b'
                x='149'
                y='276.732'
                width='64'
                height='64'
                filterUnits='userSpaceOnUse'
                colorInterpolationFilters='sRGB'
            >
                <feFlood floodOpacity='0' result='BackgroundImageFix' />
                <feColorMatrix
                    in='SourceAlpha'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                    result='hardAlpha'
                />
                <feOffset dx='4' dy='4' />
                <feComposite in2='hardAlpha' operator='out' />
                <feColorMatrix values='0 0 0 0 0.694118 0 0 0 0 0.831373 0 0 0 0 0.898039 0 0 0 1 0' />
                <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_3787_11855' />
                <feBlend in='SourceGraphic' in2='effect1_dropShadow_3787_11855' result='shape' />
            </filter>
        </svg>
    );
}
