/* @flow */

import * as React from 'react';

import {GlossaryIcon, ExternalLinkIcon} from '../icon';
import {Link} from '../link';
import {Button} from '../button';
import {Body} from '../typography';
import type {Product} from './types';
import {getLearnMoreLinkHref} from './add-ons/helpers';

type Props = {|
    product: Product,
    onOpenModal: () => void,
    size?: 'small' | 'normal',
|};

export function LearnMore(props: Props) {
    const {size = 'normal'} = props;

    const learnMoreLinkHref = getLearnMoreLinkHref(props.product);
    const iconSize = size === 'small' ? 11 : 13;

    return (
        <>
            {props.product === 'MARKETING' ? (
                <Button
                    variant='text-primary'
                    onClick={props.onOpenModal}
                    size='big'
                    noPadding={true}
                >
                    <div className='flex gap-6 align-center'>
                        <GlossaryIcon size={iconSize} alignCenter={true} wrapWithDiv={false} />
                        <Body size={size} color='blue'>
                            Learn more
                        </Body>
                    </div>
                </Button>
            ) : (
                <Link variant='primary' newTab={true} href={learnMoreLinkHref}>
                    <div className='flex gap-6 align-center'>
                        <ExternalLinkIcon size={iconSize} alignCenter={true} wrapWithDiv={false} />
                        <Body size={size} color='blue'>
                            Learn more
                        </Body>
                    </div>
                </Link>
            )}
        </>
    );
}
