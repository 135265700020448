/* @flow */

import {useGetSessionUser} from '../../ui/core/use-get-session-user';
import {useUpdateOnboardingSetting} from '../../ui/core/use-update-onboarding-setting';

// Used for showing and hiding onboarding for a given user.
//
// Example usage:
// const {shouldShow, onDismiss} = useDismissibleOnboarding('hide_competitor_iq_get_started');
//
// NOTE: Setting name needs to be added to Nut\Settings\User.php
export const useDismissibleOnboarding = (
    settingName: string
): ({
    shouldShow: boolean,
    onDismiss?: () => Promise<*>,
    isLoading: boolean,
}) => {
    const {user, isLoading} = useGetSessionUser();
    const updateOnboardingSetting = useUpdateOnboardingSetting();

    const shouldShow = Boolean(
        user &&
            user.hiddenOnboardingSettings &&
            !user.hiddenOnboardingSettings.includes(settingName)
    );

    const onDismiss = user
        ? () =>
              updateOnboardingSetting({
                  userId: user.id,
                  settingName: settingName,
                  value: '1',
              })
        : undefined;

    return {shouldShow, isLoading, onDismiss};
};
