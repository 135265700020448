/* @flow */

import * as React from 'react';

import type {GetUsageScorecard_usageScorecard_sections as Section} from 'nutshell-graphql-types';

import {LoadingPage} from '../../loading-page';
import {UsageScorecardHeader} from './usage-scorecard-header';
import {UsageScorecardSection} from './usage-scorecard-section';

type Props = {
    sections: Section[],
    isLoading: boolean,
};

export function UsageScorecard(props: Props) {
    return (
        <div>
            <div className='border br-3 full-width height-fit-content'>
                <UsageScorecardHeader />
                <div>
                    {!props.isLoading && props.sections ? (
                        props.sections.map((section) => {
                            return (
                                <UsageScorecardSection
                                    key={section.sectionName}
                                    sectionName={section.sectionName}
                                    features={section.features}
                                />
                            );
                        })
                    ) : (
                        <div className='pad-16'>
                            <LoadingPage />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
