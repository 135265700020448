/* @flow */
import * as React from 'react';

import './divider.css';

type Props = {
    text?: string,
    isDisabled?: boolean,
};

export class Line extends React.PureComponent<Props> {
    render() {
        return (
            <div styleName={this.props.isDisabled ? 'container--disabled' : 'container'}>
                <div styleName='divider-line' />
                {this.props.text ? (
                    <div styleName='divider-text'>{this.props.text}</div>
                ) : undefined}
                <div styleName='divider-line' />
            </div>
        );
    }
}
