/* @flow */

import {createAction} from 'redux-act';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import type {Dispatch} from 'redux';
import type {Dash, DashType} from '../types';
import type {NutshellState} from '../../../store';
import {getSelectedDash} from '../dash-grid/dash-config/config-selectors';
import {getDashReportType} from '../dashboard-constants';
import {fetchDashData} from './action-utils';
import {fetchDashboardData, toggleConfigureDash} from './base';

const $ = window.jQuery;

export const requestDashboardPreview = createAction('DASHBOARD_PREVIEW_REQUESTED');
export const updateDashboardPreview = createAction('DASHBOARD_PREVIEW_UPDATED');
export const failDashboardPreview = createAction('DASHBOARD_PREVIEW_FAILED');
export const updateDashboardPreviewName = createAction('DASHBOARD_PREVIEW_NAME_UPDATED');
export const clearDashboardPreview = createAction('DASHBOARD_PREVIEW_CLEARED');

export const requestConfigFormData = createAction('DASHBOARD_CONFIG_FORM_DATA_REQUESTED');
export const failConfigFormData = createAction('DASHBOARD_CONFIG_FORM_DATA_FAILED');

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const dashboardFetchStream = new Subject();
dashboardFetchStream
    .switchMap((fetchPromise) =>
        Observable.fromPromise(fetchPromise)
            // If we don't catch errors, the default behavior of an observable is to
            // dispose after an onError.  So, to allow retries, we need this catch.
            .catch((err) => {
                return Observable.of({
                    isError: true,
                    errors:
                        err.dash.type === 'quotas'
                            ? JSON.parse(err.responseText).errors
                            : err.responseText,
                    dash: err.dash,
                    dispatch: err.dispatch,
                });
            })
    )
    .subscribe((res) => {
        if (res.isError) {
            res.dispatch(
                failDashboardPreview({
                    dash: res.dash,
                    errors: res.errors,
                })
            );
        } else {
            res.dispatch(updateDashboardPreview(res));
        }
    });

export function fetchDashboardPreview(formValues: {value: any, name: string}) {
    return function (dispatch: Dispatch<*>, getState: () => NutshellState) {
        dispatch(requestDashboardPreview());
        const dash = {
            type: getSelectedDash(getState()).type, // Type cannot change, get it from redux state
            value: formValues.value,
        };
        // Using a hacky way to get dispatch and other variables into the subscribe handler of the stream
        // TODO: Find a better way to do this
        const fetchPromise = fetchDashData(dash.type, dash.value).then(
            (res) => {
                res.dispatch = dispatch;
                res.name = formValues.name;

                return res;
            },
            (err) => {
                err.dispatch = dispatch;
                err.dash = dash;

                return err;
            }
        );
        dashboardFetchStream.next(fetchPromise);

        return fetchPromise;
    };
}

type Data = {
    dash: Dash,
    formValues: {
        name: string,
        value: string,
    },
};

export function submitDashboardConfigForm({dash, formValues}: Data, dispatch: ThunkDispatch) {
    const {name, value} = formValues;
    dispatch(clearDashboardPreview());
    if (dash.id.startsWith('new-')) {
        // New dash, create it on the backend
        return createSelectedDashboardForm(dash.type, name, value, dispatch);
    } else {
        // Existing dash, so update it
        dispatch(toggleConfigureDash(dash.id));

        return updateSelectedDashboardForm(dash.id, name, value, dispatch);
    }
}

function updateSelectedDashboardForm(id, name, value, dispatch) {
    dispatch(requestConfigFormData());

    return $.ajax({
        type: 'PUT',
        dataType: 'json',
        data: {
            data: {
                name: name,
                value: value,
            },
        },
        url: `/rest/dashes/${id}`,
    }).then(
        () => {
            dispatch(fetchDashboardData());
        },
        (err) => {
            dispatch(failConfigFormData(err));
        }
    );
}

function createSelectedDashboardForm(dashType: DashType, name, value, dispatch) {
    return $.ajax({
        type: 'POST',
        url: '/rest/dashes',
        data: {
            data: {
                reportType: getDashReportType(dashType),
                isShared: 0,
                name: name,
                value: value,
            },
        },
    }).then(
        () => {
            dispatch(toggleConfigureDash()); // Clear any dash from being configured
            dispatch(fetchDashboardData());
        },
        (err) => {
            dispatch(failConfigFormData(err));
        }
    );
}
