/* @flow */

import {createSelector, type OutputSelector} from 'reselect';
import * as ramda from 'ramda';
import type {LeadId, ListItem} from '../types';
import * as Schema from '../schema';

import type {State as LeadsState} from './leads-reducer';

// The root state will include more keys, but this is what we really care about here
type LeadsModuleState = {leads: LeadsState};

/*
 * Given the entire redux state, pull out just the piece of state for the leads reducer.
 */
export const getLeadsState = (state: LeadsModuleState): LeadsState => state.leads;

/*
 * Get a map (object) of all of the leads we know about in our `byId` state
 */
// $FlowFixMe upgrading Flow to v0.92.1
export const getById = createSelector([getLeadsState], (leadsState) => leadsState.byId);

/*
 * Retrieve a single lead by id, by passing "props" with a leadId
 */
export const getLead = (state: LeadsModuleState, leadId: LeadId) => {
    return getById(state)[leadId];
};

/*
 * Get the leads schema
 */
// $FlowFixMe upgrading Flow to v0.92.1
export const getLeadsSchema = createSelector([getLeadsState], (leadsState) => leadsState.schema);

/*
 * Get schema propertyMap
 */
// $FlowFixMe upgrading Flow to v0.92.1
export const getLeadsSchemaPropertyMap = createSelector(
    [getLeadsSchema],
    (leadSchemaState): Schema.Properties => {
        if (!leadSchemaState) return {};

        return leadSchemaState.properties;
    }
);
/*
 * Get the schema properties
 */
// $FlowFixMe upgrading Flow to v0.92.1
export const getLeadsSchemaProperties = createSelector(
    [getLeadsSchemaPropertyMap],
    (leadsPropertyMap): Schema.FieldProperties[] => ramda.values(leadsPropertyMap)
);

/*
 * Get a map of `listItems` from the `listItemsById` portion of the leads state
 */
export const getLeadListItemMap: OutputSelector<
    LeadsModuleState,
    ?string,
    $PropertyType<LeadsState, 'listItemsById'>,
> = createSelector([getLeadsState], (leadsState) => leadsState.listItemsById);

/*
 * Get an array of `listItems` from the `listItemsById` portion of the leads state
 */
export const getLeadListItems: OutputSelector<LeadsModuleState, void, ListItem[]> = createSelector(
    [getLeadListItemMap],
    (leadListItemMap) => ramda.values(leadListItemMap)
);
