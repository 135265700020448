/* @flow */

import {createAction} from 'redux-act';
import type {Dispatch} from 'redux';
import {get} from 'nutshell-core/api/get';
import {Session} from 'nutshell-core/session';
import type {
    ComparedActivityReportSummary,
    ComparisonRangeType,
    ReportRowData,
} from 'nutshell-core/types';

import type {ActivityReportPjaxResponseType} from '../report-types';

// Similarly, option 1 is TYPE_DETAIL, which refers to the old school activity
// report tab "Details" - however, this is now considered the "Activities" tab,
// so we're using `1` here.
const OPTIONS_TYPE_ACTIVITIES = 1;

// Endpoints used in ReportActivityController to export old-school activity
// reports (activities, detail)
const EXPORT_ACTION_ACTIVITIES = 'export-activities';
const EXPORT_ACTION_DETAIL = 'export-detail';
type ActivityExportAction = 'export-activities' | 'export-detail';

export const ActionTypes = {
    ACTIVITY_REPORT_FETCH_SUMMARY_REQUEST: 'ACTIVITY_REPORT_FETCH_SUMMARY_REQUEST',
    ACTIVITY_REPORT_FETCH_SUMMARY_SUCCESS: 'ACTIVITY_REPORT_FETCH_SUMMARY_SUCCESS',
    ACTIVITY_REPORT_FETCH_SUMMARY_FAILURE: 'ACTIVITY_REPORT_FETCH_SUMMARY_FAILURE',
};

type SummaryRequestPayload = {filters: Object, comparisonType: ?ComparisonRangeType};
type SummaryPayload = {
    data: ComparedActivityReportSummary<Array<ReportRowData>>,
    totals: ComparedActivityReportSummary<ReportRowData>,
};
export type ActivityReportSummaryRequestAction = {
    type: 'ACTIVITY_REPORT_FETCH_SUMMARY_REQUEST',
    payload: SummaryRequestPayload,
};
export type ActivityReportSummarySuccessAction = {
    type: 'ACTIVITY_REPORT_FETCH_SUMMARY_SUCCESS',
    payload: SummaryPayload,
};
export type ActivityReportSummaryFailureAction = {
    type: 'ACTIVITY_REPORT_FETCH_SUMMARY_FAILURE',
    error: true,
    payload: ?mixed,
};

export type ActivityReportAction =
    | ActivityReportSummaryRequestAction
    | ActivityReportSummarySuccessAction
    | ActivityReportSummaryFailureAction;

/*------------------------------------------------------------------------------
 Action Creators
 ------------------------------------------------------------------------------*/
export const requestActivityReportActivities = createAction('ACTIVITY_REPORT_ACTIVITIES_REQUESTED');
export const updateActivityReportActivities = createAction('ACTIVITY_REPORT_ACTIVITIES_UPDATED');
export const failActivityReportActivities = createAction('ACTIVITY_REPORT_ACTIVITIES_FAILED');

export const requestActivityReportSummary = (
    payload: SummaryRequestPayload
): ActivityReportSummaryRequestAction => ({type: 'ACTIVITY_REPORT_FETCH_SUMMARY_REQUEST', payload});
export const resolveActivityReportSummary = (
    payload: SummaryPayload
): ActivityReportSummarySuccessAction => ({type: 'ACTIVITY_REPORT_FETCH_SUMMARY_SUCCESS', payload});
export const failActivityReportSummary = (err: mixed): ActivityReportSummaryFailureAction => ({
    type: 'ACTIVITY_REPORT_FETCH_SUMMARY_FAILURE',
    payload: err,
    error: true,
});

/**
 * Helper function to buffer call to exportActivityReport with the correct
 * exportAction name
 *
 * @return {function}              Function that calls activities export
 */
export function exportActivityReportDetail() {
    return function (dispatch: ThunkDispatch, getState: Function) {
        exportActivityReport(dispatch, getState, EXPORT_ACTION_DETAIL);
    };
}

export function fetchActivityReportActivities(filters: Object, page: number = 1) {
    return function (dispatch: Dispatch<*>) {
        dispatch(requestActivityReportActivities());
        const params = {
            id: 'new_detail',
            options: {
                ...filters,
                type: OPTIONS_TYPE_ACTIVITIES,
                page: page,
            },
        };

        // not using api.get, because that will add `/rest/` to url, which we don't want
        get('/report-activity/update-options.json', params)
            .then((res) => res.json())
            .then(
                (response: ActivityReportPjaxResponseType) => {
                    dispatch(updateActivityReportActivities(response));
                },
                (err) => {
                    dispatch(failActivityReportActivities(err));
                }
            );
    };
}

/**
 * Helper function to buffer call to exportActivityReport with the correct
 * exportAction name
 *
 * @return {function}              Function that calls activities export
 */
export function exportActivityReportActivities() {
    return function (dispatch: ThunkDispatch, getState: Function) {
        exportActivityReport(dispatch, getState, EXPORT_ACTION_ACTIVITIES);
    };
}

/**
 * Makes request to export an activity report, and conditionally poll for the
 * export progress if the export takes >10 seconds
 *
 * @param  {Dispatch} dispatch       - Redux's dispatch function
 * @param  {Function} getState       - Redux's built in getState function to
 *                                     get the current shape of our store
 * @param  {string} exportAction     - ReportActivityController action name
 * @return {void}
 */
function exportActivityReport(
    dispatch: ThunkDispatch,
    getState: Function,
    exportAction: ActivityExportAction
) {
    const ajaxParams = {
        type: 'GET',
        url: `/report-activity/${exportAction}.json`,
        data: {
            id: 'new_detail',
        },
    };

    $.ajax(ajaxParams).then((res) => {
        // We have a 10-second timeout for returning a redirectUrl
        // (download link) otherwise we throw the export into our
        // NotifyMessages flow, which we can poll for
        if (res.redirectUrl && res.redirectUrl.length) {
            window.location = res.redirectUrl;
        } else {
            dispatch(Session.pollForQueuedExports());
        }
    });
}
