/* @flow */

import * as React from 'react';

import type {Reaction, Reactions} from './types';
import {AddReactionButton} from './add-reaction-button';
import {ReactionButton} from './reaction-button';

import './reaction-bar.css';

// ReactionBarProps
export type Props = {|
    reactions: Reactions,
    onToggleReaction: (reaction: Reaction) => any,
|};

type OwnProps = {|
    ...Props,
    currentUserId: string,
    hideAddButton?: boolean,
    isSmall?: boolean,
|};

export function ReactionBar(props: OwnProps) {
    return (
        <div styleName={props.isSmall ? 'reaction-bar--small' : 'reaction-bar'}>
            {Object.keys(props.reactions).map((reaction) => (
                <ReactionButton
                    key={reaction}
                    reaction={reaction}
                    reactingUsers={props.reactions[reaction]}
                    currentUserId={props.currentUserId}
                    onClick={() => {
                        props.onToggleReaction(reaction);
                    }}
                    isSmall={props.isSmall}
                />
            ))}
            {!props.hideAddButton ? (
                <AddReactionButton onSelectReaction={props.onToggleReaction} />
            ) : undefined}
        </div>
    );
}
