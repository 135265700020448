/* @flow */

import React from 'react';

type Props = {
    shouldPoll: boolean,
    startPolling: (number) => void,
    stopPolling: () => void,
};

export function usePoll(props: Props) {
    const {shouldPoll, startPolling, stopPolling} = props;

    const currentlyPolling = React.useRef(false);

    React.useEffect(() => {
        if (shouldPoll && !currentlyPolling.current) {
            startPolling(1000);
            currentlyPolling.current = true;
        }
        if (!shouldPoll && currentlyPolling.current) {
            stopPolling();
            currentlyPolling.current = false;
        }

        // If the component unmounts before our shouldPoll state turns false,
        // ensure we stop polling
        return () => {
            stopPolling();
            currentlyPolling.current = false;
        };
    }, [shouldPoll, startPolling, stopPolling]);
}
