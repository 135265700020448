import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import SmsDripSequence from './icon-sms-drip-sequence.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <SmsDripSequence {...validProps} />;
}
SvgComponent.iconName = 'sms-drip-sequence';

export const SmsDripSequenceIcon = svgIconWrapper(SvgComponent);
