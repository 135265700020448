/* @flow */
import * as React from 'react';

import {colors} from 'shells/colors';
import {Checkbox} from 'shells/checkbox';
import {UpgradeArrowIcon} from 'shells/icon';

import {AiSummaryFeatureGateModal} from '../limits/feature-gates/ai-summary-feature-gate-modal';

type Props = {
    callHasEnded: boolean,
};

export function SummarizeCheckboxUpsell(props: Props) {
    const [showFeatureGate, setShowFeatureGate] = React.useState(false);

    return (
        <>
            <div
                className='mt-16'
                onClick={() => {
                    if (props.callHasEnded) {
                        setShowFeatureGate(true);
                    }
                }}
            >
                <Checkbox
                    label={
                        <div className='flex align-center pointer-cursor'>
                            Automatically summarize this call ✨{' '}
                            <button type='button'>
                                <UpgradeArrowIcon size={16} color={colors.blue} />
                            </button>
                        </div>
                    }
                    checked={false}
                    disabled={true}
                />
            </div>
            {showFeatureGate && (
                <AiSummaryFeatureGateModal
                    isOpen={showFeatureGate}
                    onClose={(e) => {
                        e && e.stopPropagation();
                        setShowFeatureGate(false);
                    }}
                />
            )}
        </>
    );
}
