/* @flow */

import * as React from 'react';

type Props = {|
    width?: number,
|};

export function ActivateSmsDripSequence(props: Props) {
    return (
        <svg
            viewBox='0 0 195 182'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            width={props.width}
        >
            <rect
                x='-0.325066'
                y='0.325066'
                width='86.241'
                height='164.443'
                rx='8.89066'
                transform='matrix(-1 -8.74228e-08 -8.74228e-08 1 131.404 10.7952)'
                fill='#4D4540'
                stroke='#4D4540'
                strokeWidth='0.650132'
            />
            <rect
                x='-0.921573'
                y='0.921572'
                width='85.048'
                height='163.25'
                rx='8.29415'
                transform='matrix(-1 -8.74228e-08 -8.74228e-08 1 134.555 6.4502)'
                fill='#EBE8E8'
                stroke='#4D4540'
                strokeWidth='1.84314'
            />
            <rect
                x='-0.325066'
                y='0.325066'
                width='24.2762'
                height='3.97689'
                rx='1.98844'
                transform='matrix(-1 -8.74228e-08 -8.74228e-08 1 104.771 12.2449)'
                fill='#4D4540'
                stroke='#4D4540'
                strokeWidth='0.650132'
            />
            <path
                d='M96.7807 31.1712C96.774 28.8575 98.6441 26.9765 100.958 26.9698L181.546 26.7371C183.86 26.7304 185.741 28.6006 185.747 30.9142L185.809 52.1222C185.815 54.4359 183.945 56.3169 181.631 56.3235L98.9486 56.5623C97.7918 56.5657 96.8513 55.6306 96.848 54.4738L96.7807 31.1712Z'
                fill='white'
                stroke='#4D4540'
                strokeWidth='1.84314'
                strokeDasharray='5.53 5.53'
            />
            <path
                d='M61.5277 80.9373C61.521 78.6236 63.3912 76.7426 65.7049 76.7359L146.293 76.5032C148.607 76.4965 150.488 78.3667 150.494 80.6803L150.556 101.888C150.562 104.202 148.692 106.083 146.378 106.09L63.6957 106.328C62.5389 106.332 61.5984 105.397 61.595 104.24L61.5277 80.9373Z'
                fill='white'
                stroke='#4D4540'
                strokeWidth='1.84314'
                strokeDasharray='5.53 5.53'
            />
            <path
                d='M8.31093 131.366C8.30425 129.053 10.1744 127.172 12.4881 127.165L93.0762 126.932C95.3899 126.926 97.2709 128.796 97.2776 131.11L97.3388 152.318C97.3455 154.631 95.4753 156.512 93.1617 156.519L10.4789 156.758C9.32207 156.761 8.38157 155.826 8.37823 154.669L8.31093 131.366Z'
                fill='white'
                stroke='#4D4540'
                strokeWidth='1.84314'
                strokeDasharray='5.53 5.53'
            />
            <path
                d='M90.0462 113.084C90.4061 112.724 90.4061 112.141 90.0462 111.781C89.6863 111.421 89.1028 111.421 88.7429 111.781L90.0462 113.084ZM79.2572 121.648C79.2572 122.157 79.6698 122.57 80.1788 122.57L88.473 122.57C88.9819 122.57 89.3945 122.157 89.3945 121.648C89.3945 121.139 88.9819 120.727 88.473 120.727L81.1004 120.727L81.1004 113.354C81.1004 112.845 80.6878 112.433 80.1788 112.433C79.6698 112.433 79.2572 112.845 79.2572 113.354L79.2572 121.648ZM88.7429 111.781L79.5272 120.997L80.8305 122.3L90.0462 113.084L88.7429 111.781Z'
                fill='#7A7370'
            />
            <path
                d='M126.909 62.3987C127.269 62.0388 127.269 61.4553 126.909 61.0954C126.55 60.7355 125.966 60.7355 125.606 61.0954L126.909 62.3987ZM116.121 70.9628C116.121 71.4718 116.533 71.8844 117.042 71.8844L125.336 71.8844C125.845 71.8844 126.258 71.4718 126.258 70.9628C126.258 70.4538 125.845 70.0412 125.336 70.0412L117.964 70.0412L117.964 62.6686C117.964 62.1597 117.551 61.7471 117.042 61.7471C116.533 61.7471 116.121 62.1597 116.121 62.6686L116.121 70.9628ZM125.606 61.0954L116.39 70.3111L117.694 71.6144L126.909 62.3987L125.606 61.0954Z'
                fill='#7A7370'
            />
            <rect x='36' y='134' width='52' height='6' rx='0.921572' fill='#EBE8E8' />
            <rect x='89' y='82' width='51' height='7' rx='0.921572' fill='#EBE8E8' />
            <rect x='124' y='33' width='53' height='7' rx='0.921572' fill='#EBE8E8' />
            <rect x='36' y='144' width='52' height='6' rx='0.921572' fill='#EBE8E8' />
            <rect x='89' y='92' width='45' height='7' rx='0.921572' fill='#EBE8E8' />
            <rect x='124' y='43' width='16' height='6' rx='0.921572' fill='#EBE8E8' />
            <g clipPath='url(#clip0_297_60649)'>
                <path
                    d='M110 35.2188C106.254 35.2188 103.219 38.2539 103.219 42C103.219 45.7461 106.254 48.7812 110 48.7812C113.746 48.7812 116.781 45.7461 116.781 42C116.781 38.2539 113.746 35.2188 110 35.2188ZM110 47.4688C106.979 47.4688 104.531 45.0215 104.531 42C104.531 38.9785 106.979 36.5312 110 36.5312C113.021 36.5312 115.469 38.9785 115.469 42C115.469 45.0215 113.021 47.4688 110 47.4688ZM111.69 44.6141L109.368 42.927C109.284 42.8641 109.234 42.7656 109.234 42.6617V38.1719C109.234 37.9914 109.382 37.8438 109.562 37.8438H110.438C110.618 37.8438 110.766 37.9914 110.766 38.1719V42.0465L112.592 43.3754C112.74 43.482 112.77 43.6871 112.663 43.8348L112.149 44.543C112.043 44.6879 111.837 44.7207 111.69 44.6141Z'
                    fill='#D4D1CF'
                />
            </g>
            <g clipPath='url(#clip1_297_60649)'>
                <path
                    d='M75 85.2188C71.2539 85.2188 68.2188 88.2539 68.2188 92C68.2188 95.7461 71.2539 98.7812 75 98.7812C78.7461 98.7812 81.7812 95.7461 81.7812 92C81.7812 88.2539 78.7461 85.2188 75 85.2188ZM75 97.4688C71.9785 97.4688 69.5312 95.0215 69.5312 92C69.5312 88.9785 71.9785 86.5312 75 86.5312C78.0215 86.5312 80.4688 88.9785 80.4688 92C80.4688 95.0215 78.0215 97.4688 75 97.4688ZM76.6898 94.6141L74.3684 92.927C74.2836 92.8641 74.2344 92.7656 74.2344 92.6617V88.1719C74.2344 87.9914 74.382 87.8438 74.5625 87.8438H75.4375C75.618 87.8438 75.7656 87.9914 75.7656 88.1719V92.0465L77.5922 93.3754C77.7398 93.482 77.7699 93.6871 77.6633 93.8348L77.1492 94.543C77.0426 94.6879 76.8375 94.7207 76.6898 94.6141Z'
                    fill='#D4D1CF'
                />
            </g>
            <g clipPath='url(#clip2_297_60649)'>
                <path
                    d='M22 135.219C18.2539 135.219 15.2188 138.254 15.2188 142C15.2188 145.746 18.2539 148.781 22 148.781C25.7461 148.781 28.7812 145.746 28.7812 142C28.7812 138.254 25.7461 135.219 22 135.219ZM22 147.469C18.9785 147.469 16.5312 145.021 16.5312 142C16.5312 138.979 18.9785 136.531 22 136.531C25.0215 136.531 27.4688 138.979 27.4688 142C27.4688 145.021 25.0215 147.469 22 147.469ZM23.6898 144.614L21.3684 142.927C21.2836 142.864 21.2344 142.766 21.2344 142.662V138.172C21.2344 137.991 21.382 137.844 21.5625 137.844H22.4375C22.618 137.844 22.7656 137.991 22.7656 138.172V142.046L24.5922 143.375C24.7398 143.482 24.7699 143.687 24.6633 143.835L24.1492 144.543C24.0426 144.688 23.8375 144.721 23.6898 144.614Z'
                    fill='#D4D1CF'
                />
            </g>
            <defs>
                <clipPath id='clip0_297_60649'>
                    <rect width='14' height='14' fill='white' transform='translate(103 35)' />
                </clipPath>
                <clipPath id='clip1_297_60649'>
                    <rect width='14' height='14' fill='white' transform='translate(68 85)' />
                </clipPath>
                <clipPath id='clip2_297_60649'>
                    <rect width='14' height='14' fill='white' transform='translate(15 135)' />
                </clipPath>
            </defs>
        </svg>
    );
}
