/* @flow */

import * as React from 'react';
import {
    Creatable,
    components,
    type CreatableExtraProps,
    type SelectBaseProps,
    type ControlProps,
} from 'react-select';

import {Divider, Stack} from '../layout';
import {colors} from '../colors';

import type {FooterButtonProps} from './select-picker-generic';
import {selectPickerGenericStyles} from './select-picker-generic-styles';
import {getCustomMenuList} from './custom-menu-list';

export type Option = {value: string, label: string};
export type ActionMeta = {
    action:
        | 'select-option'
        | 'deselect-option'
        | 'remove-value'
        | 'pop-value'
        | 'set-value'
        | 'clear'
        | 'create-option',
};

type Props = {|
    ...$Exact<SelectBaseProps>,
    ...$Exact<CreatableExtraProps>,
    value?: ?Option,
    /** Unfortunately for now because of https://github.com/JedWatson/react-select/issues/2953, we have to use `value` and `label` keys */
    options: Option[],
    /** Not allowing multiple selections in this component */
    isMulti?: false,
    onChange?: (newValue: Object, actionMeta: ActionMeta) => void,
    /** Added for simplicity, components usually need to handle clearing differently from changes */
    onClear?: () => void,
    controlIcon?: React.ComponentType<*>,
    footerButton?: FooterButtonProps,
|};

export function SelectPickerGenericCreatable(props: Props) {
    const {onChange, onClear, footerButton, ...restProps} = props;

    const handleChange = (newValue, actionMeta) => {
        if (Array.isArray(newValue)) {
            // There's a bug, where backspacing to clear sends an array.
            // https://github.com/JedWatson/react-select/pull/3216 should fix it
            if (newValue.length === 0) {
                if (onClear) {
                    onClear();
                }

                return;
            }
        }

        if (actionMeta.action === 'clear' || newValue === null || typeof newValue === 'undefined') {
            if (onClear) {
                onClear();
            }
        } else if (onChange) {
            onChange(newValue, actionMeta);
        }
    };

    return (
        <Creatable
            {...restProps}
            isMulti={false}
            getOptionValue={undefined}
            getOptionLabel={undefined}
            onChange={handleChange}
            styles={selectPickerGenericStyles}
            components={{
                Control,
                MenuList: getCustomMenuList(footerButton),
                ...(props.components ? props.components : {}),
            }}
        />
    );
}

const Control = ({children, ...props}: {...ControlProps}) => (
    <components.Control {...props}>
        {props.selectProps.controlIcon ? (
            <Stack spacing={8} isHorizontal={true}>
                <div
                    style={{
                        marginTop: 2,
                        marginLeft: 8,
                    }}
                >
                    <props.selectProps.controlIcon
                        color={colors.grey}
                        size={13}
                        wrapWithDiv={false}
                    />
                </div>
                <Divider variant='line--vertical' />
            </Stack>
        ) : undefined}
        {children}
    </components.Control>
);
