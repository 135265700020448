/* @flow */
import * as React from 'react';

import {Checkbox} from 'shells/checkbox';

import {SummarizeCheckboxUpsell} from './summarize-checkbox-upsell';

type Props = {
    shouldSummarize: boolean,
    callHasEnded: boolean,
    setShouldSummarize: (boolean) => void,
    disabled: boolean,
};

export function SummarizeCheckbox(props: Props) {
    if (!NutClientConfig.canUseAiToken) {
        return <SummarizeCheckboxUpsell callHasEnded={props.callHasEnded} />;
    }

    return (
        <div className='mt-16'>
            <Checkbox
                label={'Automatically summarize this call ✨'}
                checked={props.shouldSummarize}
                disabled={props.disabled}
                onChange={(checked) => props.setShouldSummarize(checked)}
            />
        </div>
    );
}
