/* @flow */

import * as React from 'react';

import {useRefObserver} from '../../utils/hooks/use-ref-observer';

import {CopyIcon} from '../../icon';
import {Popover} from '../../popover';
import {colors} from '../../colors';

import './copy-icon-button.css';

const COPY_TEXT = 'Copy';
const COPIED_TEXT = 'Copied!';

type TooltipText = 'Copy' | 'Copied!';

type Props = {
    text: string,
    size?: 'big' | 'small',
    as?: 'div' | 'button',
    popoverBodyColor?: 'light' | 'dark' | 'error',
};

export function CopyIconButton(props: Props) {
    const {text, size, as = 'button', popoverBodyColor = 'dark'} = props;

    const buttonRef = React.useRef(null);
    const {isHovered} = useRefObserver(buttonRef, ['isHovered']);

    const [tooltipText, setTooltipText] = React.useState<TooltipText>(COPY_TEXT);

    const handleCopyLink = (event: SyntheticEvent<*>) => {
        event.preventDefault();
        event.stopPropagation();

        navigator.clipboard.writeText(text).then(() => {
            setTooltipText(COPIED_TEXT);
        });
    };

    React.useEffect(() => {
        if (!isHovered && tooltipText === COPIED_TEXT) {
            setTooltipText(COPY_TEXT);
        }
    }, [isHovered, tooltipText]);

    const Tag = as;

    return (
        <>
            <div
                ref={buttonRef}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                <Tag onClick={handleCopyLink} styleName='button'>
                    <CopyIcon
                        size={size === 'small' ? 13 : 15}
                        wrapWithDiv={false}
                        color={isHovered ? colors.greyDk : colors.greyLt}
                    />
                </Tag>
            </div>
            {isHovered && (
                <Popover anchor={buttonRef.current} bodyColor={popoverBodyColor} location='top'>
                    <div className='pad-8'>{tooltipText}</div>
                </Popover>
            )}
        </>
    );
}
