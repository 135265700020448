/* @flow */

import {useMutation} from '@apollo/react-hooks';
import moment from 'moment';

import type {
    CreateComment as CreateCommentMutation,
    CreateCommentVariables as CreateCommentMutationVariables,
    DeleteNote as DeleteCommentMutation,
    DeleteNoteVariables as DeleteCommentMutationVariables,
    SessionUser,
} from 'nutshell-graphql-types';

import EVENT_FRAGMENT from '../graphql/fragments/event-fragment-full.graphql';
import COMMENT_CREATE from './graphql/mutations/comment-create.graphql';
import COMMENT_DELETE from './graphql/mutations/note-delete.graphql';

export function useCreateComment(): ({
    changeLogId: string,
    parentId: string,
    value: string,
    user: SessionUser,
}) => Promise<*> {
    const [createComment] = useMutation<CreateCommentMutation, CreateCommentMutationVariables>(
        COMMENT_CREATE
    );

    return (commentInput: {
        changeLogId: string,
        parentId: string,
        value: string,
        user: SessionUser,
    }) => {
        return createComment({
            variables: {
                input: {
                    comment: {
                        parentId: commentInput.parentId,
                        value: commentInput.value,
                    },
                },
            },
            optimisticResponse: {
                __typename: 'Mutation',
                commentCreate: {
                    __typename: 'CreateCommentPayload',
                    comment: {
                        __typename: 'Comment',
                        id: 'temp',
                        createdTime: moment().unix(),
                        creatorUser: {
                            __typename: 'User',
                            id: commentInput.user.id,
                            name: commentInput.user.name,
                            avatarUrl: commentInput.user.avatarUrl,
                            htmlUrl: commentInput.user.htmlUrl,
                            initials: commentInput.user.initials,
                        },
                        isDeleteable: false,
                        body: null,
                        bodyHtml: commentInput.value,
                        reactions: [],
                        changeLogEntry: {
                            __typename: 'TimelineEvent',
                            id: commentInput.changeLogId,
                        },
                    },
                },
            },
            update: (proxy, mutationResults) => {
                const eventFragment = proxy.readFragment({
                    id: commentInput.changeLogId,
                    fragmentName: 'EventFragmentFull',
                    fragment: EVENT_FRAGMENT,
                });

                if (eventFragment) {
                    const newComment =
                        mutationResults &&
                        mutationResults.data &&
                        mutationResults.data.commentCreate &&
                        mutationResults.data.commentCreate.comment;

                    if (!newComment) return;

                    proxy.writeFragment({
                        id: commentInput.changeLogId,
                        fragment: EVENT_FRAGMENT,
                        fragmentName: 'EventFragmentFull',
                        data: {
                            ...eventFragment,
                            __typename: 'TimelineEvent',
                            comments: eventFragment.comments.concat({
                                ...newComment,
                                __typename: 'Comment',
                            }),
                        },
                    });
                }
            },
        });
    };
}

export function useDeleteComment(): ({changeLogId: string, commentId: string}) => Promise<*> {
    const [deleteComment] = useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(
        COMMENT_DELETE
    );

    return (commentInput: {changeLogId: string, commentId: string}) => {
        return deleteComment({
            variables: {input: {note: {id: commentInput.commentId}}},
            optimisticResponse: {
                noteDelete: {
                    __typename: 'DeleteNotePayload',
                    note: {
                        __typename: 'Note',
                        id: 'temp',
                        body: null,
                        bodyHtml: null,
                        bodyMarkup: null,
                        entity: null,
                        isEditable: false,
                        hasMentionedEntities: false,
                        htmlUrl: '',
                        htmlUrlPath: '',
                    },
                },
            },
            update: (proxy) => {
                const eventFragment = proxy.readFragment({
                    id: commentInput.changeLogId,
                    fragmentName: 'EventFragmentFull',
                    fragment: EVENT_FRAGMENT,
                });

                if (eventFragment) {
                    proxy.writeFragment({
                        id: commentInput.changeLogId,
                        fragment: EVENT_FRAGMENT,
                        fragmentName: 'EventFragmentFull',
                        data: {
                            ...eventFragment,
                            __typename: 'TimelineEvent',
                            comments: eventFragment.comments.filter(
                                (comment) => comment.id && comment.id !== commentInput.commentId
                            ),
                        },
                    });
                }
            },
        });
    };
}
