/* @flow */

import {useQuery} from '@apollo/react-hooks';

import {makeDataSafe, safelyGetGraphQLErrorMessage} from 'nutshell-core/utils/graphql-errors';

/**
 * This hook is used to run a simple paginated query. We run the passed in query
 * with limit and variables and get the items and pagination data. Types are a little
 * fuzzy since we can't pass the types into the hook, so we use 'any' a bit liberally.
 */
export const usePaginatedQuery = (input: {
    query: () => void,
    queryKey: string,
    limit: number,
    queryVariables?: Object,
}) => {
    const {query, queryKey, limit, queryVariables} = input;

    const {
        data,
        loading: isLoading,
        error,
        fetchMore,
        networkStatus,
    } = useQuery<any, any>(query, {
        variables: {first: limit, ...queryVariables},
        fetchPolicy: 'cache-and-network',
    });

    const safeData = makeDataSafe(data);

    const items = safeData[queryKey];

    const itemNodes: Object[] = items ? items.edges.map((edge) => edge.node) : [];
    const hasNextPage = items ? items.pageInfo.hasNextPage : false;

    const fetchMoreRows = () => {
        // $FlowIgnore - Unsure what error flow is showing, but this works
        return fetchMore({
            variables: {
                first: limit,
                after: items ? items.pageInfo.endCursor : null,
            },
            updateQuery: (prev: any, newResults: {fetchMoreResult: any}) => {
                const oldItems = prev[queryKey];
                const fetchedItems = newResults.fetchMoreResult[queryKey];

                if (!oldItems || !fetchedItems) return;

                const newItems = {
                    ...oldItems,
                    pageInfo: fetchedItems.pageInfo,
                    edges: oldItems.edges.concat(fetchedItems.edges),
                };

                return {[queryKey]: newItems};
            },
        });
    };

    return {
        items: itemNodes,
        isLoading,
        isFirstLoad: isLoading && networkStatus !== 3,
        error: safelyGetGraphQLErrorMessage(error),
        hasNextPage,
        fetchMoreRows,
    };
};
