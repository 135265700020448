import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Transcribe from './icon-transcribe.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Transcribe {...validProps} />;
}
SvgComponent.iconName = 'transcribe';

export const TranscribeIcon = svgIconWrapper(SvgComponent);
