/* @flow */
import * as React from 'react';
import {Body} from 'shells/typography';
import {Stack} from 'shells/layout';
import {LoadingText} from 'shells/loading-placeholder';

import './transcription-section.css';

type Props = {
    callHasEnded: boolean,
    shouldTranscribe: boolean,
    transcription?: ?string,
};

export function TranscriptionSection(props: Props) {
    if (!props.shouldTranscribe) {
        return (
            <Stack spacing={4}>
                <Body size='small' isBold={true}>
                    Transcription
                </Body>
                <div className='border br-3 px-16 py-8'>
                    <Body size='small'>This call is not being transcribed</Body>
                </div>
            </Stack>
        );
    }

    return (
        <Stack spacing={4}>
            <Body size='small' isBold={true}>
                Transcription
            </Body>
            <div styleName='section-wrapper'>
                {props.callHasEnded && props.transcription ? (
                    <Body size='small'>{props.transcription}</Body>
                ) : (
                    <>
                        <Body size='small'>
                            {props.callHasEnded
                                ? 'Your transcription is loading…'
                                : 'Your transcription will load here after this conversation ends.'}
                        </Body>
                        <LoadingText width={480} />
                        <LoadingText width={480} />
                    </>
                )}
            </div>
        </Stack>
    );
}
