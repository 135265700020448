/* @flow */
import {useQuery} from '@apollo/react-hooks';

import type {
    GetAssignable as GetAssignableQuery,
    GetAssignableVariables as GetAssignableQueryVariables,
} from 'nutshell-graphql-types';
import {safelyGetGraphQLErrorMessage} from 'nutshell-core/utils/graphql-errors';

import GET_ASSIGNABLE from '../queries/get-assignable.graphql';

export const useGetAssignable = (id: ?string) => {
    const {
        data,
        loading: isLoading,
        error,
    } = useQuery<GetAssignableQuery, GetAssignableQueryVariables>(GET_ASSIGNABLE, {
        // $FlowIgnore no id is covered by skip
        variables: {id},
        skip: !id,
    });

    return {
        assignable: data && data.assignable ? data.assignable : undefined,
        isLoading,
        errorMessage: safelyGetGraphQLErrorMessage(error),
    };
};
