/* @flow */

import * as React from 'react';
import capitalize from 'underscore.string/capitalize';

import {DesktopIcon, TabletIcon, MobilePhoneIcon} from '../../../../icon';
import {Stack} from '../../../../layout';
import {Divider} from '../../../../layout/divider';

import type {DeviceType} from '../../../types';

import './session-metadata.css';

type Props = {
    locationSummary: ?string,
    deviceType: DeviceType,
    platformType: ?string,
};

export function SessionMetadata(props: Props) {
    const {locationSummary, deviceType} = props;
    const platformType = props.platformType === 'Unknown' ? undefined : props.platformType;

    let Icon: ?React.ComponentType<*> = null;
    // This is only used for the icon
    switch (deviceType) {
        case 'desktop':
            Icon = DesktopIcon;
            break;
        case 'tablet':
            Icon = TabletIcon;
            break;
        case 'mobile':
            Icon = MobilePhoneIcon;
            break;
    }

    const capitalizeLocationSummary = (location: string): string => {
        // Turns 'ann arbor, MI' to 'Ann Arbor, MI'
        return location
            .split(' ')
            .map((word) => capitalize(word))
            .join(' ');
    };

    return platformType || locationSummary ? (
        <div styleName='session-metadata'>
            {platformType ? (
                <Stack spacing={8} isHorizontal={true}>
                    <div>{Icon ? <Icon size={15} /> : undefined}</div>
                    {platformType}
                </Stack>
            ) : undefined}
            {platformType && locationSummary ? <Divider variant='dot' spacing={6} /> : undefined}
            {locationSummary ? <div>{capitalizeLocationSummary(locationSummary)}</div> : undefined}
        </div>
    ) : null;
}
