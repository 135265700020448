/* @flow */

import * as React from 'react';

import {RadioGroupContext} from './radio-group';

import '../checkbox/checkbox-button.css';

type Props = {
    value: string,
    icon: React.Node,
    children?: React.Node,
};

export class RadioIconButton extends React.Component<Props> {
    render() {
        return (
            <RadioGroupContext.Consumer>
                {({name, selectedValue, onChange}) => {
                    const checked = this.props.value === selectedValue;
                    const radioId = `${name}-${this.props.value}`;

                    return (
                        <div
                            styleName={`input-button-icon ${checked ? 'checked' : ''}`}
                            onClick={() => onChange(this.props.value)}
                        >
                            <div styleName='icon-container'>{this.props.icon}</div>
                            <label htmlFor={radioId}>{this.props.children}</label>
                        </div>
                    );
                }}
            </RadioGroupContext.Consumer>
        );
    }
}
