import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return (
        <svg {...validProps} xmlns='http://www.w3.org/2000/svg' viewBox={'0 0 27.5 20'}>
            <path
                fill='currentColor'
                d='M22,0H17.6l5.5,10L17.6,20H22l5.5-10ZM13.2,0H8.8l5.5,10L8.8,20h4.4l5.5-10ZM4.4,0H0L5.5,10,0,20H4.4L9.9,10Z'
            />
        </svg>
    );
}
SvgComponent.iconName = 'step';

export const StepIcon = svgIconWrapper(SvgComponent);
