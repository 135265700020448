/* @flow */

import {createSelector, type InputSelector, type OutputSelector} from 'reselect';
import * as ramda from 'ramda';
// Flow libdefs for chain and compose are missing, so import in a way that flow doesn't understand
import chain from 'ramda/es/chain';
import compose from 'ramda/es/compose';
import type {
    AccountId,
    Address,
    Contact,
    ContactId,
    CustomFieldAttribute,
    Email,
    EmailAddress,
    Phone,
    PhoneNumber,
    UrlString,
} from '../types';
import {getUrlHref} from '../url/get-url-href';
import {convertAddressToString} from '../eav/convert-address-to-string';
import type {State as ContactsState} from './contacts-reducer';
import type {EditableContact} from './contacts-types';

// The root state will include more keys, but this is what we really care about here
type ContactsModuleState = {contacts: ContactsState};

/*
 * Given the entire redux state, pull out just the piece of state for the contacts reducer.
 */
export const getContactsState = (state: ContactsModuleState): ContactsState => state.contacts;

/*
 * Get the `byId` portion of the contacts state
 */
// $FlowFixMe upgrading Flow to v0.92.1
export const getById = createSelector([getContactsState], (contactsState) => contactsState.byId);

/*
 * Get the `unknownEmailAddresses` portion of the contacts state
 */
// $FlowFixMe upgrading Flow to v0.92.1
export const getUnknownEmailAddresses = createSelector(
    [getContactsState],
    (contactsState) => contactsState.unknownEmailAddresses
);

/*
 * Get the `isLoading` portion of the contacts state
 */
// $FlowFixMe upgrading Flow to v0.92.1
export const getIsLoading = createSelector(
    [getContactsState],
    (contactsState) => contactsState.isLoading
);

/*
 * Return a list of all the contact ids from our store in an array.
 */
// $FlowFixMe upgrading Flow to v0.92.1
export const getIds = createSelector([getById], (contactsById) => Object.keys(contactsById));

/*
 * Get a list of all loaded contacts
 */
const getAll = createSelector([getById], (contactsById): Contact[] => ramda.values(contactsById));

/*
 * Get a list of all email addresses from all loaded contacts
 */
const getEmailsFromContact = (contact: Contact): EmailAddress[] =>
    contact.emails.map((email) => email.value);
// $FlowFixMe upgrading Flow to v0.92.1
export const getAllEmailAddresses = createSelector([getAll], (allContacts) =>
    chain(getEmailsFromContact, allContacts)
);

/*
 * This returns a memoized function that accepts two arrays of email
 * addresses, and returns a sorted list of contact ids, with the contact assocciated with the
 * "from" addresses first, and others sorted alphabetically by contact name (first name).
 */
const contactSorter = (contacts: Contact[]) =>
    ramda.memoizeWith(
        ramda.toString,
        (fromAddresses: EmailAddress[], otherAddresses: EmailAddress[]): ContactId[] => {
            const sortedContacts = [];
            if (!contacts.length) return [];

            // Add all senders to the start of the sortedContacts array
            // Maintain the existing sort order of the fromAddresses array
            const fromContacts = fromAddresses
                .map((fromAddress) =>
                    contacts.find((contact) =>
                        ramda.any(
                            (email) => email.value.toLowerCase() === fromAddress.toLowerCase()
                        )(contact.emails)
                    )
                )
                .filter(Boolean);
            if (fromContacts.length) {
                sortedContacts.push(...fromContacts);
            }

            // Add other contacts if there are any
            const otherAddressesLower = otherAddresses
                .filter((otherAddress) => !fromAddresses.includes(otherAddress))
                .map((otherAddress) => otherAddress.toLowerCase());
            const otherContacts = contacts.filter((contact) =>
                contact.emails.find((email) =>
                    otherAddressesLower.includes(email.value.toLowerCase())
                )
            );
            if (otherContacts.length) {
                const sortedByName = ramda.sortBy(compose(ramda.toLower, ramda.prop('name')))(
                    otherContacts
                );
                sortedContacts.push(...sortedByName);
            }

            return sortedContacts.map((contact) => contact.id);
        }
    );

export const getSortedContactIds: OutputSelector<
    ContactsModuleState,
    {||},
    (fromAddresses: EmailAddress[], otherAddresses: EmailAddress[]) => ContactId[],
> = createSelector([getAll], contactSorter);

/*
 * Retrieve a single contact by id, by contactId
 */
export const getContact = (state: ContactsModuleState, contactId: ContactId) => {
    return getById(state)[contactId];
};

/*
 * Retrieve a single contact in "editable" form. THis flattens out the EAV fields
 * and converts the CustomFields array to a map.
 */
// $FlowFixMe upgrading Flow to v0.92.1
export const getEditableContact = createSelector(
    [getContact],
    (contact: Contact): EditableContact => {
        const editableContact = {...contact};

        if (editableContact.phones) {
            editableContact.phones = editableContact.phones.map((phone) => ({
                name: phone.name,
                value: phone.value.numberFormatted,
            }));
        }

        if (editableContact.addresses) {
            editableContact.addresses = editableContact.addresses.map((address) => ({
                name: address.name,
                value: convertAddressToString(address.value),
            }));
        }

        if (editableContact.customFields) {
            editableContact.customFields = editableContact.customFields.reduce(
                (acc, field) => ({
                    ...acc,
                    [field.title]: field.value,
                }),
                {}
            );
        }

        return editableContact;
    }
);

// $FlowFixMe upgrading Flow to v0.92.1
export const getUrlHrefValues = createSelector([getContact], (contact) => {
    if (!contact) return [];
    // Check that there are urls
    if (!contact.urls.length) return [];
    // Find a primary url so we can put it at the top
    const primaryUrl = contact.urls.find((url) => url.isPrimary);
    const urlValues = primaryUrl ? [getUrlHref(primaryUrl)] : [];
    if (contact.urls.length > 1) {
        const nonPrimaryUrlValues = contact.urls
            .filter((url) => !url.isPrimary)
            .map((url) => getUrlHref(url));
        // We dont't care about the order of the other urls
        urlValues.push(...nonPrimaryUrlValues);
    }

    return urlValues;
});

/*
 * Returns an array of email address objects,
 * with the primary email address first, and the others in random order
 */
export const getEmailAddressesForContact: InputSelector<ContactsModuleState, ContactId, Email[]> =
    createSelector([getContact], (contact) => {
        if (!contact) return [];
        // Check that there are email addresses
        if (!contact.emails.length) return [];

        return contact.emails.sort((a, b) => {
            if (a.isPrimary) return -1;
            if (b.isPrimary) return 1;

            return 0;
        });
    });

/*
 * Returns an array of phone number objects,
 * with the primary first, and the others in random order
 */
export const getPhonesForContact: InputSelector<ContactsModuleState, ContactId, Phone[]> =
    createSelector([getContact], (contact) => {
        if (!contact) return [];
        // Check that there are phones
        if (!contact.phones.length) return [];

        return contact.phones.sort((a, b) => {
            if (a.isPrimary) return -1;
            if (b.isPrimary) return 1;

            return 0;
        });
    });

/*
 * Returns an array of postal address objects,
 * with the primary first, and the others in random order
 */
export const getPostalAddressesForContact: InputSelector<
    ContactsModuleState,
    ContactId,
    Address[],
> = createSelector([getContact], (contact) => {
    if (!contact) return [];
    // Check that there are addresses
    if (!contact.addresses.length) return [];

    return contact.addresses.sort((a, b) => {
        if (a.isPrimary) return -1;
        if (b.isPrimary) return 1;

        return 0;
    });
});

export const getCustomFields: InputSelector<
    ContactsModuleState,
    ContactId,
    CustomFieldAttribute[],
> = createSelector([getContact], (contact) => {
    if (!contact) return [];

    return contact.customFields || [];
});

export const getPrimaryPhoneValue: InputSelector<ContactsModuleState, ContactId, ?PhoneNumber> =
    createSelector([getContact], (contact) => {
        if (!contact) return null;
        // Check that there are phone numbers
        if (!contact.phones.length) return null;
        // Look for a primary phone out of the list of phones
        const primaryPhone = contact.phones.find((phone) => phone.isPrimary);
        // Make sure we found a primary phone
        if (!primaryPhone) return null;

        return primaryPhone.value;
    });

export const getHtmlUrl: InputSelector<ContactsModuleState, ContactId, UrlString> = createSelector(
    [getContact],
    (contact) => contact.htmlUrl
);

/*
 * Given a contact object, return an object containing related entity ids, keyed
 * by entity type
 */
const getRelatedEntityIds: InputSelector<ContactsModuleState, ContactId, Object> = createSelector(
    [getContact],
    (contact) => {
        if (!contact) return {};

        const relatedEntityTypes = ['accounts', 'contacts'];

        return ramda.pick(relatedEntityTypes, contact.links || {});
    }
);

export const getRelatedAccountIds: InputSelector<ContactsModuleState, ContactId, AccountId[]> =
    createSelector([getRelatedEntityIds], (relatedEntityIds) => {
        if (!relatedEntityIds || !relatedEntityIds.accounts) return [];

        return relatedEntityIds.accounts;
    });

const getContactsList = (state: ContactsModuleState) => getContactsState(state).list;

const getContactsListSchema = (state: ContactsModuleState) => getContactsList(state).schema;

export const getContactListSchemaStatus = (state: ContactsModuleState) =>
    getContactsListSchema(state).status;

// $FlowFixMe upgrading Flow to v0.92.1
export const getCustomFieldSchema = createSelector(getContactsListSchema, (schema) => {
    return schema.properties
        ? ramda.filter((field) => Boolean(field.isCustomField), schema.properties)
        : {};
});

export function getListItemMap(state: ContactsModuleState) {
    return getContactsState(state).listItemsById;
}
