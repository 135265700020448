/* @flow */

import * as React from 'react';

import {StatusBadge} from '../../../status-badge';
import {Button} from '../../../button';
import {ButtonMover} from '../../../button-mover';
import {Stack} from '../../../layout';

import type {TimelineEntryStatus} from '../../types';

type Props = {
    status: TimelineEntryStatus,
};

export function TimelineEntryStatusBar(props: Props) {
    const {primary, secondary, action} = props.status;

    return (
        <Stack spacing={12} isHorizontal={true} alignCenter={true}>
            <StatusBadge level={primary.level} text={primary.text} size='small' />
            {secondary ? (
                <StatusBadge level={secondary.level} text={secondary.text} size='small' />
            ) : undefined}
            {action ? (
                <ButtonMover size='normal' direction={['up', 'right', 'down', 'left']}>
                    <Button variant='text-secondary' onClick={action.onClick} size='small'>
                        {action.buttonText}
                    </Button>
                </ButtonMover>
            ) : undefined}
        </Stack>
    );
}
