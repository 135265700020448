/* @flow */

import * as React from 'react';

import {useQuery} from '@apollo/react-hooks';

import type {
    GetEntitySummary as GetEntitySummaryQuery,
    EntitySummaryFragment,
} from 'nutshell-graphql-types';

import {makeDataSafe, safelyGetGraphQLErrorMessage} from 'nutshell-core/utils/graphql-errors';

import GET_ENTITY_SUMMARY from '../queries/get-entity-summary.graphql';

export const useGetEntitySummary = (
    entityId: string
): ({
    entitySummary: ?EntitySummaryFragment,
    isLoading: boolean,
    error: ?string,
    refetch: () => Promise<*>,
    isRefetching: boolean,
    startPolling: (number) => void,
    stopPolling: () => void,
}) => {
    const {data, error, loading, startPolling, stopPolling, refetch, networkStatus} = useQuery<
        GetEntitySummaryQuery,
        {},
    >(GET_ENTITY_SUMMARY, {
        fetchPolicy: 'cache-and-network',
        variables: {
            id: entityId,
        },
    });

    const {entitySummary} = makeDataSafe(data);

    React.useEffect(() => {
        // Start polling if the entitySummary status is in progress
        if (entitySummary && entitySummary.status === 'IN_PROGRESS') {
            startPolling(2000); // 2 seconds
        } else if (entitySummary && entitySummary.status !== 'IN_PROGRESS') {
            stopPolling();
        }
    }, [entitySummary, startPolling, stopPolling]);

    return {
        entitySummary,
        // https://github.com/apollographql/apollo-client/blob/main/src/core/networkStatus.ts
        isLoading: loading && networkStatus !== 4, // refetching is 4
        error: safelyGetGraphQLErrorMessage(error),
        refetch,
        isRefetching: networkStatus === 4,
        startPolling,
        stopPolling,
    };
};
