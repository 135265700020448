/* @flow */

import {useQuery} from '@apollo/react-hooks';

import type {
    EventFragmentFull_payload_Activity_relatedLeads as ActivityRelatedLead,
    EventFragmentFull_payload_Chat_relatedLeads as ChatRelatedLead,
    EventFragmentFull_payload_Email_emailRelatedLeads as EmailRelatedLead,
    GetEvent as GetRelatedLeadsQuery,
    GetEventVariables as GetRelatedLeadsQueryVariables,
} from 'nutshell-graphql-types';

import {safelyGetGraphQLErrorMessage} from 'nutshell-core/utils/graphql-errors';

import GET_RELATED_LEADS from '../queries/get-related-leads.graphql';

type RelatedLead = ActivityRelatedLead | ChatRelatedLead;

export function useGetRelatedLeads(input: GetRelatedLeadsQueryVariables): {
    relatedLeads: ?(RelatedLead[]),
    emailRelatedLeads: ?EmailRelatedLead,
    isLoading: boolean,
    error: ?string,
} {
    const {data, error, loading} = useQuery<GetRelatedLeadsQuery, GetRelatedLeadsQueryVariables>(
        GET_RELATED_LEADS,
        {
            variables: input,
            fetchPolicy: 'cache-and-network',
        }
    );

    let relatedLeads, emailRelatedLeads;
    if (data && data.event) {
        switch (data.event.payload.__typename) {
            case 'Activity':
            case 'Chat':
                relatedLeads = data.event.payload.relatedLeads;
                break;
            case 'Email':
                emailRelatedLeads = data.event.payload.emailRelatedLeads;
                break;
        }
    }

    return {
        relatedLeads,
        emailRelatedLeads,
        isLoading: loading,
        error: safelyGetGraphQLErrorMessage(error),
    };
}
