/* @flow */
import * as React from 'react';

import type {EntitySummaryStatus} from 'nutshell-graphql-types';

import {Body} from 'shells/typography';
import {SparkleIcon} from 'shells/icon';
import {LoadingText} from 'shells/loading-placeholder';

import './transcription-section.css';

type Props = {
    callHasEnded: boolean,
    summary?: ?string,
    summaryStatus?: ?EntitySummaryStatus,
};

export function SummarizeSection(props: Props) {
    let summaryBody;
    switch (props.summaryStatus) {
        case 'ERRORED':
            summaryBody = (
                <Body color='error' size='small'>
                    There was an error generating the summary.
                </Body>
            );
            break;
        case 'COMPLETE':
            summaryBody = <Body size='small'>{props.summary}</Body>;
            break;
        // We need to default to the "IN_PROGRESS" state because the summary
        // status may not have a value set yet
        default:
            summaryBody = (
                <>
                    <Body size='small'>
                        {props.callHasEnded ? (
                            <LoadingText width={400} />
                        ) : (
                            'Summary will be generated after this conversation ends.'
                        )}
                    </Body>
                    <LoadingText width={480} />
                </>
            );
            break;
    }

    return (
        <div styleName='section-wrapper'>
            <div className='flex align-center gap-8'>
                <SparkleIcon size={12} alignCenter={true} wrapWithDiv={false} />
                <Body size='mini' isBold={true}>
                    AI Summary
                </Body>
            </div>
            {summaryBody}
        </div>
    );
}
