/* @flow */

import {useQuery} from '@apollo/react-hooks';

import type {
    SessionFragment as McfxCompanySessions,
    GetMcfxCompanySessions as GetMcfxCompanySessionsQuery,
    GetMcfxCompanySessionsVariables as GetMcfxCompanySessionsQueryVariables,
} from 'nutshell-graphql-types';

import {safelyGetGraphQLErrorMessage} from 'nutshell-core/utils/graphql-errors';

import GET_MCFX_COMPANY_SESSIONS from '../queries/get-mcfx-company-sessions.graphql';

export function useGetMcfxCompanySessions(input: GetMcfxCompanySessionsQueryVariables): {
    mcfxCompanySessions: ?(McfxCompanySessions[]),
    isLoading: boolean,
    error: ?string,
} {
    const {data, error, loading} = useQuery<
        GetMcfxCompanySessionsQuery,
        GetMcfxCompanySessionsQueryVariables,
    >(GET_MCFX_COMPANY_SESSIONS, {
        variables: input,
        fetchPolicy: 'cache-and-network',
    });

    return {
        mcfxCompanySessions: data ? data.sessions : undefined,
        isLoading: loading,
        error: safelyGetGraphQLErrorMessage(error),
    };
}
