/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import Sms from './icon-sms.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <Sms {...validProps} />;
}
SvgComponent.iconName = 'sms';

export const SmsIcon = svgIconWrapper(SvgComponent);
