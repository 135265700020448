/* @flow */

import * as React from 'react';

import {Price} from '../../billing/price';
import {Body} from '../../typography';

type Props = {stickerPrice?: string, finalPrice: string, discount?: ?string};

export function PriceCell(props: Props) {
    const shouldShowFinalPrice = props.stickerPrice !== props.finalPrice;
    const finalPriceIsDiscounted = Boolean(
        props.stickerPrice && props.stickerPrice !== props.finalPrice
    );

    return (
        <td>
            <div className='flex flex-dir-col align-end'>
                {props.stickerPrice && (
                    <Price
                        price={props.stickerPrice}
                        strikethrough={finalPriceIsDiscounted}
                        color={finalPriceIsDiscounted ? 'light' : 'medium'}
                        includeDollarSign={false}
                        size='normal'
                        isBold={false}
                        isFlexEnd={true}
                    />
                )}
                {shouldShowFinalPrice && (
                    <Price
                        price={props.finalPrice}
                        color={finalPriceIsDiscounted ? 'green' : 'medium'}
                        size='normal'
                        includeDollarSign={false}
                        isBold={false}
                        isFlexEnd={true}
                    />
                )}
                {props.discount && (
                    <div className='flex'>
                        <Body color='light'>(</Body>
                        <Price
                            price={props.discount}
                            color='light'
                            size='normal'
                            includeDollarSign={false}
                            isBold={false}
                            isFlexEnd={true}
                        />
                        <Body color='light'>)</Body>
                    </div>
                )}
            </div>
        </td>
    );
}
