/* @flow */
import * as React from 'react';
import ReactTooltip from 'react-tooltip';

import {ArrowsAscIcon, ArrowsDescIcon, UpgradeArrowIcon} from '../icon';
import {colors} from '../colors';
import {Popover, type LocationEnum} from './popover';

import './popover-sort.css';

export type Direction = 'ascending' | 'descending';

type FieldOptions = {
    id: string,
    label: string,
};

type Props = {
    activeSort: {
        field: string,
        direction?: Direction,
    },
    fields: FieldOptions[],
    onChangeSort: (sortField: string) => any,
    footerRow?: {
        label: string,
        icon: React.Node,
        onClick: () => void,
        requiresUpgrade?: boolean,
    },
    onClose: () => void,
    location: LocationEnum,
    anchor: ?HTMLElement,
};

export function PopoverSort(props: Props) {
    return (
        <Popover
            anchor={props.anchor}
            location={props.location}
            onBlur={props.onClose}
            customStyles={{marginLeft: -2, marginTop: 2}}
            noDefaultStyling={true}
        >
            <ul styleName='popover-sort-container'>
                {props.fields.map((field) => (
                    <SortItem
                        key={field.id}
                        activeDirection={
                            props.activeSort.field === field.id ? props.activeSort.direction : null
                        }
                        onClick={props.onChangeSort}
                        field={field}
                    />
                ))}
                {props.footerRow ? (
                    <div
                        styleName={
                            props.footerRow.requiresUpgrade
                                ? 'footer-button--upgrade'
                                : 'footer-button'
                        }
                        onClick={props.footerRow.onClick}
                    >
                        <div className='flex align-center'>
                            <div className='mr-8'>{props.footerRow.icon}</div>
                            {props.footerRow.label}
                        </div>
                        {props.footerRow.requiresUpgrade ? (
                            <UpgradeArrowIcon size={15} alignCenter={true} color={colors.blue} />
                        ) : undefined}
                    </div>
                ) : null}
            </ul>
        </Popover>
    );
}

function SortItem(props: {
    activeDirection: ?Direction,
    onClick: (sortField: string) => any,
    field: FieldOptions,
}) {
    React.useEffect(() => {
        ReactTooltip.rebuild();

        return () => ReactTooltip.hide();
    }, []);

    return (
        <li
            styleName='row-item'
            className='justify-sb'
            data-tip={getTooltip(props.field.label, props.activeDirection)}
            data-place='left'
            onClick={() => props.onClick(props.field.id)}
        >
            {props.field.label}
            {props.activeDirection && (
                <span className='flex'>
                    <ArrowsDescIcon
                        size={15}
                        wrapWithDiv={false}
                        fill={
                            props.activeDirection === 'descending' ? colors.blue : colors.offWhiteDk
                        }
                    />
                    <ArrowsAscIcon
                        size={15}
                        wrapWithDiv={false}
                        fill={
                            props.activeDirection === 'ascending' ? colors.blue : colors.offWhiteDk
                        }
                    />
                </span>
            )}
        </li>
    );
}

function getTooltip(label: string, activeDirection: ?Direction) {
    if (activeDirection === 'ascending' || !activeDirection) {
        return `Sort by ${label} (descending)`;
    }

    return `Sort by ${label} (ascending)`;
}
