/* @flow */

import * as React from 'react';

export const useSubscribeToPhtmlCheckbox = (
    settingCheckboxElementName: ?string,
    setIsEnabledChecked: (boolean) => void
) => {
    React.useEffect(() => {
        if (!settingCheckboxElementName) {
            return;
        }

        const checkboxes = document.getElementsByName(settingCheckboxElementName);
        const checkbox = checkboxes && checkboxes.length ? checkboxes[0] : null;

        setIsEnabledChecked(checkbox instanceof HTMLInputElement && checkbox.checked);

        const handleIsEnabledChange = (event: Event) => {
            if (event.target && event.target instanceof HTMLInputElement) {
                setIsEnabledChecked(event.target.checked);
            }
        };

        if (checkbox) {
            checkbox.addEventListener('change', handleIsEnabledChange);
        }

        return () => {
            if (checkbox) {
                checkbox.removeEventListener('change', handleIsEnabledChange);
            }
        };
    }, [settingCheckboxElementName, setIsEnabledChecked]);
};
