import PropTypes from 'prop-types';
import * as React from 'react';
import classnames from 'classnames';
import {CalendarDayQuickView} from './calendar-day-quick-view';
import './calendar-day.css';

export class CalendarDay extends React.Component {
    static propTypes = {
        momentDate: PropTypes.object.isRequired,
        isToday: PropTypes.bool.isRequired,
        isDisabled: PropTypes.bool.isRequired,
        isOtherMonth: PropTypes.bool.isRequired,
        quickViews: PropTypes.array,
        onClick: PropTypes.func.isRequired,
        onMouseOver: PropTypes.func,
        onMouseOut: PropTypes.func,

        isSelected: PropTypes.bool.isRequired,
        isSelectedForComparison: PropTypes.bool.isRequired,
        isFirstDayOfRow: PropTypes.bool.isRequired,
        isLastDayOfRow: PropTypes.bool.isRequired,
        isPartOfSelectedRange: PropTypes.bool.isRequired,
        isStartOfSelectedRange: PropTypes.bool.isRequired,
        isEndOfSelectedRange: PropTypes.bool.isRequired,
        isPartOfSelectedComparisonRange: PropTypes.bool.isRequired,
        isStartOfSelectedComparisonRange: PropTypes.bool.isRequired,
        isEndOfSelectedComparisonRange: PropTypes.bool.isRequired,
        isPartOfHoveredRange: PropTypes.bool.isRequired,
        isStartOfHoveredRange: PropTypes.bool.isRequired,
        isEndOfHoveredRange: PropTypes.bool.isRequired,
        hasRoundedDates: PropTypes.bool,
    };

    render() {
        const borderRadius = this.calcBorderRadius();
        const isSelectedStyle = this.props.isPartOfSelectedRange || this.props.isSelected;
        const isSelectedForComparisonStyle =
            this.props.isPartOfSelectedComparisonRange || this.props.isSelectedForComparison;
        const styleNames = classnames('container', {
            'container-hovered': this.props.isPartOfHoveredRange,
            'container-selected': isSelectedStyle,
            'container-comparison-selected': isSelectedForComparisonStyle,
            'container-disabled': this.props.isDisabled,
            'container-other-month': this.props.isOtherMonth,
            'container-today': this.props.isToday,
            'container-no-radius': this.props.quickViews,
        });

        return (
            <div
                styleName={styleNames}
                onClick={this.handleClick}
                onMouseOver={this.handleMouseOver}
                onMouseOut={this.handleMouseOut}
            >
                <div styleName='hover-container' style={{borderRadius: borderRadius}}>
                    {this.props.momentDate.format('D')}
                </div>
                {this.props.quickViews && this.props.quickViews.length ? (
                    <CalendarDayQuickView
                        isSelected={isSelectedStyle}
                        quickViews={this.props.quickViews}
                    />
                ) : undefined}
            </div>
        );
    }

    calcBorderRadius = () => {
        if (this.props.hasRoundedDates) {
            return '24px';
        }

        const isPartOfAnySelectedRange =
            this.props.isPartOfSelectedRange || this.props.isPartOfSelectedComparisonRange;
        const isStartOfAnySelectedRange =
            this.props.isStartOfSelectedRange || this.props.isStartOfSelectedComparisonRange;
        const isEndOfAnySelectedRange =
            this.props.isEndOfSelectedRange || this.props.isEndOfSelectedComparisonRange;

        // Need rectangular date displays when quick views are present
        if (this.props.quickViews) {
            return 0;
        }

        let borderRadiusTopLeft = '24px';
        let borderRadiusTopRight = '24px';
        let borderRadiusBottomRight = '24px';
        let borderRadiusBottomLeft = '24px';

        if (isPartOfAnySelectedRange || this.props.isPartOfHoveredRange) {
            borderRadiusTopLeft = '0';
            borderRadiusTopRight = '0';
            borderRadiusBottomRight = '0';
            borderRadiusBottomLeft = '0';
        }

        if (
            this.props.isFirstDayOfRow ||
            isStartOfAnySelectedRange ||
            this.props.isStartOfHoveredRange
        ) {
            borderRadiusTopLeft = '24px';
            borderRadiusBottomLeft = '24px';
        }

        if (
            this.props.isLastDayOfRow ||
            isEndOfAnySelectedRange ||
            this.props.isEndOfHoveredRange
        ) {
            borderRadiusTopRight = '24px';
            borderRadiusBottomRight = '24px';
        }

        return `${borderRadiusTopLeft} ${borderRadiusTopRight} ${borderRadiusBottomRight} ${borderRadiusBottomLeft}`;
    };

    handleClick = () => {
        if (!this.props.isDisabled) {
            this.props.onClick(this.props.momentDate);
        }
    };

    handleMouseOver = () => {
        if (this.props.onMouseOver && !this.props.isDisabled) {
            this.props.onMouseOver(this.props.momentDate);
        }
    };

    handleMouseOut = () => {
        if (this.props.onMouseOver && !this.props.isDisabled) {
            this.props.onMouseOut(this.props.momentDate);
        }
    };
}
