/* @flow */

import {useMutation} from '@apollo/react-hooks';

import type {
    UpdateSetting as UpdateSettingMutation,
    UpdateSettingVariables as UpdateSettingMutationVariables,
} from 'nutshell-graphql-types';

import SESSION_USER_FRAGMENT from './graphql/fragments/session-user-fragment.graphql';
import UPDATE_SETTING from './graphql/mutations/update-setting.graphql';

export function useUpdateOnboardingSetting(): ({
    userId: string,
    settingName: string,
    value: string,
}) => Promise<*> {
    const [updateSetting] = useMutation<UpdateSettingMutation, UpdateSettingMutationVariables>(
        UPDATE_SETTING
    );

    return (input: {userId: string, settingName: string, value: string}) => {
        return updateSetting({
            variables: {
                setting: {
                    name: input.settingName,
                    value: input.value,
                    isGlobal: false,
                },
            },
            optimisticResponse: {
                __typename: 'Mutation',
                updateSetting: {
                    __typename: 'UpdateSettingPayload',
                    setting: {
                        __typename: 'Setting',
                        id: 'temp',
                        name: input.settingName,
                        value: input.value,
                        isGlobal: false,
                    },
                },
            },
            update: (proxy, mutationResults) => {
                const userFragment = proxy.readFragment({
                    id: input.userId,
                    fragmentName: 'SessionUser',
                    fragment: SESSION_USER_FRAGMENT,
                });

                if (userFragment) {
                    const newSetting =
                        mutationResults &&
                        mutationResults.data &&
                        mutationResults.data.updateSetting &&
                        mutationResults.data.updateSetting.setting;

                    if (!newSetting) return;

                    const isHidden = Boolean(newSetting.value === '1');
                    const data = {
                        ...userFragment,
                        __typename: 'User',
                        hiddenOnboardingSettings: isHidden
                            ? userFragment.hiddenOnboardingSettings.concat([newSetting.id])
                            : userFragment.hiddenOnboardingSettings.filter(
                                  (settingName) => settingName !== newSetting.id
                              ),
                    };

                    proxy.writeFragment({
                        id: input.userId,
                        fragmentName: 'SessionUser',
                        fragment: SESSION_USER_FRAGMENT,
                        data: data,
                    });
                }
            },
        });
    };
}
