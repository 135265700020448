/* @flow */

import {createSelector, type InputSelector, type OutputSelector} from 'reselect';
import * as ramda from 'ramda';
import type {User, Assignable} from '../types';
import {orderUsersAndTeams} from '../order-users-and-teams';
import {Users, type UsersState} from '../users';
import {Teams, type TeamsState} from '../teams';

// The root state will include more keys, but this is what we really care about here
type AssignablesState = {users: UsersState, teams: TeamsState};

/**
 * Selector to get users and teams models, ordered in our nutshell opinionated way,
 * pushing disabled users and teams to the bottom of the list.
 *
 * @return {Object[]}                - Array of user and team models
 */

export const getAll: InputSelector<AssignablesState, void, Assignable[]> = createSelector(
    [Users.getById, Teams.getById],
    (usersById, teamsById) => {
        const users = ramda.values(usersById).filter((user) => !user.isHiddenFromFilters);
        const teams = ramda.values(teamsById);
        const assignables = [].concat(users).concat(teams);

        return orderUsersAndTeams(assignables);
    }
);

export const getAllEnabled: OutputSelector<AssignablesState, void, Assignable[]> = createSelector(
    [getAll],
    (assignables) => {
        return assignables.filter(
            (assignable) => assignable.isEnabled || assignable.type === 'teams'
        );
    }
);

export const getAllEnabledUsers: OutputSelector<AssignablesState, void, User[]> = createSelector(
    [getAll],
    (assignables) => {
        // $FlowFixMe it thinks we could still have teams here, not sure why
        return assignables.filter(
            (assignable) => assignable.type === 'users' && assignable.isEnabled
        );
    }
);

export const getAgents: OutputSelector<AssignablesState, void, User[]> = createSelector(
    [getAll],
    (assignables) => {
        // $FlowFixMe it thinks we could still have teams here, not sure why (c&p)
        return assignables.filter((assignable) => {
            return assignable.type === 'users' && assignable.isAgent;
        });
    }
);

export const getAllUsers: OutputSelector<AssignablesState, void, User[]> = createSelector(
    [getAll],
    (assignables) => {
        // $FlowFixMe it thinks we could still have teams here, not sure why
        return assignables.filter((assignable) => assignable.type === 'users');
    }
);

export const getEnabledUsersByPermission = (
    state: AssignablesState,
    permission: string
): User[] => {
    return getAllEnabledUsers(state).filter((user) => {
        return user.permissions[permission];
    });
};

export const getById: InputSelector<AssignablesState, void, {[id: string]: Assignable}> =
    createSelector([Users.getById, Teams.getById], (usersById, teamsById) => {
        return {...usersById, ...teamsById};
    });

export const get = (state: AssignablesState, assignableId: string) => {
    return getById(state)[assignableId];
};
