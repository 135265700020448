/* @flow */

import * as React from 'react';
import ReactTooltip from 'react-tooltip';

import {AddReactionIcon} from '../../icon';
import {Popover} from '../../popover';

import type {Reaction} from './types';
import {REACTIONS} from './consts';

import './add-reaction-button.css';

type Props = {
    onSelectReaction: (selectedReaction: Reaction) => any,
};

export function AddReactionButton(props: Props) {
    const [showPopover, setShowPopover] = React.useState<boolean>(false);

    const addButtonRef = React.useRef(undefined);

    function togglePopover() {
        setShowPopover((prevState) => !prevState);
    }

    React.useEffect(() => {
        ReactTooltip.rebuild();

        return () => ReactTooltip.hide();
    }, []);

    return (
        <>
            {showPopover ? (
                <Popover
                    anchor={addButtonRef ? addButtonRef.current : undefined}
                    location='top'
                    onBlur={togglePopover}
                >
                    <div styleName='reaction-selection-popover'>
                        {Object.keys(REACTIONS).map((reaction: Reaction) => (
                            <button
                                key={reaction}
                                styleName='reaction-button'
                                title={REACTIONS[reaction].label}
                                onClick={() => {
                                    props.onSelectReaction(reaction);
                                    setShowPopover(false);
                                }}
                            >
                                {REACTIONS[reaction].unicode}
                            </button>
                        ))}
                    </div>
                </Popover>
            ) : undefined}
            <button
                ref={addButtonRef}
                styleName={showPopover ? 'add-reaction-button--active' : 'add-reaction-button'}
                data-tip='Add reaction'
                onClick={togglePopover}
            >
                <AddReactionIcon size={15} />
            </button>
        </>
    );
}
