/* @flow */

import {Observable} from 'rxjs/Observable';
import type {ActionsObservable} from 'redux-observable';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';

import type {AccountsApiResponse} from '../types';
import {
    failFetchById,
    resolveFetchById,
    ActionTypes,
    type AccountsAction,
    type ActionFetchByIdRequest,
} from './accounts-actions';
import * as apiMethods from './accounts-api';

export const makeFetchAccountByIdEpic =
    (fetchAccountById: typeof apiMethods.fetchAccountById = apiMethods.fetchAccountById) =>
    (action$: ActionsObservable<AccountsAction>) =>
        action$
            .ofType(ActionTypes.ACCOUNTS_FETCH_BY_ID_REQUEST)
            .switchMap((action: ActionFetchByIdRequest) => {
                return Observable.fromPromise(fetchAccountById(action.payload))
                    .map((response: AccountsApiResponse) => resolveFetchById(response))
                    .catch((error) => Observable.of(failFetchById(error)));
            });
