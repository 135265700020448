/* @flow */

import * as React from 'react';

import type {GetUsageScorecard_usageScorecard_sections_features as Feature} from 'nutshell-graphql-types';

import {colors} from 'shells/colors';
import {Body} from 'shells/typography';
import {ProgressBar} from 'shells/progress-bar';

import './usage-scorecard.css';

type Props = {
    feature: Feature,
};

export function UsageScorecardRow(props: Props) {
    const {feature} = props;

    let usedRatio = feature.planLimit ? feature.currentUsage / feature.planLimit : 0;
    if (feature.customLimit) {
        usedRatio = feature.currentUsage / feature.customLimit;
    }
    // Display 100% if the plan limit is 0 and there is more than 0 usage
    if (feature.planLimit === 0 && feature.currentUsage > 0) {
        usedRatio = 1;
    }

    // Null represents no limit in the backend, so we need to display '∞' in
    // that case. Otherwise, just display the number for the plan limit.
    const currentUsageLocaleString = feature.currentUsage.toLocaleString();
    const planLimit = feature.planLimit === null ? '∞' : feature.planLimit;
    const planLimitLocaleString = planLimit === '∞' ? '∞' : String(planLimit).toLocaleString();
    const customLimitLocaleString = feature.customLimit
        ? feature.customLimit.toLocaleString()
        : null;

    return (
        <div styleName='table-row'>
            <div styleName='feature-name'>
                <Body size='normal'>{feature.displayName}</Body>
            </div>
            <div styleName='progress-container'>
                <div className='text-align-right'>
                    <Body size='small'>{currentUsageLocaleString} / </Body>
                    {feature.customLimit ? (
                        <>
                            <Body size='small' strikethrough={true}>
                                {planLimitLocaleString}
                            </Body>
                            <span styleName='custom-limit'>
                                <Body size='small'>{customLimitLocaleString}</Body>
                            </span>
                        </>
                    ) : (
                        <Body size='small'>{planLimitLocaleString}</Body>
                    )}
                    <div className='mt-4'>
                        <ProgressBar
                            percentComplete={usedRatio}
                            height={8}
                            roundedCorners={true}
                            color='black'
                            backgroundColor={colors.offWhiteDk}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
