/* @flow */

import * as React from 'react';
import {Link as RoutedLink} from 'react-router-dom';

import {Card} from '../../card';
import {Body} from '../../typography';
import {Stack} from '../../layout';
import {LearnMore} from '../learn-more';
import {LoadingPage} from '../../loading-page';
import {ErrorState} from '../../error-state';

import type {AddOn} from '../types';
import {Link} from '../../link';
import {getAddOnTitle, getAddOnLink} from '../add-ons/helpers';
import {SectionHeader} from './section-header';
import {CouponPill} from './coupon-pill';

import './enhance-your-plan.css';

type Props = {|
    addOns: AddOn[],
    onOpenModal: () => void,
    isLoading: boolean,
    errorMessage: ?string,
|};

export function EnhanceYourPlan(props: Props) {
    return (
        <Card hasBorder={true} fitHeight={true}>
            <Stack spacing={0}>
                <SectionHeader title='Enhance your plan with Nutshell add-ons' />
                <div styleName='container'>
                    {props.isLoading && (
                        <div className='pad-16'>
                            <LoadingPage />
                        </div>
                    )}
                    {props.errorMessage && (
                        <div className='pad-16'>
                            <ErrorState errorMessage={props.errorMessage} size='small' />
                        </div>
                    )}
                    {!props.isLoading && !props.errorMessage && (
                        <>
                            {props.addOns.map((addOn) => {
                                if (addOn.plans.length === 0) {
                                    return null;
                                }

                                const firstPlan = addOn.plans[0];

                                const product = firstPlan.product;
                                const title = getAddOnTitle(product);
                                const linkTo = getAddOnLink(product);

                                return (
                                    <div styleName='add-on-container' key={product}>
                                        <div styleName='add-on-content'>
                                            <Stack spacing={8}>
                                                <Stack spacing={4}>
                                                    <Body isBold={true}>{title}</Body>
                                                    <Body size='small'>
                                                        {firstPlan.description}
                                                    </Body>
                                                </Stack>
                                                {firstPlan.coupon && (
                                                    <CouponPill coupon={firstPlan.coupon} />
                                                )}
                                                <LearnMore
                                                    product={product}
                                                    onOpenModal={props.onOpenModal}
                                                    size='small'
                                                />
                                            </Stack>
                                            <Link
                                                as={RoutedLink}
                                                variant='button-secondary'
                                                to={linkTo}
                                            >
                                                Add to plan
                                            </Link>
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    )}
                </div>
            </Stack>
        </Card>
    );
}
