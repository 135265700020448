/* @flow */

import * as React from 'react';
import ReactTooltip from 'react-tooltip';

import {Link} from '../link';
import {Avatar, DEFAULT_SIZE} from '../avatar';
import {LeadCountAvatar} from '../lead/lead-count-avatar';
import type {Participant, Lead} from '../timeline/types';

import './overlapping-avatars.css';

// Offset in px to account for border
const BORDER_OFFSET = 4;

type Props = {|
    avatars: Participant[],
    relatedLeads?: Lead[],
    maxDisplayed?: number,
    size?: number,
    withTooltip?: boolean,
    getRef?: any,
|};

export function OverlappingAvatars(props: Props) {
    const {avatars, maxDisplayed, withTooltip, getRef} = props;

    const size = props.size ? props.size : DEFAULT_SIZE;
    const relatedLeads = props.relatedLeads ? props.relatedLeads : [];
    const displayableParticipants: Participant[] = avatars.filter(
        // We do not want to display an avatar for these participant types
        (participant) =>
            !(
                participant.type === 'emailAddress' ||
                participant.type === 'origins' ||
                participant.type === 'leads'
            )
    );

    // Total number of avatars displayed, including related leads and hidden avatar names
    let totalNumDisplayed = relatedLeads.length ? 1 : 0;
    // List of avatars that are displayed
    let displayedAvatars = [...displayableParticipants];
    // List of overflow avatar names that are not displayed
    let hiddenAvatarNames: ?(string[]);

    if (maxDisplayed) {
        const maxAvatars = maxDisplayed - totalNumDisplayed;
        const numHidden = displayableParticipants.length - maxAvatars;

        // If the number of hidden avatars is more than one, we will display them in a +X list
        if (numHidden > 1) {
            displayedAvatars = displayableParticipants.slice(0, maxAvatars);
            hiddenAvatarNames = displayableParticipants
                .slice(maxAvatars)
                .map((avatar) => avatar.name);

            // Update total displayed to include +X hidden
            totalNumDisplayed++;
        }
    }

    // Update total number displayed
    totalNumDisplayed += displayedAvatars.length;

    React.useEffect(() => {
        ReactTooltip.rebuild();

        return () => ReactTooltip.hide();
    }, [hiddenAvatarNames]);

    const iconSeparation: number = Math.ceil(0.85 * size);
    const indexOffset = relatedLeads.length ? 1 : 0;

    return (
        <div
            ref={getRef}
            styleName='avatar-list'
            style={{
                height: size + BORDER_OFFSET,
                // Width of avatar list
                width: size + iconSeparation * (totalNumDisplayed - 1) + BORDER_OFFSET,
            }}
        >
            {relatedLeads.length ? (
                <div
                    styleName='avatar-wrapper'
                    style={{
                        width: size + BORDER_OFFSET,
                        height: size + BORDER_OFFSET,
                    }}
                >
                    <LeadCountAvatar
                        leads={relatedLeads.map((lead) => ({id: lead.id, name: lead.name}))}
                        size={size}
                    />
                </div>
            ) : undefined}
            {displayedAvatars.map((entity, index) => {
                // Display avatars with htmlUrls as clickable links
                const Tag = entity.htmlUrl ? Link : 'div';
                let linkProps = {};
                if (entity.htmlUrl) {
                    linkProps = {
                        href: entity.htmlUrl,
                    };
                }

                return (
                    <Tag
                        key={entity.id}
                        styleName={entity.htmlUrl ? 'avatar-wrapper--link' : 'avatar-wrapper'}
                        style={{
                            left: (index + indexOffset) * iconSeparation,
                            zIndex: index + indexOffset,
                            width: size + BORDER_OFFSET,
                            height: size + BORDER_OFFSET,
                        }}
                        data-tip={withTooltip ? entity.name : undefined}
                        data-place='bottom'
                        // Needed to prevent modal from popping up when clickable avatar is clicked
                        onClick={(e) => {
                            if (entity.htmlUrl) {
                                e.stopPropagation();
                            }
                        }}
                        {...linkProps}
                    >
                        <Avatar
                            size={size}
                            avatarUrl={entity.avatarUrl}
                            type={entity.type}
                            initials={entity.initials || ''}
                            circular={true}
                            border={true}
                        />
                    </Tag>
                );
            })}
            {hiddenAvatarNames && maxDisplayed ? (
                <div
                    styleName='hidden-avatars'
                    style={{
                        left: maxDisplayed * iconSeparation,
                        zIndex: maxDisplayed,
                        width: size,
                        height: size,
                        borderRadius: size,
                    }}
                    data-tip={withTooltip ? hiddenAvatarNames.join('<br />') : undefined}
                    data-place='bottom'
                >
                    +{hiddenAvatarNames.length}
                </div>
            ) : undefined}
        </div>
    );
}
