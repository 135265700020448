/* @flow */

import * as ramda from 'ramda';
import {api} from '../api';
import type {ListSchemaResponse} from './schema-types';

export function fetchForDashboard() {
    return fetchSchema('dashboards/list/fields');
}

export function fetchForForms() {
    return fetchBaseSchema('forms/fields').then(wrapWithProperties).then(addName);
}

export function connectedFormsFetch() {
    return fetchBaseSchema('forms/cf/fields').then(wrapWithProperties).then(addName);
}

export function fetchBaseSchema(url: string): Promise<ListSchemaResponse> {
    return api.get(url).then((response) => response.json());
}

export function fetchSchema(url: string): Promise<ListSchemaResponse> {
    return fetchBaseSchema(url).then(addName);
}

/*
 * The properties that are returned from the server do not include a `name` property,
 * because it is an object map (i.e. the key is the `name`).  But, much of our
 * logic already depends on having a `name` property.  Perviously it was being
 * added only to leads schemas in a redux selector.  By moving it here, it helps
 * to unify our flow typing and ensure that `name` is always available to all
 * schema types from the get-go.
 */
const mapNameToProperty = ramda.mapObjIndexed((field, key) => {
    field.name = key;

    return field;
});

export function wrapWithProperties(response: Object) {
    return {
        properties: response,
    };
}

// Takes an existing object of schema fields from the server and translates
// each custom field so that the key is actually the entityAttributeId instead
// of the custom field name.

// i.e. 'Birthday': {...} becomes '1008-eavEntityAttributes': {...}
export function keyWithIds(response: Object) {
    return Object.keys(response).reduce((acc, currentKey) => {
        const field = response[currentKey];
        if (!field) {
            return acc;
        }

        return field.entityAttributeId
            ? {...acc, [field.entityAttributeId]: field}
            : {...acc, [currentKey]: field};
    }, {});
}

export function addName(response: Object) {
    response.properties = mapNameToProperty(response.properties);

    return response;
}
