/* @flow */

import * as React from 'react';

import {Popover, type LocationEnum} from '../popover';
import {ScrollableContainerWithShadow, Stack} from '../layout';
import {LoadingPage} from '../loading-page';

import type {Item} from './types';

import {ListItem} from './list-item';

import {BulkSelectButton} from './bulk-select-button';

import {getUpdatedItemsList, getBulkUpdatedItemsList} from './helpers';

import './mini-list-popover.css';

export type Props = {|
    anchor: any,
    headerText?: string,
    collection: Item[],
    renderRow: (item: Item) => React.Node,
    // For controlled (by parent) input - do not pass both, pass none for readonly version
    onSelect?: (id: string) => void,
    // For uncontrolled (by parent) input - do not pass both, pass none for readonly version
    onBlur?: (updatedItems: Item[]) => void,
    isSaving?: boolean,
    onClose?: () => void,
    location?: LocationEnum,
    disableOverlayClickToBlur?: boolean,
|};

export function MiniListPopover(props: Props) {
    const {
        collection,
        anchor,
        renderRow,
        onSelect,
        onBlur,
        isSaving,
        onClose,
        location = 'bottom-left',
        disableOverlayClickToBlur,
    } = props;

    // Internal state for uncontrolled (by parent) input, if the list is selectable
    const [selectableItemsList, setSelectableItemsList] = React.useState<?(Item[])>(undefined);

    React.useEffect(() => {
        if (onBlur && !selectableItemsList) {
            setSelectableItemsList(collection);
        }
    }, [collection, selectableItemsList, onBlur]);

    let handleOnSelect;
    if (onSelect) {
        handleOnSelect = (id: string) => onSelect(id);
    } else if (selectableItemsList) {
        handleOnSelect = (id: string) => {
            setSelectableItemsList((prevState) =>
                prevState ? getUpdatedItemsList(prevState, id) : undefined
            );
        };
    }

    const shouldListScroll = collection.length > 5;
    const Container = shouldListScroll ? ScrollableContainerWithShadow : 'div';
    const scrollProps = shouldListScroll
        ? {
              direction: 'vertical',
              maxContainerSize: 220,
              showReverseGradientShadow: true,
          }
        : {};

    const handleBlur = () => {
        if (onBlur) {
            onBlur(selectableItemsList ? selectableItemsList : collection);
        }

        if (onClose) {
            onClose();
        }
    };

    const handleBulkSelection = (selectAll: boolean) => {
        setSelectableItemsList((prevState) =>
            prevState ? getBulkUpdatedItemsList(prevState, selectAll) : undefined
        );
    };

    return anchor && anchor.current ? (
        <Popover
            anchor={anchor.current}
            location={location}
            onBlur={handleBlur}
            disableOverlayClickToBlur={disableOverlayClickToBlur}
        >
            <div styleName='mini-list-popover'>
                {isSaving ? (
                    <div styleName='loading-container'>
                        <LoadingPage padding={0} />
                    </div>
                ) : undefined}
                <Container {...scrollProps}>
                    {props.headerText ? (
                        <div styleName='header'>{props.headerText}</div>
                    ) : undefined}
                    <Stack spacing={4}>
                        <BulkSelectButton
                            collection={props.collection}
                            selectableItemsList={selectableItemsList}
                            onClick={handleBulkSelection}
                        />
                        {(selectableItemsList || collection).map((item) => (
                            <ListItem
                                key={item.id}
                                id={item.id}
                                content={renderRow(item)}
                                isChecked={item.isChecked}
                                onSelect={handleOnSelect}
                                isDisabled={isSaving}
                            />
                        ))}
                    </Stack>
                </Container>
            </div>
        </Popover>
    ) : null;
}
