/* @flow */

import type {UsersApiResponse, User} from '../types';
import {getSessionStateUserHasQueuedExports} from './session-selectors';
import {createMockUser} from './session-helpers';
import {
    ActionTypes,
    type SessionModuleState,
    type FetchSessionRequestAction,
    type FetchSessionSuccessAction,
    type FetchSessionFailureAction,
    type AddHackyDomUserAction,
} from './session-types';

/*
 * The different action states with respect to fetching the session data via
 * the REST API using the /rest/session endpoint (which is basically an
 * endpoint to echo back structured session data based on an authenticated REST
 * API request). The full flow can be kicked off by dispatching the
 * asynchronous action, `fetchSessionData()`.
 */
export const requestFetchSession = (
    baseUrl?: string = '/rest/',
    options?: RequestOptions
): FetchSessionRequestAction => ({
    type: ActionTypes.SESSION_FETCH_SESSION_REQUEST,
    payload: {baseUrl, options},
});
export const resolveFetchSession = (payload: UsersApiResponse): FetchSessionSuccessAction => ({
    type: ActionTypes.SESSION_FETCH_SESSION_SUCCESS,
    payload,
});
export const failFetchSession = (payload?: Object): FetchSessionFailureAction => ({
    type: ActionTypes.SESSION_FETCH_SESSION_FAILURE,
    payload,
    error: true,
});
export const addHackyDOMUser = (payload: ?User): AddHackyDomUserAction => ({
    type: ActionTypes.SESSION_HACKY_DOM_USER_ADDED,
    payload,
});

/**
 * A helper function that will retrieve the logged-in user data from a data attribute within
 * our web app's DOM, and return it as if it were a response from the `sessions` endpoint.
 * The purpose of this function is solely a micro-performance-optimization hack
 * (setting up our MVP app state session without being blocked by a REST call).
 *
 * @return {Promise} Promise that will resolve to a `sessions` response, and will never reject
 *
 * [d]: http://redux.js.org/docs/api/Store.html#dispatch
 */
export function fetchUserFromDOMAsAHackToQuicklyUpdateOurSession(): Promise<?User> {
    return new Promise((resolve) => {
        // If this is executed in an environment where there is no `document`,
        // let's just reutrn an empty response object and go on with everyone's lives.
        if (typeof document === 'undefined') {
            resolve(null);
        }
        document.addEventListener('DOMContentLoaded', function () {
            try {
                const body = document.querySelector('body');
                const loggedInUserData = body && body.dataset.loggedInUser;
                if (typeof loggedInUserData === 'string' && loggedInUserData.includes('users')) {
                    resolve(
                        createMockUser({
                            id: loggedInUserData,
                            type: 'users',
                            emails: [],
                            permissions: {},
                            links: {teams: []},
                            sendForUser: [],
                            notifyMessages: [],
                        })
                    );
                }
            } catch (e) {
                // Catch all errors and silently fail since this method is a hack to
                // speed up performance. It should not be used as the sole mechanism to
                // fetch this user information for the app session state.
            }
        });
    });
}

/**
 * Helper function to poll for queued exports every 2 seconds. We'll do this
 * by fetching session data, which has the side-effect of updating our session
 * state within the store.
 *
 * We'll then use our selector to grab specific session data, and continue to
 * poll until our exports are complete.
 * @param  {Dispatch} dispatch             Built it dispatch function from redux
 * @param  {Function} getState             Built in getState function to grab the current
 *                                         state of our store
 * @return {void}
 */
export function pollForQueuedExports() {
    return function (dispatch: Function, getState: () => SessionModuleState) {
        // Has the side-effect of updating our session state with the newest
        // notification info for the logged-in-user
        dispatch(requestFetchSession());

        setTimeout(() => {
            const hasQueuedExports = getSessionStateUserHasQueuedExports(getState());
            if (hasQueuedExports) {
                pollForQueuedExports()(dispatch, getState);
            }
        }, 2000);
    };
}

/**
 * [Thunk] Call to ackowledge an export and then re-request session data on success, so
 * our session knows about the current state of the export world
 * @param  {object} notify      `Notify` object to flip the `acknowledged` bit
 * @return {Function}           Thunk to leverage dispatch
 */
export function acknowledgeExport(notify: Object) {
    return function (dispatch: Function) {
        const Nut = window && window.Nut;
        // This is shared code, and not all environments set `Nut` on `window`
        if (typeof Nut !== 'undefined') {
            Nut.ajax.post({
                url: '/notify/ack-export.json',
                data: {notifyId: notify.name},
                success: () => {
                    dispatch(requestFetchSession());
                },
            });
        }
    };
}
