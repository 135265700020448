/* @flow */

import * as React from 'react';

import {Divider, Stack} from '../../layout';

import type {ContentView} from '../visitor-sidebar';

import type {Lead} from './company-details/company-lead';
import {CompanyHeader, type CompanyHeaderDetails} from './company-header/company-header';
import {CompanyDetails, type CompanyInfo} from './company-details/company-details';
import {SessionDetails, type Session} from './session-details/session-details';
import {CompanyPeople, type Person} from './company-people/company-people';
import {FooterAddButtons} from './footer-add-buttons/footer-add-buttons';

import './visitor-content.css';

export type Company = {|
    details: {|
        ...CompanyHeaderDetails,
        ...CompanyInfo,
    |},
    lead?: ?Lead,
    session: Session,
|};

type Props = {|
    company: Company,
    people: ?(Person[]),
    setContentView: (contentView: ContentView) => void,
    onClose: () => void,
    onAddPeople: (peopleIds: string[]) => Promise<*>,
    onAddCompany: (peopleIds: string[], buttonRef: any, onConfirmAdd: () => void) => any,
    unlocksRemaining: ?number,
|};

export function VisitorContent(props: Props) {
    const {company} = props;

    const [selectedPersonIds, setSelectedPersonIds] = React.useState<string[]>([]);

    const companyContainerRef = React.useRef(null);
    const footerContainerRef = React.useRef(null);

    // Only companies that are already added in Nutshell have a htmlUrlPath
    const isCompanyAdded = Boolean(company.details.htmlUrlPath);

    return (
        <div styleName='visitor-content'>
            <div ref={companyContainerRef}>
                <div styleName='visitor-details-container'>
                    <Stack spacing={24}>
                        <CompanyHeader
                            name={company.details.name}
                            timestamp={company.details.timestamp}
                            htmlUrlPath={company.details.htmlUrlPath}
                            wasConvertedAutomatically={company.details.wasConvertedAutomatically}
                        />
                        <CompanyDetails
                            lead={company.lead}
                            url={company.details.url}
                            location={company.details.location}
                            industry={company.details.industry}
                            revenue={company.details.revenue}
                            numEmployees={company.details.numEmployees}
                            onClose={props.onClose}
                        />
                    </Stack>
                </div>
                <Divider variant='line--horizontal' />
                <SessionDetails
                    {...company.session}
                    onExpandDetails={() => props.setContentView('SESSIONS')}
                />
                <Divider variant='line--horizontal' />
            </div>
            <CompanyPeople
                people={props.people}
                selectedPersonIds={selectedPersonIds}
                updateSelectedPersonIds={(personId) =>
                    setSelectedPersonIds((prevSelectedPersonIds) =>
                        prevSelectedPersonIds.includes(personId)
                            ? prevSelectedPersonIds.filter((id) => id !== personId)
                            : [...prevSelectedPersonIds, personId]
                    )
                }
                offsetHeightRefs={[companyContainerRef, footerContainerRef]}
                onExpandPersonDetails={(personId) => props.setContentView({personId})}
                onClose={props.onClose}
            />
            <div ref={footerContainerRef}>
                {props.people ? (
                    <FooterAddButtons
                        companyName={company.details.name}
                        onAddCompany={
                            isCompanyAdded
                                ? undefined
                                : (buttonRef) =>
                                      props.onAddCompany(selectedPersonIds, buttonRef, () =>
                                          setSelectedPersonIds([])
                                      )
                        }
                        onAddPeople={
                            isCompanyAdded
                                ? () =>
                                      props
                                          .onAddPeople(selectedPersonIds)
                                          .then(() => setSelectedPersonIds([]))
                                : undefined
                        }
                        numSelectedPeople={selectedPersonIds.length}
                        unlocksRemaining={props.unlocksRemaining}
                    />
                ) : undefined}
            </div>
        </div>
    );
}
