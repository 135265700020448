/* @flow */

import * as React from 'react';
import ReactTooltip from 'react-tooltip';

import {LinkedEntity} from '../linked-entity';
import type {Participant} from '../timeline/types';
import {shouldShowDelimiter} from '../utils/should-show-delimiter';

import './truncated-group.css';

type Props = {|
    participants: Participant[],
    includeMoreWord?: boolean,
    maxWidth: number,
    showEmailAddressTooltip?: boolean,
|};

// We need a sane cap so that React doesn't continually try and re-render
// hundreds of participants 1 by 1, potentially causing it to think its
// in an infinite render cycle. Some activities have hundreds of people
const MAX_PARTICIPANTS_SHOWN_EVER = 10;

// This component is used when we want to display a limited group, but instead of
// passing in the number of items to display, we pass in a maxWidth and then
// calculate how many items can be displayed.
export function TruncatedGroup(props: Props) {
    const {participants, includeMoreWord, maxWidth, showEmailAddressTooltip} = props;

    const models = React.useMemo(() => {
        return participants.slice(0, MAX_PARTICIPANTS_SHOWN_EVER).map((participant, index) => {
            return (
                <span
                    key={participant.id}
                    style={{display: index !== 0 ? 'inline-flex' : ''}}
                    data-tip={
                        showEmailAddressTooltip && participant.emailAddress
                            ? participant.emailAddress
                            : undefined
                    }
                    data-place='bottom'
                >
                    <LinkedEntity
                        useEntityColor={true}
                        stopPropagation={true}
                        shouldClientRoute={true}
                        greenLeads={true}
                        htmlUrl={participant.htmlUrl}
                        type={participant.type}
                        name={participant.name}
                        allowWrap={index === 0}
                        displayInline={index === 0}
                    />
                    {shouldShowDelimiter(index, participants.length) ? (
                        <span>,&nbsp;</span>
                    ) : undefined}
                </span>
            );
        });
    }, [participants, showEmailAddressTooltip]);

    const participantNames: string[] = participants.map((participant) => participant.name);

    const [participantsWidth, setParticipantsWidth] = React.useState<number>(0);
    const [numDisplayed, setNumDisplayed] = React.useState<number>(models.length);

    const firstModelRef = React.useRef(null);
    const shownModelsRef = React.useRef(null);

    // Reset numDisplayed when maxWidth changes
    React.useLayoutEffect(() => {
        if (maxWidth > 0) {
            setNumDisplayed(participants.length);
        }
    }, [participants, maxWidth]);

    // Get the width of the shown participant container + first displayed model
    React.useLayoutEffect(() => {
        const shownContainer = shownModelsRef.current;
        const firstModel = firstModelRef.current;
        if (shownContainer && firstModel) {
            setParticipantsWidth(shownContainer.clientWidth + firstModel.clientWidth);
        }
    }, [numDisplayed]);

    // Decrease the number displayed until the width of the div holding the
    // displayed models can fit inside the maxWidth, always displaying at least 1
    React.useLayoutEffect(() => {
        if (maxWidth > 0 && participantsWidth > 0 && numDisplayed > 1) {
            if (maxWidth < participantsWidth) {
                setNumDisplayed(Math.min(numDisplayed - 1, MAX_PARTICIPANTS_SHOWN_EVER));
            }
        }
    }, [maxWidth, models, participantsWidth, numDisplayed]);

    const shownModels = models.slice(0, numDisplayed);
    const firstModel = shownModels.shift(); // We will always show the first model
    const hiddenNames: string[] = participantNames.slice(numDisplayed);

    React.useEffect(() => {
        ReactTooltip.rebuild();

        return () => ReactTooltip.hide();
    }, [hiddenNames]);

    return (
        <span>
            <span
                // If this is not the only model displayed, this needs to be inline-block
                className={shownModels.length ? 'inline-block' : ''}
                ref={(ref) => {
                    if (ref) {
                        firstModelRef.current = ref;
                    }
                }}
            >
                {firstModel}
            </span>
            {shownModels.length || hiddenNames.length ? (
                <div styleName='container'>
                    <div ref={shownModelsRef} styleName='shown'>
                        {shownModels}
                    </div>
                    {hiddenNames.length ? (
                        <div
                            styleName='hidden'
                            data-tip={hiddenNames.join('<br />')}
                            data-place='bottom'
                        >
                            +{hiddenNames.length}
                            {includeMoreWord ? ' more' : ''}
                        </div>
                    ) : undefined}
                </div>
            ) : undefined}
        </span>
    );
}
