/* @flow */

import {useQuery} from '@apollo/react-hooks';
import {safelyGetGraphQLErrorMessage, makeDataSafe} from 'nutshell-core/utils/graphql-errors';
import type {
    GetSessionUser as GetSessionUserQuery,
    GetSessionUser_loggedInUser,
} from 'nutshell-graphql-types';

import GET_SESSION_USER from './graphql/queries/get-session-user.graphql';

type Return = {
    isLoading: boolean,
    user: ?GetSessionUser_loggedInUser,
    errorMessage: ?string,
};

export const useGetSessionUser = (): Return => {
    const {
        loading: isLoading,
        data,
        error,
    } = useQuery<GetSessionUserQuery, {}>(GET_SESSION_USER, {
        fetchPolicy: 'cache-and-network',
    });

    const {loggedInUser} = makeDataSafe(data);

    return {
        isLoading,
        user: loggedInUser,
        errorMessage: safelyGetGraphQLErrorMessage(error),
    };
};
