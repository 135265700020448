/* @flow */

import {useQuery} from '@apollo/react-hooks';

import type {
    GetRelatedEntitiesSearchResults as GetRelatedEntitiesSearchResultsQuery,
    GetRelatedEntitiesSearchResultsVariables as GetRelatedEntitiesSearchResultsQueryVariables,
} from 'nutshell-graphql-types';

import {makeDataSafe} from 'nutshell-core/utils/graphql-errors';

import GET_RELATED_ENTITIES_SEARCH_RESULTS from '../queries/get-related-entities-search-results.graphql';

export const useGetRelatedEntities = (
    query: ?string
): ({
    isLoading: boolean,
    relatedEntities: Object[],
    refetch: () => Promise<*>,
    fetchMore: (searchTerm: ?string) => Promise<*>,
}) => {
    const {data, loading, refetch, fetchMore} = useQuery<
        GetRelatedEntitiesSearchResultsQuery,
        GetRelatedEntitiesSearchResultsQueryVariables,
    >(GET_RELATED_ENTITIES_SEARCH_RESULTS, {
        fetchPolicy: 'cache-and-network',
        variables: {
            query,
        },
        notifyOnNetworkStatusChange: true,
    });

    const {search} = makeDataSafe(data);
    const quickResults = search ? search.quickResults : null;

    const relatedEntities = quickResults ? quickResults.topResults : [];

    return {
        relatedEntities: relatedEntities,
        isLoading: loading,
        refetch,
        fetchMore: (searchTerm: ?string) => {
            // $FlowIgnore
            return fetchMore({
                query: GET_RELATED_ENTITIES_SEARCH_RESULTS,
                variables: {
                    query: searchTerm,
                },
                updateQuery: (previousResult, {fetchMoreResult}) => {
                    if (!fetchMoreResult || !previousResult) return;

                    return {
                        search: {
                            __typename: 'SearchResultItemConnection',
                            ...fetchMoreResult.search,
                        },
                    };
                },
            });
        },
    };
};
