/* @flow */

import * as React from 'react';

import {SectionHeader} from '../billing-summary/section-header';

export function UsageScorecardHeader() {
    return (
        <>
            <SectionHeader
                title='Current usage and plan limits'
                subtitle='Overview of your current usage compared to your plan’s maximum limits'
            />
        </>
    );
}
