/* @flow */

import {combineReducers} from 'redux';

import {ActionTypes, type TinyIntroAction} from './tiny-intro-actions';

type EmailState = $PropertyType<TinyIntroState, 'userEmail'>;
export const userEmail = (state: EmailState = null, action: TinyIntroAction): EmailState => {
    switch (action.type) {
        case ActionTypes.TINY_INTRO_SET_EMAIL:
            return action.payload;
        default:
            return state;
    }
};

type SignupTokenState = $PropertyType<TinyIntroState, 'userSignupToken'>;
export const userSignupToken = (
    state: SignupTokenState = null,
    action: TinyIntroAction
): SignupTokenState => {
    switch (action.type) {
        case ActionTypes.TINY_INTRO_SET_SIGNUP_TOKEN:
            return action.payload;
        default:
            return state;
    }
};

type AccountProvisionedState = {
    status: boolean,
    instanceId: ?string,
    emailSha1: ?string,
};

export const userAccountProvisionResponse = (
    state: AccountProvisionedState = {
        status: false,
        instanceId: null,
        emailSha1: null,
    },
    action: TinyIntroAction
): AccountProvisionedState => {
    switch (action.type) {
        case ActionTypes.TINY_INTRO_SET_PROVISION_RESPONSE:
            return action.payload;
        default:
            return state;
    }
};

type AccountProvisionedErrorMessage = $PropertyType<
    TinyIntroState,
    'userAccountProvisionedErrorMessage',
>;
export const userAccountProvisionedErrorMessage = (
    state: AccountProvisionedErrorMessage = null,
    action: TinyIntroAction
): AccountProvisionedErrorMessage => {
    switch (action.type) {
        case ActionTypes.TINY_INTRO_SET_PROVISION_ERROR_MESSAGE:
            return action.payload;
        default:
            return state;
    }
};

export type TinyIntroState = {
    userEmail: ?string,
    userSignupToken: ?string,
    userAccountProvisionResponse: AccountProvisionedState,
    userAccountProvisionedErrorMessage: ?string,
};

// $FlowFixMe upgrading Flow to v0.92.1 on web
export const tinyIntroReducer = combineReducers({
    userEmail,
    userSignupToken,
    userAccountProvisionResponse,
    userAccountProvisionedErrorMessage,
});
