/* @flow */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {ApolloProvider} from 'react-apollo';
import {ApolloProvider as ReactHooksApolloProvider} from '@apollo/react-hooks';
import {Provider} from 'react-redux';

import {store} from '../store';
import {nutApolloClient} from '../apollo-client';
import {PhoneCallContainer} from '../ui/phone-call/phone-call-container';

export type PhoneCallParameters = {
    entityId: string,
    activityId?: string,
    phoneNumber: {
        E164: string,
        numberFormatted: string,
    },
    shouldRecord?: boolean,
    shouldTranscribe?: boolean,
};

export function call(parameters: PhoneCallParameters) {
    const overlay = document.getElementById('phone-call-overlay');

    // There is already an active overlay lets just bail
    if (!overlay || overlay.childElementCount) {
        logError('Attempted to place call while overlay is open');

        return;
    }

    const phoneCallContainer = (
        <Provider store={store}>
            <ApolloProvider client={nutApolloClient}>
                <ReactHooksApolloProvider client={nutApolloClient}>
                    <PhoneCallContainer
                        {...parameters}
                        onClose={() => {
                            ReactDOM.unmountComponentAtNode(overlay);
                        }}
                    />
                </ReactHooksApolloProvider>
            </ApolloProvider>
        </Provider>
    );
    ReactDOM.render(phoneCallContainer, overlay);
}

function logError(errorMessage) {
    if (typeof window.trackJs !== 'undefined') {
        window.trackJs.track(errorMessage);
    }
}
