
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UserPermissionsFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UserPermissionBooleans"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"canAccessSetup"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"canAccessMarketing"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"canBulkEdit"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"canExport"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"canViewSharedEmails"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"canImport"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"canMergeEntities"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"canDeleteEntities"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"canAssignEntities"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"canAccessBilling"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"canViewAllMeetings"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"canManageEmailTemplates"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"canAccessCrm"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"canAccessFullCampaigns"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"canUseInbox"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"canUseSms"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":384}};
    doc.loc.source = {"body":"fragment UserPermissionsFragment on UserPermissionBooleans {\n    canAccessSetup\n    canAccessMarketing\n    canBulkEdit\n    canExport\n    canViewSharedEmails\n    canImport\n    canMergeEntities\n    canDeleteEntities\n    canAssignEntities\n    canAccessBilling\n    canViewAllMeetings\n    canManageEmailTemplates\n    canAccessCrm\n    canAccessFullCampaigns\n    canUseInbox\n    canUseSms\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
