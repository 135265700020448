/* @flow */

import * as React from 'react';
import {connect} from 'react-redux';
import type {Dispatch} from 'redux';

import type {Task as TaskFragment} from 'nutshell-graphql-types';
import moment from 'moment';
import {Session} from 'nutshell-core/session';

import {handleFormSubmissionError} from '../handle-form-submission-error';
import * as dashboardActions from '../../master-dashboard/dashboard-actions';
import type {FlashMessage} from '../../creator/types';
import * as createNewActions from '../../creator/creator-actions';
import type {NutshellState} from '../../../store';

import {TaskFormConnected} from './task-form-connected';

import '../form-common.css';

function endOfBusiness() {
    const {format, end} = NutshellFrontendSettings.businessHours;

    return moment()
        .startOf('day')
        .add(Number(moment(end, format).format('HH')), 'hours')
        .toDate();
}

const mapStateToProps = function (state: NutshellState, ownProps: OwnProps): StateProps {
    // Tri-state here - either new task with default due time,
    // or existing task that may/may not have a dueTime
    let dueTime = moment(endOfBusiness()).add(1, 'days').toDate();
    if (ownProps.task) {
        dueTime = ownProps.task.dueTime ? moment.unix(ownProps.task.dueTime) : null;
    }

    return {
        initialValues: {
            title: ownProps.task ? ownProps.task.title : undefined,
            description: ownProps.task ? ownProps.task.description : undefined,
            dueTime: dueTime,
            links: {
                relatedEntity: ownProps.task ? ownProps.task.relatedEntity : undefined,
                assignee: ownProps.task
                    ? ownProps.task.assignee
                    : Session.getSessionStateUser(state),
            },
        },
        // The initial value for assignee is initially empty when the page first loads,
        // and is only fully populated after a rest request to the backend.
        // By enabling reinitialization, we can update the initial value for assingee
        // after that response comes back.
        enableReinitialize: true,
        // In case the user has made any changes to the form before the session
        // user response came back, this will keep their changes and only change
        // the assignee if it's pristine.
        keepDirtyOnReinitialize: true,
    };
};

function mapDispatchToProps(dispatch, ownProps: OwnProps): DispatchProps {
    return {
        onSubmit: (data) => {
            const getAssignee = () => {
                if (!data.links || !data.links.assignee) {
                    return undefined;
                } else if (typeof data.links.assignee === 'string') {
                    return data.links.assignee;
                } else {
                    return data.links.assignee.id;
                }
            };

            const task = {
                ...data,
                type: 'tasks',
                links: {
                    assignee: getAssignee(),
                    relatedEntity: data.links.relatedEntity
                        ? data.links.relatedEntity.id
                        : undefined,
                },
                dueTime: moment(data.dueTime).unix(),
            };

            return (
                $.ajax({
                    url: ownProps.task ? ownProps.task.href : '/rest/tasks',
                    type: ownProps.task ? 'PUT' : 'POST',
                    dataType: 'json',
                    contentType: 'application/json',
                    data: JSON.stringify({data: task}),
                })
                    // Add the jqXHR object and task data to the response, so that it can
                    // be used in the onSubmitSuccess handler
                    .then((response, status, jqXHR) => {
                        return {
                            response,
                            jqXHR,
                            requestData: ownProps.task
                                ? {id: ownProps.task.id, type: ownProps.task.type, ...task}
                                : task,
                        };
                    })
                    .fail(() => {
                        // TODO: Figure out how we want to display errors to the user. This
                        // will almost certainly involve looking at that actual jqXHR object,
                        // but we need to work with that object here and return out something
                        // that redux form is okay with. Because, the jqXHR object causes a
                        // 'Maximum call stack size exceeded error'
                        // $FlowFixMe upgrading Flow to v0.92.1 on web
                        return 'Error saving';
                    })
            );
        },
        onSubmitSuccess: ({response, jqXHR, requestData}: Object) => {
            // If we have a response back, lets use it
            const task =
                jqXHR.status !== 204
                    ? // Pretty big assumption here that the response will have the newly
                      // created task at index 0
                      response.tasks[0]
                    : requestData;

            // Save callback
            ownProps.onSaveCallback(task, false);
            ownProps.onUpdateTask();

            if (location.pathname.startsWith('/dashboard')) {
                if (task.id) {
                    dispatch(dashboardActions.refetchTodo(task.id));
                }
            }
        },
        onSubmitFail: (errors, dispatchFunction, jqXHRError: any) => {
            handleFormSubmissionError(dispatch, jqXHRError);
        },
        pushFlashMessage: (flashMessage: FlashMessage) => {
            dispatch(createNewActions.pushFlashMessage(flashMessage));
        },
    };
}

type OwnProps = {|
    task: ?TaskFragment,
    isReadOnly: boolean,
    onCancelClick: () => any,
    onUpdateTask: () => any,
    onSaveCallback: (entity: Object, jumpToUrl: boolean) => any,

    // `form` is used by redux-form
    form: string,
|};

type StateProps = {|
    initialValues: Object,
    enableReinitialize: boolean,
    keepDirtyOnReinitialize: boolean,
|};

type DispatchProps = {|
    pushFlashMessage: (FlashMessage) => void,
    onSubmit: (Object) => any,
    onSubmitSuccess: (Object) => any,
    onSubmitFail: (errors: Object[], dispatchFunction: Dispatch<*>, jqXHRError: Object) => any,
|};

type Props = {...OwnProps, ...StateProps, ...DispatchProps};

export class TaskFormComponent extends React.PureComponent<Props> {
    render() {
        return (
            <TaskFormConnected
                form={this.props.form}
                task={this.props.task}
                isReadOnly={this.props.isReadOnly}
                onCancelClick={this.props.onCancelClick}
                onUpdateTask={this.props.onUpdateTask}
                onSaveCallback={this.props.onSaveCallback}
                enableReinitialize={this.props.enableReinitialize}
                keepDirtyOnReinitialize={this.props.keepDirtyOnReinitialize}
                pushFlashMessage={this.props.pushFlashMessage}
                onSubmit={this.props.onSubmit}
                onSubmitSuccess={this.props.onSubmitSuccess}
                onSubmitFail={this.props.onSubmitFail}
                initialValues={this.props.initialValues}
            />
        );
    }
}

const connector = connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps);
export const TaskForm = connector(TaskFormComponent);
