/* @flow */
import * as React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import {SaveButton, Button} from 'shells/button';
import {Stack} from 'shells/layout';
import {AlertBox} from 'shells/alert-box';
import {EmptyStateSmall} from 'shells/empty-states';
import {Body} from 'shells/typography';

import {
    ArrowDownIcon,
    ActivityMinimizeIcon,
    CtcKeypadIcon,
    LoadingIcon,
    MicrophoneSlashIcon,
} from 'shells/icon';
import getClasses from '../mixins/get-classes';
import {ComponentOverlayMask} from '../base/component-overlay-mask';
import FetchedBackboneModel from '../core/fetched-backbone-model';
import {KeypadPopover} from './keypad-popover';
import {DialerTranscriptionSection} from './dialer-transcription-section';

type Props = {
    activityTypes: {id: string, name: string}[],
    entityId: string,
    phoneCall: {
        error?: string,
        requiresCustomerVerification?: boolean,
        activityTypeId: string,
        note?: string,
    },
    phoneNumber: string,
    status: string,
    shouldTranscribe: boolean,

    isActivityFormMinimized: boolean,
    isCallConnected: boolean,
    isOpening: boolean,
    isClosing: boolean,
    isKeypadActive: boolean,
    isMuted: boolean,
    isSaving: boolean,

    onActivityTypeSelected: Function,
    onDoNotLog: Function,
    onHangup: Function,
    onNotesChanged: Function,
    onSave: Function,
    onSendDigit: Function,
    onToggleActivityForm: Function,
    onToggleKeypad: Function,
    onToggleMute: Function,
    phonecallId: ?string,
    activityId: ?string,
    shouldIncludeSummary: boolean,
    setShouldIncludeSummary: (boolean) => void,
};

/**
 * Stateless UI component for in app phone calls.
 */
const NOTES_PLACEHOLDER =
    'Log what happened in your activity… @Mention other users to grab their attention, or reference other companies, people, or leads.';

export class PhoneCallView extends React.Component<Props> {
    note: ?HTMLElement;
    keypadAnchor: ?HTMLElement;

    getClasses = getClasses;

    componentDidMount() {
        ReactTooltip.rebuild();
        if (this.note) {
            window.Mentions.MentionsInput($(this.note)); // eslint-disable-line babel/new-cap
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const {isActivityFormMinimized, isCallConnected} = this.props;

        // Focus the note input when call is initially connected and also when
        // the activity form is un-minimized.
        const focusNote =
            (!isCallConnected && nextProps.isCallConnected) ||
            (isActivityFormMinimized && !nextProps.isActivityFormMinimized);

        if (focusNote && this.note) {
            this.note.focus();
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (!prevProps.isActivityFormMinimized && this.props.isActivityFormMinimized) {
            ReactTooltip.rebuild();
        }
        if (prevProps.isActivityFormMinimized && !this.props.isActivityFormMinimized) {
            ReactTooltip.hide();
        }
    }

    render() {
        const {isCallConnected, isOpening, isClosing} = this.props;
        const callHasEnded = Boolean(this.props.phoneCall.error || this.props.status === 'closed');

        const classes = this.getClasses('ui-phone-call', {
            'ui-phone-call--on-open-animate-in': isOpening,
            'ui-phone-call--on-close-animate-out': isClosing,
            'ui-phone-call--activity-form-is-minimized': this.props.isActivityFormMinimized,
        });

        const headerBarClasses = classnames({
            'header-bar': true,
            'header-bar--call-is-connecting': !isCallConnected,
            'header-bar--call-is-muted': this.props.isMuted,
            'header-bar--call-ended': callHasEnded,
            'header-bar--keypad-is-active': this.props.isKeypadActive,
        });

        const componentOverlayMasks = ['#sidebar'].map((selector) => {
            const node = document.querySelector(selector);
            if (node) {
                return <ComponentOverlayMask isClosing={isClosing} key={selector} node={node} />;
            }

            return undefined;
        });

        return (
            <div className={classes}>
                {componentOverlayMasks}

                <div className={headerBarClasses}>
                    {callHasEnded ? (
                        this.renderCallCompleted()
                    ) : (
                        <div>
                            <div className='column column--left'>
                                <div className='phone-number'>{this.props.phoneNumber}</div>
                            </div>

                            <button
                                className='hang-up-button'
                                disabled={!isCallConnected}
                                onClick={this.props.onHangup}
                            >
                                {isCallConnected ? (
                                    <span>END</span>
                                ) : (
                                    <LoadingIcon className='hang-up-button__loading-svg' />
                                )}
                            </button>

                            <div className='column column--right'>
                                {isCallConnected ? (
                                    <CallTime status={this.props.status} />
                                ) : (
                                    <span>Connecting…</span>
                                )}
                            </div>

                            {isCallConnected ? this.renderActionButtons() : undefined}

                            {isCallConnected ? (
                                <div className='doppler-effect-animation' />
                            ) : undefined}
                        </div>
                    )}
                </div>

                {this.props.phoneCall.requiresCustomerVerification
                    ? this.renderCustomerVerificationCTA()
                    : this.renderCallLoggingInterface(callHasEnded, isCallConnected)}
            </div>
        );
    }

    renderCallCompleted() {
        if (this.props.phoneCall.error) {
            return <div className='call-error-message'>{this.props.phoneCall.error}</div>;
        } else {
            return <div className='call-completed-message'>Call completed</div>;
        }
    }

    renderCustomerVerificationCTA() {
        return (
            <AlertBox onClose={() => {}}>
                <div style={{padding: 24}}>
                    <EmptyStateSmall
                        icon={
                            <img alt='' src='/include/images/email-drip/email-scheduled_2x.png' />
                        }
                        title='We need to verify your account'
                        subtitle='To continue using click-to-call, we need to verify your account. Please continue below and we’ll get you back on the phones!'
                        cta={
                            <Button
                                onClick={this.props.onDoNotLog}
                                size='big'
                                variant='text-secondary'
                            >
                                Not right now
                            </Button>
                        }
                    />
                </div>
            </AlertBox>
        );
    }

    renderActionButtons() {
        return (
            <div className='action-buttons'>
                {this.props.isActivityFormMinimized ? (
                    <div className='call-header-button' onClick={this.props.onToggleActivityForm}>
                        <span
                            data-tip='Show call notes'
                            className='call-header-button__icon call-header-button__icon--activity'
                        >
                            <ArrowDownIcon size={22} />
                        </span>
                    </div>
                ) : undefined}

                <button
                    className='call-header-button call-header-button--keypad'
                    onClick={this.props.onToggleKeypad}
                    type='button'
                >
                    <span className='call-header-button__icon call-header-button__icon--keypad'>
                        <CtcKeypadIcon size={21} />
                    </span>
                    <span
                        className='call-header-button__label'
                        ref={(c) => {
                            this.keypadAnchor = c;
                        }}
                    >
                        KEYPAD
                    </span>
                </button>
                {this.props.isKeypadActive ? (
                    <KeypadPopover
                        anchor={this.keypadAnchor}
                        onDigitPress={this.props.onSendDigit}
                    />
                ) : undefined}

                <button
                    className='call-header-button call-header-button--mute'
                    onClick={this.props.onToggleMute}
                    type='button'
                >
                    <span className='call-header-button__icon call-header-button__icon--mute'>
                        <MicrophoneSlashIcon size={24} />
                    </span>
                    <span className='call-header-button__label call-header-button__label--mute'>
                        {this.props.isMuted ? 'MUTED' : 'MUTE'}
                    </span>
                </button>
            </div>
        );
    }

    renderCallLoggingInterface(callHasEnded: boolean, isCallConnected: boolean) {
        const activtiyTypeSelectOptions = this.props.activityTypes.map(({id, name}) => (
            <option key={id} value={id}>
                {name}
            </option>
        ));

        return (
            <form className='activity-form' onSubmit={this.handleSubmit}>
                <div className='section-top'>
                    <div className='flex-column-left'>
                        <span>Phone call with</span>{' '}
                        <FetchedBackboneModel id={this.props.entityId} />
                    </div>
                    <div className='flex-column-right'>
                        <select
                            value={this.props.phoneCall.activityTypeId}
                            onChange={this.handleActivityTypeChange}
                        >
                            <option value='automatic'>Automatic</option>
                            {activtiyTypeSelectOptions}
                        </select>
                        <button
                            className='minimize-activity-form-button'
                            disabled={callHasEnded || !isCallConnected}
                            data-tip='Minimize'
                            onClick={this.props.onToggleActivityForm}
                        >
                            <ActivityMinimizeIcon size={22} />
                        </button>
                    </div>
                </div>
                <Stack spacing={4}>
                    <Body size='small' isBold={true}>
                        Log a note
                    </Body>
                    <textarea
                        ref={(c) => {
                            this.note = c;
                        }}
                        className='orange-outline'
                        data-collections='accounts,contacts,Leads,Users,Teams'
                        name='note'
                        placeholder={NOTES_PLACEHOLDER}
                        value={this.props.phoneCall.note || ''} // Controlled text areas need to always have a value
                        onChange={this.handleNotesChange}
                    />
                </Stack>
                {NutClientConfig.hasClickToCallAi && (
                    <DialerTranscriptionSection
                        shouldTranscribe={this.props.shouldTranscribe}
                        callHasEnded={callHasEnded}
                        phonecallId={this.props.phonecallId}
                        activityId={this.props.activityId}
                        shouldIncludeSummary={this.props.shouldIncludeSummary}
                        setShouldIncludeSummary={this.props.setShouldIncludeSummary}
                    />
                )}
                <div className='section-bottom'>
                    <Button
                        variant='text-secondary'
                        noPadding={true}
                        disabled={!callHasEnded}
                        onClick={this.handleDoNotLog}
                    >
                        Don’t save this call
                    </Button>
                    <SaveButton disabled={!callHasEnded} isSaving={this.props.isSaving} />
                </div>
            </form>
        );
    }

    handleActivityTypeChange = (e: SyntheticInputEvent<*>) => {
        this.props.onActivityTypeSelected(e.target.value);
    };

    handleNotesChange = (e: SyntheticInputEvent<*>) => {
        const serializedNote = $(e.target).data('messageText');
        this.props.onNotesChanged(e.target.value, serializedNote);
    };

    handleDoNotLog = (e: SyntheticEvent<*>) => {
        e.preventDefault();
        if (this.props.isCallConnected) return;

        this.props.onDoNotLog();
    };

    handleSubmit = (e: SyntheticEvent<*>) => {
        e.preventDefault();
        if (this.props.isCallConnected) return;

        this.props.onSave();
    };
}

type CallTimeProps = {
    status?: string,
};
type CallTimeState = {
    startTime?: Date,
    endTime?: Date,
};
class CallTime extends React.Component<CallTimeProps, CallTimeState> {
    interval: ?number;

    constructor(props) {
        super(props);
        this.state = {};
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (this.props.status !== 'open' && newProps.status === 'open') {
            this.setState({startTime: new Date()});
            // Guard against creating a memory leak.
            // $FlowFixMe upgrading Flow to v0.92.1 on web
            if (this.interval) clearInterval(this.interval);
            // $FlowFixMe upgrading Flow to v0.92.1 on web
            this.interval = setInterval(() => this.forceUpdate(), 1000);
        }

        if (this.interval && this.props.status === 'open' && newProps.status !== 'open') {
            this.setState({endTime: new Date()});
            // $FlowFixMe upgrading Flow to v0.92.1 on web
            if (this.interval) clearInterval(this.interval);
        }
    }

    UNSAFE_componentWillMount() {
        if (this.props.status === 'open') {
            this.setState({startTime: new Date()});
            // $FlowFixMe upgrading Flow to v0.92.1 on web
            this.interval = setInterval(() => this.forceUpdate(), 1000);
        }
    }

    componentWillUnmount() {
        // Make sure the interval has been cleared on unmount, just in case it unmounts without a hangup
        if (this.interval) {
            // $FlowFixMe upgrading Flow to v0.92.1 on web
            clearInterval(this.interval);
        }
    }

    render() {
        const {startTime, endTime} = this.state;

        let callTime = '00:00:00';

        if (startTime) {
            const diff = moment(endTime || new Date()).diff(startTime);
            callTime = moment.utc(moment.duration(diff).asMilliseconds()).format('HH:mm:ss');
        }

        return <div className='call-timer'>{callTime}</div>;
    }
}
