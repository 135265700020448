/* @flow */

import * as React from 'react';
import ReactTooltip from 'react-tooltip';

import {DynamicButtonContent} from 'shells/dynamic-button-content';
import {MicrophoneIcon, SparkleIcon, StopIcon, LoadingIcon, SparkleOpenIcon} from '../icon';
import {colors} from '../colors';
import {AnimatingBars} from './animating-bars';

import './transcribe-button.css';

type Props = {
    getRef: (ref: ?HTMLButtonElement) => void,
    isTranscribing: boolean,
    isLoading: boolean,
    onClick: (e: SyntheticEvent<*>) => void,
};

export function TranscribeButton(props: Props) {
    React.useEffect(() => {
        ReactTooltip.rebuild();

        const handleScroll = () => ReactTooltip.hide();
        window.addEventListener('scroll', handleScroll, true);

        return () => {
            ReactTooltip.hide();
            window.removeEventListener('scroll', handleScroll, true);
        };
    }, [props.isTranscribing, props.isLoading]);

    const buttonRef = React.useRef(null);

    const StartButtonContent = (contentProps: {icon: React.Node}) => (
        <>
            <div>{contentProps.icon}</div>
            <MicrophoneIcon
                size={17}
                onClick={(e) => {
                    e.preventDefault();
                    props.onClick(e);
                }}
                onMouseDown={(e) => {
                    e.preventDefault();
                }}
                fill={colors.white}
                color={colors.white}
                wrapWithDiv={false}
            />
        </>
    );

    const StopButtonContent = (contentProps: {icon: React.Node}) => (
        <>
            <div>{contentProps.icon}</div>
            <AnimatingBars />
            <StopIcon size={17} wrapWithDiv={false} fill={colors.rose} color={colors.rose} />
        </>
    );

    return props.isTranscribing ? (
        <div styleName='button-container'>
            <button
                styleName='stop-button'
                ref={(ref) => {
                    buttonRef.current = ref;
                    props.getRef(ref);
                }}
                data-tip='Stop taking notes'
                onClick={(e) => {
                    e.preventDefault();
                    props.onClick(e);
                }}
                onMouseDown={(e) => {
                    e.preventDefault();
                }}
            >
                <DynamicButtonContent
                    buttonRef={buttonRef}
                    defaultContent={
                        <StopButtonContent
                            icon={
                                <SparkleOpenIcon
                                    size={17}
                                    wrapWithDiv={false}
                                    fill={colors.white}
                                />
                            }
                        />
                    }
                    hoverContent={
                        <StopButtonContent
                            icon={
                                <SparkleIcon size={17} wrapWithDiv={false} fill={colors.roseDk} />
                            }
                        />
                    }
                />
            </button>
        </div>
    ) : (
        <div styleName='button-container'>
            {props.isLoading ? (
                <LoadingIcon
                    size={17}
                    fill={colors.offWhite}
                    color={colors.offWhite}
                    wrapWithDiv={false}
                />
            ) : (
                <button
                    styleName='start-button'
                    ref={(ref) => {
                        buttonRef.current = ref;
                        props.getRef(ref);
                    }}
                    data-tip='Start taking notes'
                    onClick={(e) => {
                        e.preventDefault();
                        props.onClick(e);
                    }}
                    onMouseDown={(e) => {
                        e.preventDefault();
                    }}
                >
                    <DynamicButtonContent
                        buttonRef={buttonRef}
                        defaultContent={
                            <StartButtonContent
                                icon={
                                    <SparkleOpenIcon
                                        size={17}
                                        wrapWithDiv={false}
                                        fill={colors.white}
                                    />
                                }
                            />
                        }
                        hoverContent={
                            <StartButtonContent
                                icon={
                                    <SparkleIcon
                                        size={17}
                                        wrapWithDiv={false}
                                        fill={colors.white}
                                    />
                                }
                            />
                        }
                    />
                </button>
            )}
        </div>
    );
}
