export const NUTSHELL_PRO_MONTHLY = 'ns-uub-m';
export const NUTSHELL_PRO_ANNUAL = 'ns-uub-a';

export const NUTSHELL_BUSINESS_MONTHLY = 'ns-uuf-m';
export const NUTSHELL_BUSINESS_ANNUAL = 'ns-uuf-a';

export const ENGAGEMENT_FOUNDATION_MONTHLY = 'fake'; // not a real SKU for foundation
export const ENGAGEMENT_PRO_MONTHLY = 'ne-uub-m';

export const ADD_ONS_HASH = '#add-ons';
export const MARKETING_HASH = '#campaigns';
export const REVENUE_BOOSTER_HASH = '#revenue-booster';
export const NUTSHELL_IQ_HASH = '#nutshell-iq';
export const LANDING_PAGES_HASH = '#landing-pages';
export const INBOX_HASH = '#sms';
export const ADD_ONS_HASHES = [
    ADD_ONS_HASH,
    MARKETING_HASH,
    REVENUE_BOOSTER_HASH,
    NUTSHELL_IQ_HASH,
    LANDING_PAGES_HASH,
    INBOX_HASH,
];
