/* @flow */

import * as React from 'react';
import {components, type MenuListComponentProps} from 'react-select';

import {Stack} from 'shells/layout';
import {CogIcon} from 'shells/icon';

import {type FooterButtonProps} from './select-picker-generic';

import './custom-menu-list.css';

// We need to set a min-height on the menu list to prevent it from collapsing if there are only a few options, so that the footer button stays at the bottom
const MENU_LIST_STYLES = {
    overflowY: 'auto',
    minHeight: 251,
};

export const getCustomMenuList =
    (footerButton?: FooterButtonProps) =>
    ({children, ...props}: {...MenuListComponentProps}) => {
        const Icon = (footerButton && footerButton.icon) || CogIcon;

        return (
            <>
                <components.MenuList {...props}>
                    {footerButton ? <div style={MENU_LIST_STYLES}>{children}</div> : children}
                </components.MenuList>
                {footerButton && (
                    <div
                        className='flex align-center border-top pos-sticky'
                        styleName='fixed-footer'
                        onClick={footerButton.onClick}
                    >
                        <Stack spacing={8} isHorizontal={true} alignCenter={true}>
                            <Icon size={13} alignCenter={true} />
                            <div>{footerButton.label}</div>
                        </Stack>
                    </div>
                )}
            </>
        );
    };
