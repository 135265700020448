/* @flow */

import * as React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import {notificationStream} from '../../notifications/index';
import {Notification, type NotificationObj} from './notification';

export function initNotifications() {
    document.addEventListener('DOMContentLoaded', function () {
        const container = document.createElement('div');
        document.getElementsByTagName('body')[0].appendChild(container);
        ReactDOM.render(<NotificationsContainer />, container);
    });
}

type State = {
    notifications: NotificationObj[],
};

class NotificationsContainer extends React.Component<{}, State> {
    constructor() {
        super();
        this.state = {
            notifications: [],
        };
    }

    componentDidMount() {
        notificationStream.subscribe((notification) =>
            this.setState((prevState) => ({
                notifications: prevState.notifications.concat(notification),
            }))
        );
    }

    render() {
        return (
            <Notifications
                notifications={this.state.notifications}
                onNotificationClose={this.handleNotificationClose}
            />
        );
    }

    handleNotificationClose = (notification) => {
        this.setState((prevState) => ({
            notifications: _.without(prevState.notifications, notification),
        }));
    };
}

type Props = {
    notifications: NotificationObj[],
    onNotificationClose: (NotificationObj) => void,
};

function Notifications(props: Props) {
    return (
        <ul className='ui-notifications__list'>
            {props.notifications.map((notification) => (
                <Notification
                    key={notification.id}
                    notification={notification}
                    onClose={props.onNotificationClose}
                />
            ))}
        </ul>
    );
}
