/* @flow */

import * as React from 'react';

import {FeatureGateModal} from 'shells/billing';
import {type FeatureName} from 'shells/billing/feature-gate/features-list';

import {analyticsClient} from '../../../analytics-client';
import {useGetSessionUser} from '../../core/use-get-session-user';

type Props = {
    title: string,
    description: string,
    bullets: string[],
    feature: FeatureName,
    icon?: any,
    onClose: () => void,
    isOpen: boolean,
};

export function FeatureGateModalWrapper(props: Props) {
    const {user} = useGetSessionUser();
    const userCanAccessBilling = Boolean(user && user.permissions.canAccessBilling);

    if (!user) {
        return null;
    }

    return (
        <FeatureGateModal
            analyticsClient={analyticsClient}
            isAdmin={userCanAccessBilling}
            {...props}
        />
    );
}
