/* @flow */

import {useMutation} from '@apollo/react-hooks';

import type {
    SelectCreateAccount as SelectCreateAccountMutation,
    SelectCreateAccountVariables as SelectCreateAccountMutationVariables,
    AccountCreateInput,
} from 'nutshell-graphql-types';
import {safelyGetGraphQLErrorMessage} from 'nutshell-core/utils/graphql-errors';

import CREATE_ACCOUNT from '../mutations/create-account.graphql';

export const useCreateAccount = (): ({
    createAccount: (input: AccountCreateInput) => Promise<*>,
    isLoading: boolean,
    error: ?string,
}) => {
    const [createAccount, {loading, error}] = useMutation<
        SelectCreateAccountMutation,
        SelectCreateAccountMutationVariables,
    >(CREATE_ACCOUNT);

    return {
        createAccount: (input: AccountCreateInput) => {
            return createAccount({
                variables: {account: input},
            });
        },
        isLoading: loading,
        error: error && safelyGetGraphQLErrorMessage(error),
    };
};
