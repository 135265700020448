/* @flow */

import * as React from 'react';
import {Header, Subheader} from '../typography';
import {Button} from '../button';
import {BulletedList} from '../bulleted-list/bulleted-list';

import './empty-state-large.css';

type DetailTextBullet = React.Node | string;

type Props = {
    icon: any,
    iconSide: 'left' | 'right',
    title: React.Node | string,
    subtitle?: React.Node | string,
    detailTextBullets?: DetailTextBullet[],
    cta?: React.Node,
    secondaryCta?: React.Node,
    dismissal?: {
        title: string,
        onClick: () => void,
    },
};

EmptyStateLarge.defaultProps = {
    iconSide: 'left',
};

export function EmptyStateLarge(props: Props) {
    return (
        <div className='flex flex-dir-col align-center' styleName='container'>
            <div className='flex flex-dir-col align-center justify-center' styleName='empty-text'>
                <Header>{props.title}</Header>
                <Subheader>{props.subtitle}</Subheader>
            </div>
            <div
                className='flex align-center justify-center'
                styleName={
                    props.detailTextBullets ? 'details-container--with-text' : 'details-container'
                }
            >
                {props.iconSide === 'left' ? <div>{props.icon}</div> : undefined}
                {props.detailTextBullets ? (
                    <div>
                        <BulletedList bullets={props.detailTextBullets} size='big' />
                    </div>
                ) : undefined}
                {props.iconSide === 'right' ? <div>{props.icon}</div> : undefined}
            </div>
            <div styleName='action-container'>
                <div
                    className='flex justify-center'
                    styleName={props.secondaryCta ? 'cta-container--two-column' : 'cta-container'}
                >
                    <div>{props.cta}</div>
                    {props.secondaryCta ? <div>{props.secondaryCta}</div> : undefined}
                </div>
                {props.dismissal ? (
                    <Button variant='text-secondary' onClick={props.dismissal.onClick}>
                        {props.dismissal.title}
                    </Button>
                ) : undefined}
            </div>
        </div>
    );
}
