/* @flow */

import * as React from 'react';
import * as ramda from 'ramda';
import debounce from 'lodash/debounce';

import {RelativeOverlay} from '../relative-overlay';
import {ListViewInfiniteLoading} from './list-view-infinite-loading';
import {type SpecialState} from './list-view';
import {renderSpecialState} from './helpers';

import './list-view-paginated.css';

type Props = {|
    collection: Object[],
    specialStates: SpecialState[],
    isLoading?: boolean,
    isUpdating?: boolean,
    renderRow: (item: Object, index: number) => React.Node,
    getListViewRef?: (?HTMLElement) => void,
    hasNextPage: boolean,
    onFetchMoreRows: () => any,
    itemSpacing?: 8 | 16,
    listPadding?: 8 | 16,
    invertScrolling?: boolean,
|};

const findSpecialState = ramda.find((state: SpecialState) => {
    return state.shouldRender();
});

const LOAD_MORE_PIXEL_THRESHOLD = 250;

export function ListViewPaginated(props: Props) {
    const {collection = [], specialStates = []} = props;
    const [scrollHeight, setScrollHeight] = React.useState(undefined);
    const [scrollTop, setScrollTop] = React.useState(undefined);
    const [collectionCount, setCollectionCount] = React.useState(0);
    const listRef = React.useRef();

    React.useEffect(() => {
        setCollectionCount(collection.length);
    }, [collection, listRef]);

    const handleScroll = debounce((e: Event) => {
        const {
            // $FlowIgnore these _do_ exist
            scrollHeight: targetScrollHeight,
            // $FlowIgnore these _do_ exist
            offsetHeight,
            // $FlowIgnore these _do_ exist
            scrollTop: targetScrollTop,
        } = e.target;

        setScrollHeight(targetScrollHeight);
        setScrollTop(targetScrollTop);

        if (
            props.invertScrolling &&
            targetScrollTop < LOAD_MORE_PIXEL_THRESHOLD &&
            props.hasNextPage &&
            !props.isLoading
        ) {
            props.onFetchMoreRows();
        }

        if (
            !props.invertScrolling &&
            targetScrollHeight - offsetHeight < scrollTop + LOAD_MORE_PIXEL_THRESHOLD &&
            props.hasNextPage &&
            !props.isLoading
        ) {
            props.onFetchMoreRows();
        }
    }, 100);

    React.useEffect(() => {
        if (listRef.current) {
            const currentRef = listRef.current;
            currentRef.addEventListener('scroll', handleScroll);

            return () => {
                currentRef.removeEventListener('scroll', handleScroll);
            };
        }
    }, [listRef, handleScroll]);

    React.useLayoutEffect(() => {
        if (
            props.invertScrolling &&
            listRef.current &&
            collectionCount === 0 &&
            collection.length > 0
        ) {
            listRef.current.scrollTo(0, 10000);
        }
    }, [collectionCount, collection, props.invertScrolling, listRef]);

    React.useEffect(() => {
        if (
            props.invertScrolling &&
            collectionCount !== collection.length &&
            listRef.current &&
            scrollTop !== undefined &&
            scrollHeight !== undefined &&
            scrollHeight !== listRef.current.scrollHeight
        ) {
            listRef.current.scrollTo(0, scrollTop + listRef.current.scrollHeight - scrollHeight);
        }
    }, [
        collectionCount,
        collection.length,
        props.invertScrolling,
        scrollTop,
        scrollHeight,
        listRef,
    ]);

    const setRefCallback = (ref: ?HTMLElement) => {
        listRef.current = ref;

        if (props.getListViewRef) {
            props.getListViewRef(ref);
        }
    };

    const renderBody = () => {
        const list = (
            <ul styleName={props.listPadding ? `pad-${props.listPadding}` : undefined}>
                {props.collection.map((item, index) => {
                    return (
                        <li
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            styleName={
                                props.itemSpacing && index !== props.collection.length - 1
                                    ? `item-spacing-${props.itemSpacing}`
                                    : undefined
                            }
                        >
                            {props.renderRow(item, index)}
                        </li>
                    );
                })}
                {!props.invertScrolling && (props.isLoading || props.hasNextPage) ? (
                    <ListViewInfiniteLoading />
                ) : undefined}
            </ul>
        );

        const specialState = findSpecialState(specialStates);

        return specialState ? renderSpecialState(specialState) : list;
    };

    return (
        <>
            <div ref={setRefCallback} styleName='list-view-paginated'>
                {renderBody()}
            </div>
            {props.isUpdating ? (
                <RelativeOverlay>
                    <ListViewInfiniteLoading />
                </RelativeOverlay>
            ) : undefined}
        </>
    );
}
