/* @flow */

import * as React from 'react';
import classNames from 'classnames';
import {colors} from '../colors';
import {LoadingText, LoadingCircle} from '../loading-placeholder';

import './two-line.css';

type Props = {
    /* eslint-disable react/no-unused-prop-types */
    icon?: React.ComponentType<*>,
    iconSize: number,
    iconColor: string,
    avatar?: React.Node,
    topRow: string | React.Node,
    bottomRow?: string | React.Node,
    isLoading?: boolean,
    isLarge?: boolean,
    isLargeWithSmallBottomRow?: boolean,

    // If no second row, should the first row be centered vertically?
    shouldCenterSingleLine?: boolean,
    isDisabled?: boolean,
};

TwoLine.defaultProps = {
    iconSize: 32,
    iconColor: colors.greyLt,
};

export function TwoLine(props: Props) {
    return props.isLoading ? renderLoadingPlaceholder(props) : renderLoadedContent(props);
}

function renderLoadingPlaceholder(props: Props) {
    return (
        <div styleName={props.isLarge ? 'two-line--large' : 'two-line'}>
            <div styleName='icon'>
                <LoadingCircle size={32} color={colors.offWhiteDk} />
            </div>
            <div styleName={props.isLarge ? 'two-line-info--large' : 'two-line-info'}>
                <div styleName='top-row'>
                    <LoadingText height={13} color={colors.offWhiteDk} />
                </div>
                <div styleName='bottom-row'>
                    <LoadingText height={13} color={colors.offWhiteMd} />
                </div>
            </div>
        </div>
    );
}

function renderLoadedContent(props: Props) {
    const twoLineStyleNames = classNames({
        'two-line': !(props.isLarge || props.isLargeWithSmallBottomRow),
        'two-line--large': props.isLarge || props.isLargeWithSmallBottomRow,
        'two-line--disabled': props.isDisabled,
    });

    const topRowStyle = classNames({
        'top-row': !props.isLargeWithSmallBottomRow,
        'top-row--light': props.isLargeWithSmallBottomRow,
        'top-row--align-center': props.shouldCenterSingleLine && !props.bottomRow,
    });

    return (
        <div styleName={twoLineStyleNames}>
            {/* We shouldn't ever render both an icon and an avatar */}
            {props.icon ? renderIcon(props.icon, props.iconSize, props.iconColor) : undefined}
            {props.avatar ? <div styleName='icon'>{props.avatar}</div> : undefined}
            <div
                styleName={
                    props.isLarge || props.isLargeWithSmallBottomRow
                        ? 'two-line-info--large'
                        : 'two-line-info'
                }
            >
                <div styleName={topRowStyle}>
                    <div
                        title={typeof props.topRow === 'string' ? props.topRow : undefined}
                        styleName='row-truncated'
                    >
                        {props.topRow}
                    </div>
                </div>
                {props.bottomRow ? (
                    <div
                        styleName={
                            props.isLargeWithSmallBottomRow ? 'bottom-row-small' : 'bottom-row'
                        }
                    >
                        <div styleName='row-truncated'>{props.bottomRow}</div>
                    </div>
                ) : undefined}
            </div>
        </div>
    );
}

function renderIcon(icon: React.ComponentType<*>, iconSize: number, iconColor: string) {
    const Icon = icon;

    return (
        <div styleName='icon'>
            <Icon size={iconSize} wrapWithDiv={false} fill={iconColor} color={iconColor} />
        </div>
    );
}
