/* @flow */

import * as React from 'react';
import {useDispatch} from 'react-redux';
import type {LimitedType} from 'nutshell-graphql-types';
import {StatusBadge} from 'shells/status-badge';
import {openModalForType} from './limits-actions';
import {useGetLimitsByType} from './use-get-limits-by-type';
import {LimitsWarning} from './limits-warning';

import './title-bar-limits.css';

type Props = {
    type: LimitedType,
};

export function TitleBarLimits(props: Props) {
    const dispatch = useDispatch();
    const {limits} = useGetLimitsByType(props.type);

    if (limits && limits.isEnforced) {
        const currentUsage = limits.currentUsage;
        const planLimit = limits.currentPlanLimit;
        const typeString = limits.humanReadableType;
        const tierPlanLimit = limits.tierPlanLimit;
        const tierPlanLimitValidated = typeof tierPlanLimit === 'number' ? tierPlanLimit : '∞';

        return (
            <>
                {NutClientConfig.shouldShowTrialPlanLimits && limits.shouldShowWarningInTrial ? (
                    <LimitsWarning />
                ) : (
                    <div
                        styleName='title-bar-limits'
                        onClick={() => {
                            dispatch(openModalForType(props.type));
                        }}
                    >
                        <StatusBadge
                            level={planLimit ? getLevel(currentUsage, planLimit) : 'info--grey'}
                            text={`${currentUsage} / ${tierPlanLimitValidated}`}
                            detail={`You’re using ${currentUsage} of your ${
                                tierPlanLimit || 'unlimited'
                            } ${typeString}`}
                        />
                    </div>
                )}
            </>
        );
    }

    return null;
}

function getLevel(currentUsage: number, planLimit: number) {
    if (currentUsage / planLimit >= 1) {
        return 'alert';
    } else if (currentUsage / planLimit > 0.9) {
        return 'warning';
    } else {
        return 'info--grey';
    }
}
