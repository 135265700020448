/* @flow */
import * as React from 'react';

import {Stack} from 'shells/layout';

import {usePoll} from '../../util/hooks';

import {analyticsClient} from '../../analytics-client';

import {SummarizeSection} from './summarize-section';
import {SummarizeCheckbox} from './summarize-checkbox';
import {TranscriptionSection} from './transcription-section';
import {IncludeSummaryCheckbox} from './include-summary-checkbox';

import {useGetCallActivity, useUpdatePhonecallShouldSummarize} from './graphql/hooks';

type Props = {
    callHasEnded: boolean,
    shouldTranscribe: boolean,
    phonecallId: ?string,
    activityId: ?string,
    shouldIncludeSummary: boolean,
    setShouldIncludeSummary: (boolean) => void,
};

export function DialerTranscriptionSection(props: Props) {
    const {
        callHasEnded,
        shouldTranscribe,
        phonecallId,
        activityId,
        shouldIncludeSummary,
        setShouldIncludeSummary,
    } = props;

    // If the user does not have an AI token, they should not attempt to
    // summarize the call.
    const [shouldSummarize, setShouldSummarize] = React.useState<boolean>(
        NutClientConfig.canUseAiToken && NutClientConfig.enableAi ? shouldTranscribe : false
    );

    const analyticsFired = React.useRef(false);

    const {activity, startPolling, stopPolling} = useGetCallActivity(activityId);
    const {updatePhonecallShouldSummarize} = useUpdatePhonecallShouldSummarize();

    const shouldPollTranscription = Boolean(
        shouldTranscribe && activity && !activity.transcription
    );
    const shouldPollSummary = Boolean(
        shouldSummarize && activity && (!activity.summary || !activity.summary.summary)
    );
    const shouldPoll = shouldPollTranscription || shouldPollSummary;

    // Handles initiation and cleanup of polling with using the shouldPoll
    // state. Will stop polling on unmount.
    usePoll({
        shouldPoll,
        startPolling,
        stopPolling,
    });

    const summary = activity && activity.summary && activity.summary.summary;
    const summaryStatus = activity && activity.summary && activity.summary.status;
    const transcription = activity && activity.transcription;

    React.useEffect(() => {
        if (phonecallId) {
            updatePhonecallShouldSummarize({
                phonecallId,
                shouldSummarize,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phonecallId, shouldSummarize]);
    const shouldShowIncludeSummary =
        summary &&
        callHasEnded &&
        shouldTranscribe &&
        NutClientConfig.canUseAiToken &&
        shouldSummarize;
    const summarizeCheckboxDisabled = callHasEnded || !shouldTranscribe;

    React.useEffect(() => {
        if (analyticsFired.current) return;
        if (!callHasEnded) return;
        analyticsFired.current = true;
        analyticsClient.track(analyticsClient.EVENTS.TRACK_C2C_CALL_COMPLETED, {
            shouldTranscribe: shouldTranscribe,
            shouldSummarize: shouldSummarize,
        });
    }, [shouldTranscribe, shouldSummarize, callHasEnded]);

    return (
        <Stack spacing={16}>
            <Stack spacing={8}>
                {NutClientConfig.enableAi &&
                    (shouldShowIncludeSummary ? (
                        <IncludeSummaryCheckbox
                            shouldIncludeSummary={shouldIncludeSummary}
                            setShouldIncludeSummary={setShouldIncludeSummary}
                        />
                    ) : (
                        <SummarizeCheckbox
                            shouldSummarize={shouldSummarize}
                            setShouldSummarize={setShouldSummarize}
                            callHasEnded={callHasEnded}
                            disabled={summarizeCheckboxDisabled}
                        />
                    ))}
                {shouldSummarize && NutClientConfig.canUseAiToken && (
                    <SummarizeSection
                        activityId={activityId}
                        summary={summary}
                        summaryStatus={summaryStatus}
                        callHasEnded={callHasEnded}
                    />
                )}
            </Stack>
            <TranscriptionSection
                callHasEnded={callHasEnded}
                shouldTranscribe={shouldTranscribe}
                transcription={transcription}
            />
        </Stack>
    );
}
