/* @flow */

import * as React from 'react';

import type {EventFragmentSparse, SessionUser} from 'nutshell-graphql-types';
import {getApiTypeFromGraphQLTypename} from 'nutshell-core/utils';

import type {TimelineEntryIconVariant} from 'shells/timeline/gutter/types';
import {TimelineEntryActionMenu} from 'shells/timeline/entry/toolbar/timeline-entry-action-menu';

import {useSaveTranscriptSummary} from '../../activity-ai/graphql/hooks/use-save-transcript-summary';
import {TranscriptSummary} from '../../activity-ai/transcript-summary';
import {getEntryTypeFromPayload} from '../helpers';
import {useCreateComment, useDeleteComment} from '../comments/timeline-comment-mutations';
import {useGetRelatedLeads} from '../graphql/hooks/use-get-related-leads';
import {TimelineEntry} from './timeline-entry';

type Props = {
    event: EventFragmentSparse,
    currentUser: SessionUser,
    onDeleteEvent?: (eventId: string) => Promise<*>,
    refetchEvents?: () => Promise<*>,
    hasGutterLine?: boolean,
};

export function TimelineEntryActivity(props: Props) {
    const [isShowingTranscriptSummary, setIsShowingTranscriptSummary] =
        React.useState<boolean>(false);
    const {id, changeType, changeTime, actor, payload, reactions, comments} = props.event;
    const createComment = useCreateComment();
    const deleteComment = useDeleteComment();
    const {saveTranscriptSummary} = useSaveTranscriptSummary();

    const closeSummary = () => setIsShowingTranscriptSummary(false);

    const {relatedLeads} = useGetRelatedLeads({changeLogId: id});

    let hasActivityTranscription = false;

    /**
     * We need to include this check, even though we are only passing an event
     * with a payload.__typename of 'Activity' because Apollo doesn't generate a specific
     * enough type when using a union type (like the TimelineEventPayload).
     * See this issue for more detail:
     * https://github.com/apollographql/apollo-tooling/issues/1223
     */
    if (payload.__typename === 'Activity') {
        let iconVariant: TimelineEntryIconVariant = 'custom-activity';
        let entryType = getEntryTypeFromPayload(payload);
        let content;
        let status;
        let zoomMeetingData;

        const transcription = payload.transcription;

        // For logged activities, we show the logNote body (which may be undefined)
        if (payload.logNote && payload.logNote.bodyHtml) {
            content = (
                <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: payload.logNote.bodyHtml,
                    }}
                />
            );
        } else if (NutClientConfig.hasClickToCallAi && transcription) {
            // Only show the transcription for an activity if there is no log
            // note
            content = transcription;
        }

        if (NutClientConfig.hasClickToCallAi && transcription) {
            hasActivityTranscription = true;
        }

        if (payload.activityType) {
            if (payload.isMediaLogged) {
                // If activity has logged media, we always want to show the recorded-activity icon
                iconVariant = 'recorded-activity';
            } else {
                switch (payload.activityType.name) {
                    case 'Phone Call':
                        iconVariant = 'phone-call';
                        break;
                    case 'Meeting':
                        iconVariant = 'meeting';
                        break;
                    case 'Virtual Meeting':
                        iconVariant = 'virtual-meeting';
                        break;
                    case 'Voicemail':
                        // The 'Voicemail' type is created by C2C - and if a customer just so happens
                        // to have a custom-activity type named 'Voicemail' it will get this icon as well
                        iconVariant = 'voicemail';
                        break;
                }
            }

            // Show a status badge with the activity type, unless the type and activity name are the same
            if (payload.activityType.name && payload.activityType.name !== payload.name) {
                status = {
                    primary: {
                        level: 'info--grey',
                        text: payload.activityType.name,
                    },
                };
            }
        }

        // Override entryType, icon, and status and set zoomMeetingData if this is a zoom meeting
        if (payload.zoomMeeting) {
            entryType = 'ZoomMeeting';
            iconVariant = 'zoom-meeting';
            status = {
                primary: {
                    level: 'info--grey',
                    text: 'Zoom meeting',
                },
            };
            zoomMeetingData = {
                zoomMeetingId: payload.zoomMeeting.id,
                recordingUrl: payload.zoomMeeting.recordingUrl,
                transcript: payload.zoomMeeting.transcript,
                transcriptionSummary: payload.zoomMeeting.transcriptionSummary,
                setIsShowingTranscriptSummary,
            };
        }

        const actionMenu = (
            <TimelineEntryActionMenu
                id={id}
                entryType={getEntryTypeFromPayload(payload)}
                handleDelete={props.onDeleteEvent}
            />
        );

        const participants = payload.participants.map((participant) => ({
            type: getApiTypeFromGraphQLTypename(participant.__typename),
            id: participant.id,
            name: participant.name,
            avatarUrl: participant.avatarUrl,
            initials: typeof participant.initials === 'string' ? participant.initials : undefined,
            htmlUrl: typeof participant.htmlUrl === 'string' ? participant.htmlUrl : undefined,
        }));

        if (relatedLeads) {
            relatedLeads.forEach((relatedLead) => {
                participants.push({
                    type: 'leads',
                    id: relatedLead.id,
                    name: relatedLead.name,
                    htmlUrl:
                        typeof relatedLead.htmlUrl === 'string' ? relatedLead.htmlUrl : undefined,
                });
            });
        }

        return (
            <TimelineEntry
                id={id}
                iconVariant={iconVariant}
                entryType={entryType}
                changeType={changeType}
                timestamp={changeTime}
                subject={payload.name}
                content={content}
                status={status}
                actor={actor}
                reactions={reactions}
                comments={comments}
                onAddComment={(value) =>
                    createComment({
                        changeLogId: id,
                        parentId: payload.id,
                        value,
                        user: props.currentUser,
                    })
                }
                onDeleteComment={(commentId) => deleteComment({changeLogId: id, commentId})}
                currentUser={props.currentUser}
                relatedLeads={
                    relatedLeads
                        ? relatedLeads.map((lead) => ({
                              type: 'leads',
                              id: lead.id,
                              name: lead.name,
                              avatarUrl: lead.avatarUrl,
                              number: lead.number,
                              status: lead.status,
                              priority: lead.priority,
                              htmlUrl: lead.htmlUrl,
                              relatedPerson: lead.contacts.primaryEdge
                                  ? {
                                        type: 'contacts',
                                        id: lead.contacts.primaryEdge.node.id,
                                        name: lead.contacts.primaryEdge.node.name,
                                        htmlUrl: lead.contacts.primaryEdge.node.htmlUrl,
                                    }
                                  : undefined,
                              relatedCompany: lead.accounts.primaryEdge
                                  ? {
                                        type: 'accounts',
                                        id: lead.accounts.primaryEdge.node.id,
                                        name: lead.accounts.primaryEdge.node.name,
                                        htmlUrl: lead.accounts.primaryEdge.node.htmlUrl,
                                    }
                                  : undefined,
                          }))
                        : undefined
                }
                participants={participants}
                onClickOpen={() => {
                    Modal.open({
                        url: `/activity/view/${payload.id}`,
                        onSuccess: props.refetchEvents,
                    });
                }}
                actionMenu={actionMenu}
                hasGutterLine={props.hasGutterLine}
                zoomMeetingData={zoomMeetingData}
                zoomTranscriptSummary={
                    isShowingTranscriptSummary && zoomMeetingData ? (
                        <TranscriptSummary
                            // $FlowIgnore this exists if we have zoomMeetingData
                            entityId={payload.zoomMeeting.id}
                            onAdd={() => saveTranscriptSummary(payload.id).then(closeSummary)}
                            onReplace={
                                payload.logNote
                                    ? () =>
                                          saveTranscriptSummary(payload.id, true).then(closeSummary)
                                    : undefined
                            }
                            onCancel={closeSummary}
                        />
                    ) : undefined
                }
                hasActivityTranscription={hasActivityTranscription}
            />
        );
    }

    return null;
}
