/* @flow */

import * as React from 'react';

import {Popover, type LocationEnum} from '../popover';
import {Button} from '../button';
import type {ButtonVariant} from '../button';

import './dialog.css';

export const DIALOG_ANSWER_PRI = 'Yes';
export const DIALOG_ANSWER_SEC = 'No';
export const DIALOG_ANSWER_TER = 'Maybe';

type DialogAnswer = 'Yes' | 'No' | 'Maybe';

type Props = {|
    anchor: ?HTMLElement,
    // This should only be used in situations where the anchor ref may become stale during the
    // lifecycle of the popover. This is a rare case and 'anchor' should be prioritized.
    anchorElementId?: string,
    question: string,
    primaryText: string,
    secondaryText?: string,
    tertiaryText?: string,
    primaryVariant?: ButtonVariant, // Button variant for the primary button
    secondaryVariant?: ButtonVariant, // Button variant for the secondary button
    tertiaryVariant?: ButtonVariant, // Button variant for the tertiary button
    isError?: boolean, // Overwrites primaryVariant - dialog will have red background
    isSave?: boolean, // Overwrites primaryVariant - dialog will have green background
    isWarning?: boolean, // Overwrites primaryVariant - primaryVariant will be 'danger'
    onPrimary?: (e: SyntheticEvent<*>) => void,
    onSecondary?: (e: SyntheticEvent<*>) => void,
    onTertiary?: (e: SyntheticEvent<*>) => void,
    onBlur?: () => any,
    location?: LocationEnum,
    customStyles?: any,
    children?: React.Node,
|};

export class Dialog extends React.Component<Props> {
    static displayName = 'Dialog';

    static defaultProps = {
        primaryText: DIALOG_ANSWER_PRI,
        secondaryText: DIALOG_ANSWER_SEC,
        primaryVariant: 'primary',
        secondaryVariant: 'secondary',
        tertiaryVariant: 'primary',
    };

    render() {
        let primaryVariant = this.props.primaryVariant;
        // Overwrite default primary variant for special cases
        if (this.props.isWarning) primaryVariant = 'danger';
        if (this.props.isError) primaryVariant = 'secondary'; // Yeah, it's stupid, but we need a grey box here.  :(
        if (this.props.isSave) primaryVariant = 'save';

        return (
            <Popover
                customStyles={this.props.customStyles}
                anchor={this.props.anchor}
                anchorElementId={this.props.anchorElementId}
                isDialog={true}
                bodyColor={this.props.isError ? 'error' : 'dark'}
                location={this.props.location}
                onBlur={(e) => {
                    e.stopPropagation();
                    if (this.props.onBlur) this.props.onBlur();
                }}
            >
                <div styleName='question'>{this.props.question}</div>
                {this.props.children}
                <div styleName='buttons'>
                    {this.props.tertiaryText ? (
                        <div styleName='left-buttons'>
                            <Button
                                variant={this.props.tertiaryVariant}
                                onClick={this.handleResult(DIALOG_ANSWER_TER)}
                            >
                                {this.props.tertiaryText}
                            </Button>
                        </div>
                    ) : undefined}
                    <div styleName='right-buttons'>
                        {this.props.isError ||
                        !this.props.onSecondary ||
                        !this.props.secondaryText ? null : (
                            <Button
                                variant={this.props.secondaryVariant}
                                onClick={this.handleResult(DIALOG_ANSWER_SEC)}
                            >
                                {this.props.secondaryText}
                            </Button>
                        )}
                        <Button
                            variant={primaryVariant}
                            onClick={this.handleResult(DIALOG_ANSWER_PRI)}
                        >
                            {this.props.primaryText}
                        </Button>
                    </div>
                </div>
            </Popover>
        );
    }

    handleResult = (answer: DialogAnswer) => (e: SyntheticEvent<*>) => {
        if (answer === DIALOG_ANSWER_PRI && this.props.onPrimary) {
            this.props.onPrimary(e);
        } else if (answer === DIALOG_ANSWER_SEC && this.props.onSecondary) {
            this.props.onSecondary(e);
        } else if (answer === DIALOG_ANSWER_TER && this.props.onTertiary) {
            this.props.onTertiary(e);
        }
    };
}
