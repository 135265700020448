/* @flow */

import {createAction} from 'redux-act';
import type {CoreEntityType} from 'nutshell-core/types';
import {formEncodedApi} from 'nutshell-core/api';
import {Session} from 'nutshell-core/session';

export const toggleShowFilterDrawer = createAction('ENTITY_LIST_FILTER_DRAWER_TOGGLED');

export function persistQueryParams(entityType: CoreEntityType, urlQuery: string) {
    const persistedFilterSetting = `entity_list_${entityType}_persisted_url`;

    formEncodedApi.post('/my-account/save-user-setting.json', {
        setting: persistedFilterSetting,
        value: urlQuery,
    });

    return {
        type: 'ENTITY_LIST_PERSIST_PARAMS',
        payload: {
            entityType,
            urlQuery,
        },
    };
}

/**
 * Helper function to fetch an entity list given an object of filter objects
 * as params.
 * @param  {string} entityType     The entity type to fetch
 * @param  {Object} filter         Object of filter objects {status: 'open'}
 *                                 to use as params
 * @param  {Object} [fields]       The fields to include in the export
 * @return {Promise}               jQueryXHR Promise object
 */
export function getList(
    entityType: CoreEntityType,
    filter: Object,
    fields?: {[entityType: CoreEntityType]: string}
) {
    const ajaxParams = {
        headers: {
            Accept: 'text/csv; charset=utf-8',
        },
        type: 'GET',
        url: `/rest/${entityType}/list`,
        data: {filter, fields},
    };

    // $FlowFixMe doesn't like our `headers` key
    return $.ajax(ajaxParams);
}

/**
 * [Thunk] Async action to export the list for an entity type, given a set of
 * filters and fields to include.
 *
 * @param  {string} entityType   The entity type to export
 * @param  {Object} filter       Object of filters to be passed in as params
 * @param  {Object} [fields]     The fields to include in the export
 * @return {Function}            Thunk to leverage dispatch and getState
 */
export function exportList(entityType: CoreEntityType, filter: Object, fields?: {leads: string}) {
    return function (dispatch: ThunkDispatch) {
        return getList(entityType, filter, fields).then(() => {
            dispatch(Session.pollForQueuedExports());
        });
    };
}

export function loadPersistedParams(params: Object) {
    return {
        type: 'ENTITY_LIST_LOAD_PERSISTED_PARAMS',
        payload: {
            accounts: params.accounts && params.accounts.value,
            contacts: params.contacts && params.contacts.value,
            leads: params.leads && params.leads.value,
        },
    };
}
