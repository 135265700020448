/* @flow */

import * as React from 'react';
import {PopoverNoPortal} from '../popover';

import './range-input.css';

type Props = {
    label?: string,
    // Added a string type here because at some point the value becomes a string.
    // We shoud look into this. Possibly a redux-form thing.
    value: number | string,
    onChange: (number) => void,
    customRange?: {
        start: string,
        end: string,
    },
    min?: number,
    max?: number,
    alwaysShowPopover?: boolean,
    popoverValue?: any,
    disabled?: boolean,
    zIndex?: number,
    disableFocusTrapZone?: boolean,
};

RangeInput.defaultProps = {
    alwaysShowPopover: true,
};

export function RangeInput(props: Props) {
    const [popoverLeftValue, setPopoverLeftValue] = React.useState<string>('0px');
    const rangeRef = React.useRef<any>(null);
    const popoverAnchorRef = React.useRef<any>(null);

    React.useEffect(() => {
        if (rangeRef.current && props.alwaysShowPopover) {
            calculatePopoverAnchorLeftPosition(rangeRef.current);
        }
    }, [rangeRef, props.alwaysShowPopover, props.value]);

    const calculatePopoverAnchorLeftPosition = (range) => {
        const val = range.value;
        const min = range.min ? range.min : 0;
        const max = range.max ? range.max : 100;
        const percent = Number(((val - min) * 100) / (max - min));
        const leftValue = `calc(${percent}% + 18px - ${(percent / 100) * 36}px)`;

        setPopoverLeftValue(leftValue);
    };

    const handleChange = (e: SyntheticInputEvent<*>) => {
        props.onChange(Number(e.target.value));

        // If rangeRef exists, re-calculate the popover position
        if (rangeRef.current) {
            calculatePopoverAnchorLeftPosition(rangeRef.current);
        }
    };

    return (
        <div>
            {props.label ? <label styleName='label'>{props.label}</label> : undefined}
            <div styleName='input-container'>
                {props.customRange ? undefined : <div styleName='value'>{props.value}%</div>}
                {props.popoverValue && popoverAnchorRef.current ? (
                    <PopoverNoPortal
                        anchor={popoverAnchorRef.current}
                        location='top'
                        zIndex={props.zIndex}
                        disableFocusTrapZone={props.disableFocusTrapZone}
                    >
                        <div className='pad-16'>{props.popoverValue}</div>
                    </PopoverNoPortal>
                ) : undefined}
                <input
                    ref={rangeRef}
                    value={props.value}
                    styleName='slider'
                    type='range'
                    min={props.min}
                    max={props.max}
                    onChange={handleChange}
                    disabled={props.disabled}
                />
                <div ref={popoverAnchorRef} style={{left: popoverLeftValue}} styleName='popover' />
            </div>
            {props.customRange ? (
                <div styleName='custom-range'>
                    <span>{props.customRange.start}</span>
                    <span>{props.customRange.end}</span>
                </div>
            ) : undefined}
        </div>
    );
}
