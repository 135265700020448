/* @flow */

import * as React from 'react';
import {Link} from 'react-router-dom';
import * as Leads from 'nutshell-core/leads';
import {Routing} from 'nutshell-core/routing';
import {FlameIcon} from 'shells/icon';
import styles from './linked-entity.css';

type Props = {
    lead: {
        name: string | React.Element<*>,
        status?: string,
        htmlUrl: string,
        priority?: string | number,
        avatarUrl?: string,
    },
    showIcon?: boolean,
    showFlame?: boolean,
    allowWrap?: boolean,
    isLinkable?: boolean,
    newTab?: boolean,
    disableRouting?: boolean,
};

export class LinkedLeadEntity extends React.PureComponent<Props> {
    static defaultProps = {
        isLinkable: true,
    };

    render() {
        const {lead, showIcon, showFlame, allowWrap} = this.props;
        const isHot =
            Leads.leadPriorityIsHot(lead.priority) &&
            lead.status &&
            lead.status.toLowerCase() === 'open';

        // The lead "name" can either be a react element which had its html set from solr, or a string
        // We want to get the raw string back out, to put into title attributes.
        const nameText: ?string =
            lead.name && typeof lead.name === 'object'
                ? lead.name.props &&
                  lead.name.props &&
                  lead.name.props.dangerouslySetInnerHTML &&
                  lead.name.props.dangerouslySetInnerHTML.__html
                : lead.name;

        const hasName = nameText && nameText.length;
        const entityName = hasName ? lead.name : <span styleName='nameless'>Unnamed lead</span>;
        const textContainerStyleName = allowWrap ? undefined : 'truncate';
        const entityElement = isHot ? (
            <div styleName={`${textContainerStyleName || ''} lead-name--hot`} title={nameText}>
                {entityName}
            </div>
        ) : (
            <div styleName={textContainerStyleName} title={nameText}>
                {entityName}
            </div>
        );

        const altText = `${lead.status || ''} lead icon`;

        const content = (
            <React.Fragment>
                {entityElement}
                {isHot && showFlame ? <FlameIcon size={null} wrapWithDiv={false} /> : null}
                {lead.avatarUrl && showIcon ? (
                    <img styleName='icon-image' alt={altText} src={lead.avatarUrl} />
                ) : undefined}
            </React.Fragment>
        );

        const containerStyleName = `container-leads${isHot ? ' hot' : ''}`;

        if (!this.props.isLinkable) {
            return (
                <div styleName={containerStyleName}>
                    <div styleName='lead-link'>{content}</div>
                </div>
            );
        }

        return (
            <div styleName={containerStyleName}>
                {Routing.hasWorkingUrl() && !this.props.disableRouting ? (
                    <Link
                        className={styles['lead-link']}
                        to={new URL(lead.htmlUrl).pathname}
                        onClick={this.handleClick}
                        target={this.props.newTab ? '_blank' : undefined}
                        rel={this.props.newTab ? 'noreferrer noopener' : undefined}
                    >
                        {content}
                    </Link>
                ) : (
                    <a
                        styleName='lead-link'
                        onClick={this.handleClick}
                        href={lead.htmlUrl}
                        target={this.props.newTab ? '_blank' : undefined}
                        rel={this.props.newTab ? 'noreferrer noopener' : undefined}
                    >
                        {content}
                    </a>
                )}
            </div>
        );
    }

    handleClick = (e: Event) => {
        e.stopPropagation();
    };
}
