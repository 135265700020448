/* @flow */

import * as React from 'react';

type Props = {
    originatedVia: string,
};

export function TimelineHeaderEmailSource(props: Props) {
    return <div>{props.originatedVia}</div>;
}
