/* @flow */

import * as React from 'react';

import type {EventFragmentSparse} from 'nutshell-graphql-types';
import {LinkedEntity} from 'shells/linked-entity';
import {getApiTypeFromGraphQLTypename} from 'nutshell-core/utils';
import {TimelineBannerFilesCard} from 'shells/timeline/banner/timeline-banner-files-card';

import {TimelineBanner} from './timeline-banner';

import './timeline-banner.css';

type Props = {|event: EventFragmentSparse|};

export function TimelineBannerFileUploads(props: Props) {
    const {event} = props;
    const {payload} = event;

    /**
     * We need to include this check, even though we are only passing an event
     * with a payload.__typename of 'EntityAttributeValueFile' because Apollo doesn't generate a specific
     * enough type when using a union type (like the TimelineEventPayload).
     * See this issue for more detail:
     * https://github.com/apollographql/apollo-tooling/issues/1223
     */
    if (payload.__typename === 'EntityAttributeValueFile') {
        const fileCount = event.files.length;
        const text = fileCount === 1 ? 'uploaded 1 file' : `uploaded ${fileCount} files`;
        const entityName =
            payload.eavEntity.name && typeof payload.eavEntity.name === 'string'
                ? payload.eavEntity.name
                : undefined;
        const entityHtmlUrl =
            payload.eavEntity.htmlUrl && typeof payload.eavEntity.htmlUrl === 'string'
                ? payload.eavEntity.htmlUrl
                : undefined;

        const action = (
            <span>
                {text}
                {entityName && entityHtmlUrl ? (
                    <>
                        &nbsp;to&nbsp;
                        <strong>
                            <LinkedEntity
                                type={getApiTypeFromGraphQLTypename(payload.__typename)}
                                shouldClientRoute={true}
                                name={entityName}
                                htmlUrl={entityHtmlUrl}
                                allowWrap={true}
                                displayInline={true}
                            />
                        </strong>
                    </>
                ) : undefined}
            </span>
        );

        return (
            <>
                <TimelineBanner
                    id={event.id}
                    iconVariant='file-upload'
                    timestamp={event.changeTime}
                    actor={event.actor}
                    action={action}
                    bannerBody={<TimelineBannerFilesCard files={event.files} />}
                />
            </>
        );
    }

    return null;
}
