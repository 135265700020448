/* @flow */

import * as React from 'react';
import {Quill} from 'react-quill';
import ImageResize from 'quill-image-resize-module';
import {ImageDrop} from 'quill-image-drop-module';

import {PlaceholderBlot} from '../email-editor/placeholder-blot';
import {PlaceholderBlotCustom} from '../email-editor/placeholder-blot-custom';
import {PlaceholderBlotCustomPlaceholder} from '../email-editor/placeholder-blot-custom-placeholder';
import {PlaceholderModule} from '../email-editor/placeholder-module';
import {CustomMentionBlot} from './mention-blot';
import type {MentionConfig} from './rich-textarea';

const builtInQuillIcons = Quill.import('ui/icons');

// We want Quill to be configured a single time. Additionally, wrapping it in a
// function like this allows us to delay initialization until we use it. This
// way we don't load it when the module loads only when we use it.
export const configureQuill = ({
    images,
    useDivs,
    formatLinks,
    placeholders,
    mention,
}: {
    images: boolean,
    useDivs: boolean,
    formatLinks: boolean,
    placeholders: boolean,
    mention?: MentionConfig,
}) => {
    if (images) {
        // In order to show images with the correct alignment that has been previously saved
        // into the database, we need to enable using the `style` format.
        // See: https://github.com/kensnyder/quill-image-resize-module/issues/10#issuecomment-317747389
        const BaseImageFormat = Quill.import('formats/image');
        const ImageFormatAttributesList = ['alt', 'height', 'width', 'style'];
        class ImageFormat extends BaseImageFormat {
            static formats(domNode) {
                return ImageFormatAttributesList.reduce(function (formats, attribute) {
                    if (domNode.hasAttribute(attribute)) {
                        formats[attribute] = domNode.getAttribute(attribute);
                    }

                    return formats;
                }, {});
            }
            format(name, value) {
                if (ImageFormatAttributesList.indexOf(name) > -1) {
                    if (value) {
                        this.domNode.setAttribute(name, value);
                    } else {
                        this.domNode.removeAttribute(name);
                    }
                } else {
                    super.format(name, value);
                }
            }
        }

        Quill.register(ImageFormat, true);
        // Register imageDrop module. Allows copy & pasting images into the
        // editor
        Quill.register('modules/imageDrop', ImageDrop, true);

        // Register the imageResize module. Adds a native "resize" handler to every
        // image in the editor, and allows aligning images left/center/right
        Quill.register('modules/imageResize', ImageResize, true);
    }

    if (useDivs) {
        // Override default tag with `<div>` instead of `<p>`.
        // We do this because email clients handle the spacing of `<p>` tags
        // differently, and we want consistency.
        const Block = Quill.import('blots/block');
        Block.tagName = 'DIV';
        Quill.register(Block, true);
    }

    if (formatLinks) {
        const Link = Quill.import('formats/link');
        Link.sanitize = function (url) {
            // We don't want relative urls, which quill gives if it can't find a valid protocol,
            // so add http if necessary.
            const protocol = url.slice(0, url.indexOf(':'));
            if (Link.PROTOCOL_WHITELIST.indexOf(protocol) === -1) {
                return `http://${url}`;
            }

            return url;
        };
        Quill.register(Link, true);
    }

    // Placeholder attributes
    if (placeholders) {
        const Parchment = Quill.import('parchment');
        const PlaceholderClass = new Parchment.Attributor.Class('token', 'placeholder-suggestion', {
            scope: Parchment.Scope.INLINE,
            whitelist: ['suggestion'],
        });

        Quill.register(PlaceholderClass, true);
        Quill.register(PlaceholderBlot, true);
        Quill.register(PlaceholderBlotCustom, true);
        Quill.register(PlaceholderBlotCustomPlaceholder, true);
        Quill.register('modules/placeholder', PlaceholderModule, true);
    }

    if (mention) {
        Quill.register(CustomMentionBlot, true);
    }

    // There's probably a more elegant way to do this, but overriding
    // their default icons like this seemingly allows us to just use
    // our own below /shrug
    builtInQuillIcons.bold = <div />;
    builtInQuillIcons.italic = <div />;
    builtInQuillIcons.underline = <div />;
    builtInQuillIcons.list = <div />;
    builtInQuillIcons.image = <div />;
    builtInQuillIcons.link = <div />;
};
