/* @flow */

import * as React from 'react';
import classnames from 'classnames';

import {DescriptionBody} from '../typography/description-body';
import {EditableLabelWrapping} from '../editable-label/editable-label-wrapping';

import {ErrorMessage} from './error-message';

import './form-field.css';

type Props = {
    editableLabelProps: {
        onSaveNewValue: (string) => Promise<*>,
        placeholder: string,
        allowSavingEmpty: boolean,
        shouldAllowUndo: boolean,
        required?: boolean,
        tooltip?: ?string,
        shouldFocusOnMount?: boolean,
        onFocusedOnMount?: () => void,
    },
    label: string,
    /** The id of the main form element, used to connect the label to it */
    innerId: string,
    /** Text to display in red under the main form element */
    errorMessage?: ?string,
    /** Display with no margin */
    noMargin?: ?boolean,
    /** Text to display in grey under the main form element, if no error */
    helperText?: ?string | React.Node,
    tooltipInfo?: string | React.Node,
    isFullWidth?: boolean,
    smallBottomMargin?: boolean,
    /** The main form element */
    children: React.Node,
};

export function EditableFormField({
    editableLabelProps,
    label,
    helperText,
    errorMessage,
    noMargin,
    isFullWidth,
    smallBottomMargin,
    children,
}: Props) {
    const styleName = classnames('form-field', {
        'form-field--with-footer': errorMessage || helperText,
        'form-field--no-margin': noMargin,
        'form-field--full-width': isFullWidth,
    });

    return (
        <div styleName={styleName} style={smallBottomMargin ? {marginBottom: '16px'} : undefined}>
            <EditableLabelWrapping {...editableLabelProps} value={label} tooltip={null} />
            {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : undefined}
            {children}
            {helperText && !errorMessage ? (
                <DescriptionBody>{helperText}</DescriptionBody>
            ) : undefined}
        </div>
    );
}
