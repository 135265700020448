/* @flow */

import * as React from 'react';
import {components, type ControlProps, type OptionProps} from 'react-select';

import {AccountIcon} from 'shells/icon';

import {EntitySelectRow} from 'shells/entity/entity-select-row';

import {GraphQLSelectCreatable, type Option} from './graphql-select-creatable';
import {useGetAccounts} from './hooks/use-get-accounts';
import {useCreateAccount} from './hooks/use-create-account';
import {ControlWithIcon} from './control-with-icon';

type Props = {
    selectedValues: Option[],
    onChange: (values: Option | Option[]) => any,
    onClearValue?: () => void,
    isMulti: boolean,
    clearable?: boolean,
};

export const GraphQLSelectAccounts = (props: Props) => {
    const {fetchMore, isLoading} = useGetAccounts();
    const {createAccount} = useCreateAccount();

    const handleCreate = (newAccount: string) => {
        // Immediately add a temp account to the list of selected values
        const tempAccount = {id: newAccount, name: newAccount};
        if (props.isMulti && Array.isArray(props.selectedValues)) {
            props.onChange([...props.selectedValues, tempAccount]);
        } else {
            props.onChange(tempAccount);
        }

        createAccount({name: newAccount}).then((result) => {
            const createdAccount =
                result.data && result.data.accountCreate && result.data.accountCreate.account;
            const createdAccountValue = createdAccount
                ? {id: createdAccount.id, name: createdAccount.name}
                : null;

            // Add the real created account to the list of selected values
            if (createdAccountValue) {
                if (props.isMulti && Array.isArray(props.selectedValues)) {
                    props.onChange([...props.selectedValues, createdAccountValue]);
                } else {
                    props.onChange(createdAccountValue);
                }
            }
        });
    };

    const handleLoadOptions = (search: ?string) => {
        return fetchMore(search).then(({data}) => {
            const quickResults = data.search ? data.search.quickResults : null;

            let accountResults = quickResults ? quickResults.accountResults : [];

            if (accountResults.length === 0) {
                const recentResults =
                    quickResults && quickResults.recentResults ? quickResults.recentResults : [];
                if (recentResults && recentResults.length > 0) {
                    accountResults = recentResults;
                }
            }

            return Promise.resolve(accountResults);
        });
    };

    return (
        <GraphQLSelectCreatable
            placeholder={
                props.isMulti ? 'Select or create companies' : 'Select or create a company'
            }
            value={props.selectedValues}
            multi={props.isMulti}
            onClearValue={props.onClearValue}
            loadOptions={handleLoadOptions}
            onChange={props.onChange}
            onBlur={() => {
                props.onChange(props.selectedValues);
            }}
            noOptionsMessage={({inputValue}) => {
                if (isLoading) {
                    return 'Getting recent results…';
                }
                if (inputValue.length) {
                    return `No companies found matching '${inputValue}'`;
                }
                if (inputValue.length === 0) {
                    return 'No recently viewed companies. Type to search all companies…';
                }

                return null;
            }}
            formatCreateLabel={(inputValue) => `Create new company named '${inputValue}'`}
            onCreate={handleCreate}
            components={{Control: ControlComponent, Option: OptionComponent}}
            labelKey='name'
            valueKey='id'
            clearable={props.clearable}
        />
    );
};

const OptionComponent = (props: OptionProps) => {
    // This is a hack to get around limitations in our old version of flow
    const spreadableProps: $Exact<OptionProps> = (props: any);

    if (props.data.__isNew__) {
        return (
            // $FlowFixMe upgrading Flow to v0.92.1 on web
            <components.Option {...spreadableProps}>{props.data.name}</components.Option>
        );
    }

    const relatedName =
        props.data.contacts && props.data.contacts.edges && props.data.contacts.edges[0]
            ? props.data.contacts.edges[0].node.name
            : undefined;

    return (
        // $FlowFixMe upgrading Flow to v0.92.1 on web
        <components.Option {...spreadableProps}>
            <EntitySelectRow
                entity={{
                    id: props.data.id,
                    type: 'accounts',
                    value: props.data.name,
                    description: '',
                    avatarUrl: props.data.avatarUrl,
                    initials: props.data.initials,
                    secondaryInfo: relatedName ? (
                        <span
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: relatedName,
                            }}
                        />
                    ) : undefined,
                }}
            />
        </components.Option>
    );
};

const ControlComponent = ({children, ...props}: {...ControlProps}) => (
    <components.Control {...props}>
        <ControlWithIcon icon={AccountIcon}>{children}</ControlWithIcon>
    </components.Control>
);
