/* @flow */
/* eslint-disable react/no-danger */
/* We need to use dangerouslySetInnerHTML in this file because we are rendering the results from solr,
   which helpfully highlights the parts of the results that matched the search query */

import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';
import type {FormattedValue} from 'nutshell-core/types';

import {colors} from '../colors';
import {LinkedLeadEntity} from '../linked-entity';
import {LeadFormattedValue} from '../lead-card/lead-formatted-value';
import {RelatedEntity} from '../lead-card/related-entity';
import {LoadingText} from '../loading-placeholder';
import {LeadAvatar} from './lead-avatar';

import './lead-two-line.css';

type Props = {
    isLinkable?: boolean,
    newTab?: boolean,
    disableRouting?: boolean,
    isLoading?: boolean,

    leadName: string,
    leadNumber: ?number,
    avatarUrl: string,
    priority?: string,
    htmlUrl: string,
    leadStatus: string,
    relatedPerson?: ?{
        id: string,
        type: string,
        htmlUrl: string,
        name: string,
    },
    relatedCompany?: ?{
        id: string,
        type: string,
        htmlUrl: string,
        name: string,
    },
    // We don't always require these two properties, for situations
    // like the entity list which doesn't want additional metadata
    // on the right side of the two-line information
    overdueDuration?: ?string,
    formattedValue?: FormattedValue,
    withLessPadding?: boolean,
};

export class LeadTwoLine extends React.PureComponent<Props> {
    componentDidMount() {
        ReactTooltip.rebuild();
    }

    render() {
        if (this.props.isLoading) {
            return this.renderLoadingPlaceholder();
        }

        // If both related entity fields aren't even defined, then don't try to show second row
        const hideRelatedEntity =
            typeof this.props.relatedCompany === 'undefined' &&
            typeof this.props.relatedPerson === 'undefined';

        const styleNames = classnames({
            'lead-two-line': !this.props.leadNumber,
            'lead-two-line--with-number': this.props.leadNumber,
            'less-padding': this.props.withLessPadding,
        });

        // The bottom row might not have content, we don't want to render it in that case
        let bottomRowContent;
        if (!hideRelatedEntity) {
            // possible related entity, could be null
            let relatedEntity = (
                <RelatedEntity
                    isLinkable={Boolean(this.props.isLinkable)}
                    relatedPerson={this.props.relatedPerson}
                    relatedCompany={this.props.relatedCompany}
                    leadName={this.props.leadName}
                />
            );

            // converts jsx to a string, is empty if nothing is rendered by that component
            if (!ReactDOMServer.renderToStaticMarkup(relatedEntity)) {
                relatedEntity = null;
            }

            // could be overdue duration
            const overdueDuration = this.props.overdueDuration ? (
                <div
                    styleName='overdue-duration'
                    data-tip={`This lead is overdue by ${this.props.overdueDuration}`}
                >
                    {this.props.overdueDuration}
                </div>
            ) : undefined;

            if (relatedEntity || overdueDuration) {
                bottomRowContent = (
                    <>
                        {relatedEntity && <div styleName='associated'>{relatedEntity}</div>}
                        {overdueDuration}
                    </>
                );
            }
        }

        return (
            <div styleName={styleNames}>
                <LeadAvatar avatarUrl={this.props.avatarUrl} leadNumber={this.props.leadNumber} />

                <div styleName={hideRelatedEntity ? 'lead-info' : 'lead-info--two-rows'}>
                    <div styleName='top-row'>
                        <LinkedLeadEntity
                            isLinkable={this.props.isLinkable}
                            newTab={this.props.newTab}
                            lead={{
                                name: (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: this.props.leadName,
                                        }}
                                    />
                                ),
                                priority: this.props.priority,
                                status: this.props.leadStatus,
                                htmlUrl: this.props.htmlUrl,
                            }}
                            showFlame={true}
                            disableRouting={this.props.disableRouting}
                        />
                        {this.props.formattedValue ? (
                            <LeadFormattedValue formattedValue={this.props.formattedValue} />
                        ) : undefined}
                    </div>
                    {bottomRowContent ? (
                        <div styleName='bottom-row'>{bottomRowContent}</div>
                    ) : undefined}
                </div>
            </div>
        );
    }

    renderLoadingPlaceholder = () => {
        // This is really hacky, but we are currently always show a lead number on the lead list page,
        // so if that's where this component is being rendered, let's leave a placeholder space for the number.
        const goingToHaveNumber = Boolean(
            this.props.leadNumber || window.location.pathname === '/leads'
        );

        return (
            <div styleName={goingToHaveNumber ? 'lead-two-line--with-number' : 'lead-two-line'}>
                <LeadAvatar isLoading={true} includeNumber={goingToHaveNumber} />
                <div styleName='lead-info'>
                    <div styleName='top-row'>
                        <div style={{height: 17, paddingTop: 4}}>
                            <LoadingText height={13} color={colors.offWhiteDk} />
                        </div>
                    </div>
                    <div styleName='bottom-row'>
                        <LoadingText height={13} color={colors.offWhiteMd} />
                    </div>
                </div>
            </div>
        );
    };
}
