/* @flow */

import * as React from 'react';

import {Link as RoutedLink} from 'react-router-dom';

import {Body} from '../../typography';
import {Link} from '../../link';

import './billing-summary.css';

type Props = {title: string, subtitle?: string, linkProps?: ?{to: string, text: string}};

export function SectionHeader(props: Props) {
    return (
        <div className='flex justify-sb py-8 px-16 background-offwhite-dk'>
            <div className='flex flex-dir-col gap-4'>
                <Body isBold={true}>{props.title}</Body>
                {props.subtitle && (
                    <Body color='light' size='small'>
                        {props.subtitle}
                    </Body>
                )}
            </div>
            {props.linkProps && (
                <Link as={RoutedLink} variant='primary' to={props.linkProps.to} size='small'>
                    {props.linkProps.text}
                </Link>
            )}
        </div>
    );
}
