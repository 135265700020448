/* @flow */

import * as React from 'react';
import classnames from 'classnames';

import {SavedSettingToast, type SavedSettingToastState} from '../saved-setting-toast';
import {DescriptionBody} from '../typography/description-body';
import {ErrorMessage} from './error-message';
import {Label} from './label';

import './form-field.css';

type Props = {
    label?: string | React.Node,
    /** Text to display in grey under the label */
    sublabel?: string | React.Node,
    /** The id of the main form element, used to connect the label to it */
    innerId: string,
    /** Text to display in red under the main form element */
    errorMessage?: ?string,
    /** Display with no margin */
    noMargin?: ?boolean,
    /** Text to display in grey under the main form element, if no error */
    helperText?: ?string | React.Node,
    tooltipInfo?: string | React.Node,
    isFullWidth?: boolean,
    isRequired?: boolean,
    /** This is used if 'isRequired' and the label is more than one line -
     * flex messes up the position of the asterisk for these cases */
    labelIsTwoLines?: boolean,
    saveToastState?: SavedSettingToastState,
    /** The main form element */
    children: React.Node,
};

export function FormField({
    innerId,
    label,
    sublabel,
    tooltipInfo,
    helperText,
    errorMessage,
    noMargin,
    isFullWidth,
    isRequired,
    labelIsTwoLines,
    saveToastState,
    children,
}: Props) {
    const styleName = classnames('form-field', {
        'form-field--with-footer': (errorMessage || helperText) && label,
        'form-field--with-footer--no-label': (errorMessage || helperText) && !label,
        'form-field--no-margin': noMargin,
    });

    const getLabelComponent = () => {
        const labelComponent = label ? (
            <Label
                tooltipInfo={tooltipInfo}
                htmlFor={innerId}
                required={isRequired}
                isTwoLines={labelIsTwoLines}
            >
                {label}
            </Label>
        ) : null;

        if (saveToastState) {
            return (
                <SavedSettingToast state={saveToastState} toastStyles={{marginBottom: '8px'}}>
                    {labelComponent}
                </SavedSettingToast>
            );
        }

        return labelComponent;
    };

    return (
        <div styleName={styleName} style={isFullWidth ? {width: '100%'} : undefined}>
            {getLabelComponent()}
            {sublabel && (
                <div className='mb-8'>
                    <DescriptionBody>{sublabel}</DescriptionBody>
                </div>
            )}
            {children}
            {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : undefined}
            {helperText && !errorMessage ? (
                <DescriptionBody>{helperText}</DescriptionBody>
            ) : undefined}
        </div>
    );
}
