/* @flow */

import * as React from 'react';
import classnames from 'classnames';

import './price.css';

type Props = {|
    price: string,
    strikethrough?: boolean,
    isBold?: boolean,
    color?: 'light' | 'medium' | 'dark' | 'green',
    hasMarketingFont?: boolean,
    size?: 'small' | 'normal' | 'large' | 'extraLarge',
    includeDollarSign?: boolean,
    isFlexEnd?: boolean,
    includePlus?: boolean,
|};

export function Price(props: Props) {
    const {
        isBold = true,
        color = 'dark',
        size = 'normal',
        includeDollarSign = true,
        includePlus,
    } = props;

    const priceComponents = props.price.split('.');

    const styleNames = classnames({
        container: true,
        strikethrough: props.strikethrough,
        bold: isBold,
        dark: color === 'dark',
        medium: color === 'medium',
        light: color === 'light',
        green: color === 'green',
        marketing: props.hasMarketingFont,
        small: size === 'small',
        large: size === 'large',
        'extra-large': size === 'extraLarge',
        'flex-end': props.isFlexEnd,
    });

    return (
        <div styleName={styleNames}>
            <span>
                {includeDollarSign ? '$' : ''}
                {priceComponents[0]}
            </span>
            {includePlus
                ? '+'
                : priceComponents.length > 0 && (
                      <span styleName='decimal'>{priceComponents[1]}</span>
                  )}
        </div>
    );
}
