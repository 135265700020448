/* @flow */

import React from 'react';
import {svgIconWrapper} from '../svg-icon-wrapper';
import MessageSolid from './icon-message-solid.svg';

function SvgComponent(props) {
    const validProps = Object.assign({}, props);
    delete validProps.styles;

    return <MessageSolid {...validProps} />;
}
SvgComponent.iconName = 'message-icon-solid';

export const MessageIconSolid = svgIconWrapper(SvgComponent);
