/* @flow */

import * as React from 'react';

import {useRefObserver} from '../../utils/hooks/use-ref-observer';
import {CopyIcon} from '../../icon';
import {Button} from '../button';
import {Popover} from '../../popover';

type Props = {
    target: ?React.Ref<*>,
    size: 'big' | 'xlarge',
    variant?: 'primary' | 'secondary',
    onCopy?: () => void,
};

const COPY_TEXT = 'Copy';
const COPIED_TEXT = 'Copied!';
const COPIED_BUTTON_TEXT = 'Copied';

type ButtonState = 'Copy' | 'Copied!';

export function CopyButton(props: Props) {
    const [target, setTarget] = React.useState(null);
    const buttonRef = React.useRef(null);
    const {isHovered} = useRefObserver(buttonRef, ['isHovered']);
    const [buttonText, setButtonText] = React.useState<ButtonState>(COPY_TEXT);
    const [showTooltip, setShowTooltip] = React.useState(false);

    React.useEffect(() => {
        if (props.target && typeof props.target === 'object') {
            // $FlowFixMe upgrading Flow to v0.110.1
            setTarget(props.target.current);
        }
    }, [props.target]);

    const copyToClipboard = (event: SyntheticEvent<*>) => {
        event.stopPropagation();
        if (!target) return;

        let textToCopy = '';
        if (target.nodeName === 'INPUT' || target.nodeName === 'TEXTAREA') {
            textToCopy = target.value;
        } else {
            textToCopy = target.innerText;
        }

        navigator.clipboard.writeText(textToCopy).then(() => {
            setButtonText(COPIED_TEXT);
            if (props.onCopy) {
                props.onCopy();
            }
        });
    };

    React.useEffect(() => {
        let timeoutId;
        if (isHovered) {
            setShowTooltip(true);
        } else if (buttonText === COPIED_TEXT) {
            timeoutId = setTimeout(() => {
                setShowTooltip(false);
                setButtonText(COPY_TEXT);
            }, 500);
        } else {
            setShowTooltip(false);
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [isHovered, buttonText]);

    return (
        <>
            <Button
                size={props.size}
                icon={CopyIcon}
                variant={props.variant}
                getButtonRef={buttonRef}
                onClick={copyToClipboard}
            >
                {buttonText === COPIED_TEXT ? COPIED_BUTTON_TEXT : COPY_TEXT}
            </Button>
            {showTooltip && (
                <Popover anchor={buttonRef.current} bodyColor='dark' location='top'>
                    <div className='pad-8'>{buttonText}</div>
                </Popover>
            )}
        </>
    );
}
