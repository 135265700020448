/* @flow */

import * as React from 'react';
import classnames from 'classnames';
import {PopoverInfo} from '../popover-info';

import './label.css';

type Props = {
    htmlFor: string,
    tooltipInfo?: string | React.Node,
    required?: boolean,
    isTwoLines?: boolean,
    children?: React.Node,
    inline?: boolean,
    noBottomMargin?: boolean, // Label without the 8px bottom margin
    largeSize?: boolean, // Font size is 16px for certain forms where other labels are standard 15px
};

export function Label(props: Props) {
    const styleNames = classnames({
        label: true,
        'label--inline': props.inline,
        'label--required': props.required,
        'label--inline-block': props.isTwoLines,
        'label--no-bottom-margin': props.noBottomMargin,
        'label--large-size': props.largeSize,
    });

    return (
        <label styleName={styleNames} htmlFor={props.htmlFor}>
            {props.children}
            {props.tooltipInfo ? (
                <div style={{height: 0, marginTop: '-1px'}}>
                    <PopoverInfo size={18} info={props.tooltipInfo} />
                </div>
            ) : undefined}
        </label>
    );
}
