/* @flow */

/*
 * These selectors pull from both the leads and contacts state, and require
 * both a `state` parameter as well as a `contactId`, to be able to pull together
 * lead information for a particular contact.
 */

import {createSelector, type OutputSelector} from 'reselect';
import * as Contacts from '../contacts';
import type {ContactId, LeadCardData, ListItem} from '../types';
import type {State as LeadsState} from './leads-reducer';
import {getLeadListItemMap} from './leads-selectors';

// The root state will include more keys, but this is what we really care about here
type LeadsAndContactsModulesState = {
    leads: LeadsState,
    contacts: Contacts.State,
};

/**
 * Helper: Get the lead list related to a particular contact
 *
 * This allows other selectors to take an argument of contactId, and return just the lead list
 * items associated with that contact.
 *
 * @param {Object} state     - Top-level redux state
 * @param {string} contactId - API id of a particular contact
 */
export const getLeadListItemsForContact: OutputSelector<
    LeadsAndContactsModulesState,
    ContactId,
    ListItem[],
> = createSelector(
    [getLeadListItemMap, Contacts.getRelatedLeadIds],
    (leadListItemMap, contactRelatedLeadIds) => {
        return contactRelatedLeadIds.map((leadId) => leadListItemMap[leadId]);
    }
);

/**
 * Get the open lead list items from the store.
 *
 * @param {Object} state     - Top-level redux state
 * @param {string} contactId - API id of a particular contact
 */
export const getOpenLeadListItems: OutputSelector<
    LeadsAndContactsModulesState,
    ContactId,
    ListItem[],
> = createSelector([getLeadListItemsForContact], (leadListItems) => {
    return leadListItems.filter(
        (item) =>
            item.fields.status &&
            item.fields.status.value &&
            item.fields.status.value.value === 'Open'
    );
});

/**
 * Gets open lead list items in a format that can be rendered by LeadCard components
 *
 * @param {Object} state     - Top-level redux state
 * @param {string} contactId - API id of a particular contact
 */
export const getOpenLeadListItemsForCard: OutputSelector<
    LeadsAndContactsModulesState,
    ContactId,
    LeadCardData[],
> = createSelector([getOpenLeadListItems], (openLeadListItems) =>
    openLeadListItems.map(convertListItemToLeadCardProps)
);

/**
 * Get the closed lead list items from the store
 *
 * @param {Object} state     - Top-level redux state
 * @param {string} contactId - API id of a particular contact
 */
// $FlowFixMe upgrading Flow to v0.92.1
export const getClosedLeadListItems = createSelector(
    [getLeadListItemsForContact],
    (leadListItems: ListItem[]) => {
        return leadListItems.filter(
            (item) =>
                item.fields.status &&
                item.fields.status.value &&
                item.fields.status.value.value !== 'Open' &&
                item.fields.status.value.value !== 'pending'
        );
    }
);

/**
 * Get closed lead list items in a format that can be rendered by LeadCard components
 *
 * @param {Object} state     - Top-level redux state
 * @param {string} contactId - API id of a particular contact
 */
export const getClosedLeadListItemsForCard: (
    state: LeadsAndContactsModulesState,
    contactId: ContactId
) => LeadCardData[] = createSelector([getClosedLeadListItems], (closedLeadListItems) =>
    closedLeadListItems.map(convertListItemToLeadCardProps)
);

/**
 * Get the number of closed lead list items for a given contact
 *
 * TODO: Deprecate this and use the `meta` from the list response instead, perhaps storing it in the contact reducer
 *
 * @param {Object} state     - Top-level redux state
 * @param {string} contactId - API id of a particular contact
 */
export const getClosedLeadCountForContact: (
    state: LeadsAndContactsModulesState,
    contactId: ContactId
) => number = createSelector(
    [getClosedLeadListItems],
    (closedLeadListItems) => closedLeadListItems.length
);

/**
 * Get the total value of won leads
 *
 * TODO: Replace this with an attribute on the contact entity
 *
 * @param {Object} state     - Top-level redux state
 * @param {string} contactId - API id of a particular contact
 */
export const getWonLeadValueForContact: (
    state: LeadsAndContactsModulesState,
    contactId: ContactId
) => string = createSelector([getClosedLeadListItems], (closedLeadListItems) => {
    const totalWonValue = closedLeadListItems
        .filter(
            (item) =>
                item.fields.status &&
                item.fields.status.value &&
                item.fields.status.value.value === 'Won'
        )
        .reduce((totalValue, item) => {
            const formattedValue =
                item.fields.value && item.fields.value.formattedValue
                    ? item.fields.value.formattedValue.value
                    : 0;

            return totalValue + formattedValue;
        }, 0);

    return `$${totalWonValue}`;
});

/**
 * Check whether a contact has any associated leads
 *
 * @param {Object} state     - Top-level redux state
 * @param {string} contactId - API id of a particular contact
 */
// $FlowFixMe upgrading Flow to v0.92.1
export const contactHasLeads = createSelector(
    [getLeadListItemsForContact],
    (leadsForContact) => leadsForContact.length > 0
);

/**
 * Convert a lead list item into a shape that can be rendered by a LeadCardBasic
 *
 * @param  {Object} listItem An individual lead list item
 * @return {Object}          An object that can be rendered by a lead card
 */
function convertListItemToLeadCardProps(listItem: ListItem): LeadCardData {
    return {
        id: listItem.links.entity.id,
        htmlUrl: listItem.htmlUrl,
        leadName: listItem.primaryName,
        leadNumber: listItem.primaryInfo,
        avatarUrl: listItem.avatarUrl,
        value: listItem.fields.value.value,
        assignee: listItem.fields.owner.value,
        relatedPerson: listItem.primaryContact,
        relatedCompany: listItem.primaryAccount,
    };
}
