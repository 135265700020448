/* @flow */

import * as React from 'react';
import {Provider} from 'react-redux';
import {Router} from 'react-router-dom';
import {ApolloProvider} from 'react-apollo';
import {ApolloProvider as ReactHooksApolloProvider} from '@apollo/react-hooks';

import {store} from '../../../store';
import {appHistory} from '../../../history';
import {nutApolloClient} from '../../../apollo-client';

import {FeatureGateModalWrapper} from './feature-gate-modal-wrapper';

type Props = {
    onClose: () => void,
    isOpen: boolean,
};
export function AiSummaryFeatureGateModal(props: Props) {
    return (
        <Provider store={store}>
            <ApolloProvider client={nutApolloClient}>
                <ReactHooksApolloProvider client={nutApolloClient}>
                    <Router history={appHistory}>
                        <FeatureGateModalWrapper
                            isOpen={props.isOpen}
                            title='Automatically summarize your calls'
                            description='Upgrade Nutshell to summarize your calls and activities with one click'
                            bullets={[
                                'Use click-to-call and transcripts to automatically summarize the phone calls you make with Nutshell',
                                'Also works with our Zoom integration to summarize recordings',
                                'Summarize any activity that has an attached transcript and get action items, and an overview of the conversation',
                            ]}
                            feature='summarization'
                            onClose={props.onClose}
                        />
                    </Router>
                </ReactHooksApolloProvider>
            </ApolloProvider>
        </Provider>
    );
}
