/* @flow */

import * as React from 'react';

import {SectionHeader} from './section-header';
import './billing-summary.css';

type Props = {title: string, linkProps: ?{to: string, text: string}};

export function TableSectionHeader(props: Props) {
    return (
        <thead>
            <tr>
                <td colSpan='4' styleName='section-header'>
                    <SectionHeader title={props.title} linkProps={props.linkProps} />
                </td>
            </tr>
        </thead>
    );
}
