/* @flow */

import {createReducer} from 'redux-act';
import {REHYDRATE} from 'redux-persist';
import moment from 'moment';
import {getRehydratedValueForDateProperty} from 'nutshell-core/reducer-utils';

import * as actions from '../../dashboard-actions';

type ActivitiesState = {
    items: {[id: string]: ActivityState},
    totalOverdueCount: number,
};

const defaultActivitiesState = {
    items: {},
    totalOverdueCount: 0,
};

type ActivitiesConfigState = {
    filter: string,
    previousUrl?: string,
    nextUrl?: string,
    displayDate: number, // UNIX milliseconds
    scrolledToDate: number, // UNIX milliseconds
    lastUpdated: ?number, // UNIX milliseconds
};

const defaultActivitiesConfigState = {
    filter: '',
    previousUrl: undefined,
    nextUrl: undefined,
    displayDate: moment().startOf('day').valueOf(),
    scrolledToDate: moment().startOf('day').valueOf(),
    lastUpdated: null,
};

type User = Object; // TODO (@ianvs 2017-06-26): give this an actual definition

type ActivityState = {
    id: string,
    type?: 'activities',
    name?: string,
    errorMessage?: string,
    startTime: ?number, // UNIX seconds
    participants: User[],
    lastUpdated: ?number, // UNIX milliseconds
    didInvalidate: boolean,
};

const defaultActivityState = {
    id: undefined,
    type: undefined,
    name: undefined,
    errorMessage: undefined,
    startTime: null,
    participants: [],
    lastUpdated: 0,
    didInvalidate: false,
};

export const activityReducer: (ActivityState, Object) => ActivityState = createReducer(
    {
        [actions.updateActivityData]: (state, payload) => {
            return {
                ...state,
                errorMessage: undefined,
                lastUpdated: new Date().getTime(),
                didInvalidate: false,
                ...payload,
            };
        },
    },
    defaultActivityState
);

export const sidebarActivitiesConfigReducer: (
    ActivitiesConfigState,
    Object
) => ActivitiesConfigState = createReducer(
    {
        [actions.updateActivityFilter]: (state, payload) => {
            return {
                ...state,
                nextUrl: undefined,
                previousUrl: undefined,
                scrolledToDate: state.displayDate,
                filter: payload,
            };
        },
        [actions.updateActivityDisplayDate]: (state, payload) => {
            return {
                ...state,
                nextUrl: undefined,
                previousUrl: undefined,
                displayDate: payload.getTime(),
                scrolledToDate: payload.getTime(),
                lastUpdated: moment().valueOf(),
            };
        },
        [actions.updateActivityListScrolledToDate]: (state, scrolledToDate) => {
            return {
                ...state,
                scrolledToDate: scrolledToDate,
            };
        },
        [actions.updateActivitiesForDay]: (state, {meta}) => {
            return {...state, nextUrl: meta.next, previousUrl: meta.prev};
        },
        [REHYDRATE]: (state, persistedState) => {
            if (
                !persistedState ||
                !persistedState.dashboard ||
                !persistedState.dashboard.activitiesConfig
            ) {
                return state;
            }

            return {
                ...state,
                ...persistedState.dashboard.activitiesConfig,
                displayDate: getRehydratedValueForDateProperty(
                    persistedState.dashboard.activitiesConfig,
                    'displayDate'
                ),
                scrolledToDate: getRehydratedValueForDateProperty(
                    persistedState.dashboard.activitiesConfig,
                    'scrolledToDate'
                ),
            };
        },
    },
    defaultActivitiesConfigState
);

export const activitiesReducer: (ActivitiesState, Object) => ActivitiesState = createReducer(
    {
        [actions.updateActivitiesForDay]: (state, {activities}) => {
            const newActivities = activities.reduce(
                (newState, current) => {
                    newState[current.id] = activityReducer(
                        state.items[current.id],
                        actions.updateActivityData(current)
                    );

                    return newState;
                },
                {...state.items}
            );

            return {...state, items: newActivities};
        },
        [actions.updateActivitiesForMonth]: (state, activities) => {
            const newActivities = activities.reduce(
                (newState, current) => {
                    newState[current.id] = activityReducer(
                        state.items[current.id],
                        actions.updateActivityData(current)
                    );

                    return newState;
                },
                {...state.items}
            );

            return {...state, items: newActivities};
        },
        [actions.updateActivityTotalOverdueCount]: (state, totalOverdueCount) => {
            return {...state, totalOverdueCount: totalOverdueCount};
        },
        [actions.updateOverdueActivities]: (state, activities) => {
            const newActivities = activities.reduce(
                (newState, current) => {
                    newState[current.id] = activityReducer(
                        state.items[current.id],
                        actions.updateActivityData(current)
                    );

                    return newState;
                },
                {...state.items}
            );

            return {...state, items: newActivities};
        },
        [actions.updateActivityDataById]: (state, payload) => {
            const newActivities = {
                ...state.items,
                [payload.id]: activityReducer(
                    state.items[payload.id],
                    actions.updateActivityData(payload)
                ),
            };

            return {...state, items: newActivities};
        },
        [actions.updateActivityFilter]: (state) => {
            return {...state, items: {}};
        },
        [REHYDRATE]: (state, persistedState) => {
            if (
                !persistedState ||
                !persistedState.dashboard ||
                !persistedState.dashboard.activities
            )
                return state;

            return {
                ...state,
                ...persistedState.dashboard.activities,
                items: {},
            };
        },
    },
    defaultActivitiesState
);
