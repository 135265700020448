/* @flow */

import * as React from 'react';

import {Body} from 'shells/typography';

type Props = {
    primaryText?: string,
    secondaryText?: string,
};

export function UsageScorecardSectionHeader(props: Props) {
    return (
        <div className='flex justify-sb py-8 px-16 background-offwhite'>
            <Body color='dark' size='small' isBold={true}>
                {props.primaryText}
            </Body>
            <Body color='dark' size='small' isBold={true}>
                {props.secondaryText}
            </Body>
        </div>
    );
}
