/* @flow */

import * as React from 'react';

import {OnboardingPage} from '../onboarding-page/onboarding-page';
import {colors} from '../colors';
import {Link} from '../link';
import {Modal} from './modal';

type Props = {
    isOpen: boolean,
    onClose: () => void,
    bodyComponent: React.Node,
    artImage: ?React.Element<any>,
    showAdditionalHelpCopy?: boolean,
};

export function HelpModal(props: Props) {
    return (
        <Modal
            isOpen={props.isOpen}
            onClose={props.onClose}
            noPadding={true}
            closeButton={false}
            preventBodyScroll={false}
            maxHeight={600}
        >
            <OnboardingPage
                type='singlePage'
                bodyComponent={props.bodyComponent}
                artBackgroundColor={colors.plumLt}
                artImage={props.artImage}
                buttonText='Done'
                onClose={props.onClose}
                bottomLeftContent={
                    props.showAdditionalHelpCopy ? (
                        <div style={{color: colors.greyLt, fontSize: 13}}>
                            For more help,{' '}
                            <Link
                                href='https://support.nutshell.com/en/'
                                newTab={true}
                                variant='primary'
                                size='small'
                            >
                                contact our team
                            </Link>
                        </div>
                    ) : undefined
                }
            />
        </Modal>
    );
}
