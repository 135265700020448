/* @flow */

import * as React from 'react';
import Select, {
    mergeStyles,
    components,
    type SelectBaseProps,
    type ControlProps,
    Async,
} from 'react-select';

import {colors} from '../colors';

import {getCustomMenuList} from './custom-menu-list';
import type {ActionMeta} from './select-picker-generic-creatable';
import {selectPickerGenericStyles} from './select-picker-generic-styles';
import {getNoOptionsMessage} from './no-options-message';

export type NoOptionsMessageProps = {|
    icon?: React.Node,
    text: React.Node | string,
    cta?: React.Node,
|};

export type FooterButtonProps = {
    label: string,
    href?: string,
    onClick?: () => void,
    icon?: React.ComponentType<*>,
};

export type Props = {|
    // We really want to use ElementConfig, but it wasn't added until flow 0.63,
    // so instead we made a custom lib-def with default props made optional
    ...$Exact<SelectBaseProps>,
    /** Fades the option to green when true */
    blinkSuccess?: boolean,
    /** Not allowing multiple selections in this component */
    isMulti?: false,
    /** Not allowing creation in this component */
    isCreatable?: false,
    /** Provide an `onClear` to make this clearable */
    isClearable: false,
    valueKey: string,
    labelKey: string,
    /** Simplified / abstracted version of react-select's onChange method.  This only gets called with an object. */
    onChange: (newValue: Object, action: ActionMeta) => void,
    /** Added for simplicity, components usually need to handle clearing differently from changes */
    onClear?: () => void,
    hasError?: ?boolean,
    getRef?: React.Ref<*>,
    /** This signifies an Async, multi-select */
    loadOptions?: (query: string) => Promise<Object[]>,
    isDark?: boolean,
    controlIcon?: React.ComponentType<*>,
    hideDividerWhenEmpty?: boolean,
    minHeight?: number,
    customNoOptionsMessage?: NoOptionsMessageProps,
    footerButton?: FooterButtonProps,
|};

/**
 * Thin wrapper around react-select.  Takes all the standard react-select props.
 *
 * Only for single value, non-creatable pickers
 */
export class SelectPickerGeneric extends React.Component<Props> {
    static defaultProps = {
        valueKey: 'value',
        labelKey: 'label',
        isClearable: false,
    };

    render() {
        const {
            valueKey,
            labelKey,
            // eslint-disable-next-line no-unused-vars
            onChange,
            styles,
            getRef,
            loadOptions,
            customNoOptionsMessage,
            footerButton,
            ...restProps
        } = this.props;
        const componentStyles = {
            control: (base, state) => {
                let controlStyles = {
                    ...base,
                    backgroundColor: this.props.isDark ? colors.offWhiteDk : colors.white,
                    border: this.props.hasError
                        ? `1px solid ${colors.rose}`
                        : `1px solid ${colors.offWhiteDk}`,
                    minHeight: this.props.minHeight, // undefined will be default
                };

                if (state.isDisabled || this.props.isDisabled) {
                    controlStyles = {
                        ...controlStyles,
                        color: 'var(--grey-lt)',
                        backgroundColor: 'var(--off-white)',
                        opacity: 0.6,
                    };
                }

                return controlStyles;
            },
            placeholder: (base, state) => ({
                ...base,
                color: this.props.blinkSuccess && !state.isDisabled ? colors.green : base.color,
                transition: 'color 200ms linear',
            }),
            menu: (base) => ({
                ...base,
                zIndex: '5',
            }),
            // If we have the footer button, we need to adjust the padding of the menu list to make room for it otherwise the bottom padding messes up the scroll
            ...(footerButton && {
                menuList: (base) => ({
                    ...base,
                    paddingBottom: 0,
                }),
            }),
            singleValue: (base, state) => ({
                ...base,
                color: this.props.blinkSuccess && !state.isDisabled ? colors.green : colors.grey,
                transition: 'color 200ms linear',
            }),
            indicatorSeparator: (base, state) => {
                let display;

                if (
                    !this.props.onClear ||
                    (this.props.onClear &&
                        state.selectProps.hideDividerWhenEmpty &&
                        !state.selectProps.value)
                ) {
                    display = 'none';
                }

                return {
                    ...base,
                    display,
                };
            },
            dropdownIndicator: (base, state) => {
                return {
                    ...base,
                    display: state.isDisabled ? 'none' : 'flex',
                };
            },
        };
        let mergedStyles = mergeStyles(selectPickerGenericStyles, componentStyles);
        // Add in styles from props
        if (styles) {
            mergedStyles = mergeStyles(mergedStyles, styles);
        }

        const Component = loadOptions ? Async : Select;

        return (
            <Component
                {...restProps}
                ref={getRef}
                loadOptions={loadOptions}
                defaultOptions={true}
                cacheOptions={null}
                styles={mergedStyles}
                isMulti={false}
                isClearable={Boolean(this.props.onClear)}
                onChange={this.handleChange}
                getOptionLabel={(option) => option[labelKey]}
                getOptionValue={(option) => option[valueKey]}
                placeholder={this.props.placeholder}
                components={{
                    Control,
                    NoOptionsMessage: getNoOptionsMessage(customNoOptionsMessage),
                    MenuList: getCustomMenuList(footerButton),
                    ...(this.props.components ? this.props.components : {}),
                }}
            />
        );
    }

    handleChange: $PropertyType<$PropertyType<Select, 'props'>, 'onChange'> = (
        newValue,
        actionMeta
    ) => {
        // Should not happen because we are not using multi select, but just to be safe:
        if (Array.isArray(newValue)) {
            // There's a bug, where backspacing to clear sends an array.
            // https://github.com/JedWatson/react-select/pull/3216 should fix it
            if (newValue.length === 0) {
                if (this.props.onClear) {
                    this.props.onClear();
                }

                return;
            }
            throw new Error(
                `Somehow got an array value in an onChange (${actionMeta.action}): ${JSON.stringify(
                    newValue
                )}`
            );
        }

        if (actionMeta.action === 'clear' || newValue === null || typeof newValue === 'undefined') {
            if (this.props.onClear) {
                this.props.onClear();
            }
        } else {
            this.props.onChange(newValue, actionMeta);
        }
    };
}

const Control = ({children, ...props}: {...ControlProps}) => (
    <components.Control {...props}>
        {props.selectProps.controlIcon ? (
            <div
                style={{
                    fill: colors.grey,
                    color: colors.grey,
                    marginLeft: 12,
                    display: 'flex',
                }}
            >
                <props.selectProps.controlIcon color={colors.grey} size={15} wrapWithDiv={false} />
            </div>
        ) : undefined}
        {children}
    </components.Control>
);
