/* @flow */

import * as React from 'react';
import classnames from 'classnames';
import {CloseIcon} from '../icon';
import './message.css';

export type MessageType = 'default' | 'error' | 'info' | 'success' | 'warning';

type Props = {|
    type: MessageType,
    children: React.Node,
    /** Callback function for the close button */
    onClose?: () => void,
    /** If true, the message will be rendered with a border and corner radius. */
    hasBorder?: boolean,
    /** If true, the content will be aligned to the left edge, instead of centered */
    leftAlignContent?: boolean,
    cta?: React.Node,
    noRightPadding?: boolean,
|};

export class Message extends React.Component<Props> {
    static defaultProps = {
        type: 'default',
    };

    render() {
        const classes = classnames(`container--${this.props.type}`, {
            'container--bordered': this.props.hasBorder,
            'container--left-aligned-content': this.props.leftAlignContent,
            'has-cta': Boolean(this.props.cta),
        });

        return (
            <div styleName={classes}>
                <div
                    styleName='message'
                    style={this.props.noRightPadding ? {paddingRight: 0} : undefined}
                >
                    {this.props.children}
                </div>
                {this.props.onClose ? (
                    <button
                        styleName='button-close'
                        onClick={this.props.onClose}
                        // This is important because we dont want to
                        // submit forms if this component is nested in one.
                        type='button'
                    >
                        <CloseIcon size={14} wrapWithDiv={false} />
                    </button>
                ) : undefined}
                {this.props.cta}
            </div>
        );
    }
}
