/* @flow */

import * as React from 'react';

import {useGetTeams} from '../../../../../../../ui/core/graphql/hooks';
import {CheckmarkButton, CancelButton} from '../../../button';
import {RichTextarea, getMentionsConfig} from '../../../rich-textarea';
import {isRichTextareaEmpty} from '../../../rich-textarea/helpers';

import './add-a-comment.css';

type Props = {|
    onAddComment: (value: string) => Promise<*>,
    cancelAddingComment?: () => void,
    isAddingComment?: boolean,
|};

export function AddAComment(props: Props) {
    // The value to save to the database, without the rendered HTML @mention content
    const [commentPlaintextValue, setCommentPlaintextValue] = React.useState<string>('');
    // The HTML value of the comment
    const [commentHtmlValue, setCommentHtmlValue] = React.useState<string>('');

    const isCommentEmpty = isRichTextareaEmpty(commentHtmlValue, commentPlaintextValue);

    const onAddComment = () => {
        if (!isCommentEmpty) {
            props.onAddComment(commentPlaintextValue);
            setCommentHtmlValue('');
            setCommentPlaintextValue('');
        }
    };

    const onCancelComment = () => {
        if (props.cancelAddingComment) {
            props.cancelAddingComment();
        }
        setCommentHtmlValue('');
        setCommentPlaintextValue('');
    };

    const handleChange = (newValue, textValue) => {
        setCommentHtmlValue(newValue);
        setCommentPlaintextValue(textValue);
    };

    const {teams} = useGetTeams();

    return (
        <form styleName='add-a-comment'>
            <div styleName='textarea'>
                <RichTextarea
                    id='timeline-comment'
                    isFullHeight={true}
                    overflowVisible={true}
                    placeholder='Write a comment…'
                    value={commentHtmlValue}
                    config={{
                        modules: {
                            toolbar: false,
                        },
                        formats: ['bold', 'italic', 'mention'],
                        images: false,
                        formatLinks: true,
                        useDivs: false,
                        placeholders: false,
                        mention: getMentionsConfig(),
                    }}
                    forceFocus={props.isAddingComment}
                    onBlur={() => {
                        // If comment is empty and user clicks off input, treat as cancelled comment
                        if (isCommentEmpty) {
                            onCancelComment();
                        }
                    }}
                    onEnter={() => {
                        onAddComment();
                    }}
                    onChange={handleChange}
                    teams={teams}
                />
            </div>
            {commentPlaintextValue.length ? (
                <div styleName='button-container'>
                    <CheckmarkButton onClick={onAddComment} isDisabled={isCommentEmpty} />
                    <CancelButton onClick={onCancelComment} />
                </div>
            ) : undefined}
        </form>
    );
}
