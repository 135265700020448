/* @flow */

import * as React from 'react';
import {useQuery} from '@apollo/react-hooks';

import type {
    GetHtmlContentForEntry as GetHtmlContentForEntryQuery,
    GetHtmlContentForEntryVariables as GetHtmlContentForEntryQueryVariables,
} from 'nutshell-graphql-types';
import {makeDataSafe} from 'nutshell-core/utils/graphql-errors';

import {ExpandedTimelineEntryContent} from 'shells/timeline/entry/content/expanded-timeline-entry-content';

import GET_HTML_CONTENT from '../graphql/queries/get-html-content-for-entry.graphql';

import './timeline-entry-content.css';

type TimelineEntryExpandedContentWrapperProps = {
    changeLogEntryId: string,
    content: ?(string | React.Node),
    htmlContent?: string,
    maxHeight?: number,
};

const MAX_EXPANDED_CONTENT_HEIGHT = 400;

/**
 * This component is a wrapper for the timeline entry expanded content
 * (i.e. htmlBody of an email or note). We may have the htmlContent passed in
 * if this is used in a modal or not if it is a normal timeline entry. We fetch
 * the htmlContent if we don't have it and then render it using
 * ExpandedTimelineEntryContent
 */
export function TimelineEntryExpandedContentWrapper(
    props: TimelineEntryExpandedContentWrapperProps
) {
    const {data, loading: isLoading} = useQuery<
        GetHtmlContentForEntryQuery,
        GetHtmlContentForEntryQueryVariables,
    >(GET_HTML_CONTENT, {
        variables: {id: props.changeLogEntryId},
        skip: Boolean(props.htmlContent),
        notifyOnNetworkStatusChange: true,
    });

    const {event} = makeDataSafe(data);

    let htmlContent = props.htmlContent;
    let content = props.content; // content may just be passed in

    if (event && event.payload.__typename === 'Email') {
        htmlContent = event.payload.emailBodyHtml;
    }

    if (event && event.payload.__typename === 'Note') {
        // The bodyHtml for notes is special and needs to be rendered separately so
        // that @-mentions, links, and other quill things show up properly.
        content = (
            <div
                styleName='entry-html-body'
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    __html: event.payload.noteBodyHtml,
                }}
            />
        );
    }

    if (event && event.payload.__typename === 'EmailQueued') {
        htmlContent = event.payload.bodyHtml;
    }

    if (event && event.payload.__typename === 'EmailInteraction') {
        htmlContent =
            event.payload.emailDelivery && event.payload.emailDelivery.email
                ? event.payload.emailDelivery.email.bodyHtml
                : undefined;
    }

    return (
        <ExpandedTimelineEntryContent
            isLoading={isLoading}
            // For some events, like event.payload.__typename === 'Chat' - there is no htmlContent
            content={content}
            htmlContent={htmlContent}
            maxHeight={props.maxHeight || MAX_EXPANDED_CONTENT_HEIGHT}
        />
    );
}
