/* @flow */

import * as React from 'react';
import * as ramda from 'ramda';
import moment from 'moment';
import type Moment from 'moment';
import {handleFilterUpdate} from 'nutshell-core/filter';
import type {FilterObject} from 'nutshell-core/types';
import {getApiTypeFromGraphQLTypename} from 'nutshell-core/utils';

import type {
    SessionUser,
    EventFragmentSparse,
    EventFragmentSparse_actor as Actor,
    EventFragmentSparse_payload as Payload,
    NoteFragment_entity as Entity,
    SubmissionFragment_formVersion_form as Form,
    EmailInteractionFragmentSparse as EmailInteraction,
    EmailQueuedFragmentSparse as EmailQueued,
    EmailFragmentSparse as Email,
    EventFragmentSparse_actor as EmailActor,
    EventFragmentSparse_payload_Session_url as Url,
    EventFragmentSparse_payload_Session_browser as Browser,
    EventFragmentSparse_payload_Email_recipients as EmailRecipient,
    EventFragmentSparse_payload_Email_from as EmailSender,
    EventFragmentSparse_payload_Chat_participants as ChatParticipants,
    EventFragmentFull_payload_Chat_relatedLeads as ChatRelatedLeads,
    EventFragmentFull_payload_Email_emailRelatedLeads as RelatedLeads,
} from 'nutshell-graphql-types';

import {ReplyAllIcon, ReplyIcon, ExpandIcon, CollapseIcon} from 'shells/icon';
import {Link} from 'shells/link';
import type {Participant, EventType, TimelineEntryStatus, DeviceType} from 'shells/timeline/types';
import type {TimelineEntryIconVariant} from 'shells/timeline/gutter/types';
import type {ToolbarOption} from 'shells/timeline/entry/toolbar/timeline-entry-toolbar';

import {getLeadBanner} from './banner';
import type {EventForTimeline} from './types';

/**
 * This function returns a list of filtered events to display in the timeline, or undefined
 * if events have not been loaded yet.
 *
 * @param unfilteredEvents
 * @param forDashboard
 * @returns {EventForTimeline[]|undefined}
 */
export const getEventsForTimeline = (
    unfilteredEvents: ?(EventFragmentSparse[]),
    forDashboard?: boolean,
    currentUser: ?SessionUser,
    filteredEventIds?: string[]
): ?(EventForTimeline[]) => {
    if (!unfilteredEvents || !currentUser) {
        return undefined;
    }

    if (unfilteredEvents && !unfilteredEvents.length) {
        return [];
    }

    // First, we use a reducer to combine certain events
    let combinedEvents = combineEventsReducer(unfilteredEvents, timelineEventsCombiner);

    // Next, we combine and filter comment events
    combinedEvents = combineAndFilterCommentEvents(combinedEvents, currentUser.id);

    // Next, we update certain events
    combinedEvents = combinedEvents.map((event) => timelineEventsUpdater(event));

    // Next, we run events through a filter and remove any that do not need to be displayed
    return filterEvents(combinedEvents, currentUser, forDashboard, filteredEventIds);
};

/**
 * This filtering function will take an array of events and filter out any that we do not
 * want to display in the timeline.
 *
 * @param events
 * @param forDashboard
 * @returns {EventForTimeline[]}
 */
export const filterEvents = (
    events: EventForTimeline[],
    currentUser: SessionUser,
    forDashboard?: boolean,
    filteredEventIds?: string[]
): EventForTimeline[] => {
    return events.filter((event) => {
        // Filter out missing payload events or events that have already been filtered out
        if (
            event.payload.__typename === 'TimelineEventPayloadUnknownType' ||
            (filteredEventIds && filteredEventIds.includes(event.id))
        ) {
            return false;
        }

        // Filter certain activity events
        if (event.payload.__typename === 'Activity') {
            let isLoggedActivity;
            event.changes.forEach((change) => {
                if (
                    change.attribute === 'status' &&
                    change.oldValue === '0' &&
                    change.newValue === '1'
                ) {
                    isLoggedActivity = true;
                }
            });

            if (
                (event.changeType === 'ADDED' && !forDashboard) ||
                // Only want to display 'logged' activities events
                (event.changeType === 'EDITED' && !isLoggedActivity)
            ) {
                return false;
            }
        }

        // Filter certain Lead events
        if (
            event.payload.__typename === 'Lead' &&
            !getLeadBanner({changeType: event.changeType, event, currentUser})
        ) {
            return false;
        }

        // All other events are not filtered out
        return true;
    });
};

/**
 * This reducer will take the events array and a combiner.
 *
 * If the combiner returns an event, will push that event onto the new array, and skip pushing
 * the next event. Otherwise, it'll push the current object onto the new array, unmodified.
 *
 * @param eventArray
 * @param combiner
 * @returns {EventForTimeline[]}
 */
export const combineEventsReducer = (
    eventArray: EventFragmentSparse[],
    combiner: (currentEvent: EventFragmentSparse, nextEvent: EventFragmentSparse) => any
): EventForTimeline[] => {
    let justCombined = false;

    return eventArray.reduce((newEventArray, currentEvent, currentIndex, seedArray) => {
        const combinedEvent = combiner(currentEvent, seedArray[currentIndex + 1]);

        if (!justCombined) {
            // $FlowIgnore
            newEventArray.push(combinedEvent ? combinedEvent : currentEvent);
        }
        justCombined = Boolean(combinedEvent);

        return newEventArray;
    }, []);
};

/**
 * This is the combiner for the combineEventsReducer. We pass the current and next events
 * and return either an event or nothing to be pushed onto the event array.
 *
 * @param currentEvent
 * @param nextEvent
 * @returns {EventFragmentSparse}
 */
export const timelineEventsCombiner = (
    currentEvent: EventFragmentSparse,
    nextEvent: EventFragmentSparse
): ?EventForTimeline => {
    // Combine a reassignment event (including DELETE and ADD events) into one displayable timeline event
    if (
        nextEvent &&
        nextEvent.payload.__typename === 'Assignment' &&
        nextEvent.changeType === 'DELETED' &&
        nextEvent.changeTime === currentEvent.changeTime
    ) {
        const updatedEvent = currentEvent;
        // $FlowIgnore
        updatedEvent.previousAssignee = nextEvent.payload.assigneeEntity;

        // $FlowIgnore
        return updatedEvent;
    }

    // Remove a queued email event once the real email event exists
    if (
        nextEvent &&
        nextEvent.payload.__typename === 'EmailQueued' &&
        currentEvent.payload.__typename === 'Email' &&
        nextEvent.payload.subject === currentEvent.payload.subject
    ) {
        // $FlowIgnore
        return currentEvent;
    }
};

const timelineEventsUpdater = (event: EventFragmentSparse): EventForTimeline => {
    if (event.payload.__typename === 'Thread') {
        const isReopenEvent = event.changes.find(
            (change) => change.attribute === 'status' && change.newValue === 'open'
        );

        if (isReopenEvent) {
            const updatedEvent = event;
            // $FlowIgnore this is a manually-added changeType
            updatedEvent.changeType = 'REOPENED';

            // $FlowIgnore
            return updatedEvent;
        } else {
            // $FlowIgnore
            return event;
        }
    }

    // $FlowIgnore
    return event;
};

export function getSessionDeviceTypeFromSessionBrowser(browser: ?Browser): DeviceType {
    if (browser) {
        switch (browser.deviceType) {
            case 'DESKTOP':
                return 'desktop';
            case 'TABLET':
                return 'tablet';
            case 'MOBILE':
            case 'UNKNOWN':
            case 'WEARABLE':
            default:
                return 'mobile';
        }
    }

    // Default to mobile
    return 'mobile';
}

export function getActorForSession(payload: Payload, origin: Actor) {
    if (payload.__typename === 'Session' && payload.contact && payload.contact.nutshellContact) {
        return payload.contact.nutshellContact;
    }

    return origin;
}

export function getEntryTypeFromPayload(payload: Payload): EventType {
    if (payload.__typename === 'Email') {
        if (payload.isFailed) {
            return 'FailedEmail';
        } else if (payload.isTicketMessage) {
            return 'TicketMessage';
        } else if (payload.isAutomatedReply) {
            return 'AutomatedReply';
        } else if (payload.isEmailAutomation) {
            return 'AutomatedEmail';
        } else if (payload.isNutshellMarketingEmail) {
            return 'NutshellMarketingEmail';
        } else {
            return 'Email';
        }
    }

    if (payload.__typename === 'EmailQueued') {
        if (payload.isFailed) {
            return 'FailedEmail';
        }

        return 'Email';
    }

    return payload.__typename;
}

/**
 * This function parses through each event to ensure that only one comment event is displayed for a
 * specific Commentable entity.
 *
 * The currentUser may be included in the list of additionalActors for a comment event, but comments
 * left by the currentUser are not treated as top-level events and the currentUser will never be the
 * main actor.
 *
 * @param events
 * @param currentUserId
 * @returns {EventForTimeline[]}
 */
export const combineAndFilterCommentEvents = (
    events: EventForTimeline[],
    currentUserId: string
): EventForTimeline[] => {
    const updatedEvents: EventForTimeline[] = [];

    events.forEach((event) => {
        if (event.payload.__typename === 'Comment') {
            // Destruct payload for ease of using Flow
            const parentEntity =
                !event.payload.deletedTime && // Excludes deleted comments
                event.payload.parentEntity &&
                event.payload.parentEntity.__typename !== 'Form' &&
                event.payload.parentEntity.id
                    ? event.payload.parentEntity
                    : undefined;

            if (parentEntity) {
                // Check to see if a comment event for the same parentEntity exists already
                const existingCommentEventIndex = getIndexOfCommentEvent(
                    updatedEvents,
                    parentEntity.id
                );

                if (existingCommentEventIndex !== -1) {
                    const existingEvent = updatedEvents[existingCommentEventIndex];

                    // Check to see if the commenter is already represented in the existing event
                    if (!isCommenterActorOrAdditionalActor(event.actor, existingEvent)) {
                        // If the event already has an additionalActors array, push to it
                        if (updatedEvents[existingCommentEventIndex].additionalActors) {
                            updatedEvents[existingCommentEventIndex].additionalActors.push(
                                event.actor
                            );
                        } else {
                            // Create additionalActors array with current actor
                            updatedEvents[existingCommentEventIndex].additionalActors = [
                                event.actor,
                            ];
                        }
                    }
                } else if (event.actor.id !== currentUserId) {
                    // If there is not already a comment event, and the commenter is not the
                    // current user, then we'll add it as an event
                    updatedEvents.push(event);
                }
            }
        } else {
            // Push non-comment events to updated events array
            updatedEvents.push(event);
        }
    });

    return updatedEvents;
};

/**
 * This function searches the list of timeline events and returns the index of a comment event
 * for the provided parentEntityId, or -1 if none exists.
 *
 * @param events
 * @param parentEntityId
 * @returns {number}
 */
export const getIndexOfCommentEvent = (
    events: EventForTimeline[],
    parentEntityId: string
): number => {
    return events.findIndex(
        (event) =>
            event.payload.__typename === 'Comment' &&
            event.payload.parentEntity &&
            event.payload.parentEntity.id === parentEntityId
    );
};

/**
 * This function returns true if the commenter is already included in a timeline event as the main actor,
 * or within the list of additionalActors.
 *
 * @param commenter
 * @param event
 * @returns {boolean}
 */
export const isCommenterActorOrAdditionalActor = (
    commenter: Actor,
    event: EventForTimeline
): boolean => {
    if (commenter.id && event.actor.id) {
        const commenterId = commenter.id;

        // If the event has additionalActors, check these first
        if (
            event.additionalActors &&
            event.additionalActors.find(
                (actor) => actor.__typename !== 'Form' && actor.id === commenterId
            )
        ) {
            return true;
        }

        // If commenter is not in additionalActors, check if they are the actor
        return event.actor.id === commenterId;
    }

    return false;
};

/**
 * Returns an Actor as a Participant to be displayed as an avatar if it can be, otherwise returns undefined.
 *
 * @param actor
 * @returns ?Participant
 */
export function getActorAsParticipant(actor: ?Actor): ?Participant {
    if (!actor || actor.__typename === 'Form') {
        // We do not have a participant type that covers this
        return;
    }

    return {
        type: getApiTypeFromGraphQLTypename(actor.__typename),
        id: actor.id,
        name: actor.name,
        avatarUrl: typeof actor.avatarUrl === 'string' ? actor.avatarUrl : undefined,
        initials: typeof actor.initials === 'string' ? actor.initials : undefined,
        htmlUrl: typeof actor.htmlUrl === 'string' ? actor.htmlUrl : undefined,
    };
}

/**
 * Returns an Actor as a Participant to be displayed as an avatar if it can be, otherwise returns undefined.
 *
 * @param entity
 * @returns ?Participant
 */
export function getEntityAsParticipant(entity: ?Entity | Form): ?Participant {
    if (!entity || entity.__typename === 'Form' || entity.__typename === 'NoteEntityUnknownType') {
        // We do not have a participant type that covers this
        return;
    }

    return {
        type: getApiTypeFromGraphQLTypename(entity.__typename),
        id: entity.id,
        name: entity.name,
        avatarUrl: typeof entity.avatarUrl === 'string' ? entity.avatarUrl : undefined,
        initials: typeof entity.initials === 'string' ? entity.initials : undefined,
        htmlUrl: typeof entity.htmlUrl === 'string' ? entity.htmlUrl : undefined,
    };
}

/**
 * Returns an array of participants to be shown in an avatar list which includes the actor and/or entity.
 *
 * @param participants
 * @param actor
 * @param ?entity
 * @returns ?Participant[]
 */
export function getParticipantsForAvatarList(
    participants: ?(Participant[]),
    actor: Actor,
    entity: ?Entity | Form | EmailInteraction | Email | Url
): ?(Participant[]) {
    // If there are no other participants or entity OR it is an email interaction - we don't show anything
    if (
        (!participants && !entity) ||
        (entity &&
            (entity.__typename === 'EmailInteraction' ||
                entity.__typename === 'Email' ||
                entity.__typename === 'Url'))
    ) {
        return undefined;
    }

    const participantsForAvatarList: Participant[] = [];

    // Add list of participants to array if those exist
    if (participants) {
        participantsForAvatarList.push(...participants);
    }

    // Get entity as a participant
    const entityAsParticipant = getEntityAsParticipant(entity);
    if (
        entityAsParticipant &&
        !participantsForAvatarList.find((participant) => participant.id === entityAsParticipant.id)
    ) {
        // Add entity to participants list if it does not exist already
        participantsForAvatarList.unshift(entityAsParticipant);
    }

    // Get actor as a participant
    const actorAsParticipant = getActorAsParticipant(actor);

    if (
        actorAsParticipant &&
        !participantsForAvatarList.find((participant) => participant.id === actorAsParticipant.id)
    ) {
        // Add actor to participants list if it does not exist already
        participantsForAvatarList.unshift(actorAsParticipant);
    }

    return participantsForAvatarList;
}

export const updateFilters = (
    filterObject: FilterObject,
    filterName: string,
    activeFilters: FilterObject[]
): FilterObject[] => {
    // Get the current filter from our array of active filters
    const getExistingFilter = (name) => {
        return ramda.find(ramda.has(name))(activeFilters);
    };

    // When the activityType filter is set, we clear out any existing entryType filter
    // and replace with 'activities' so we only show the selected activity types. This
    // ensures the filter capsules match what is displayed in the timeline
    if (filterName === 'activityType') {
        const existingActivityTypeFilter = getExistingFilter('activityType');
        if (!filterObject.activityType || existingActivityTypeFilter) {
            if (existingActivityTypeFilter) {
                return handleFilterUpdate(activeFilters, {
                    oldFilter: existingActivityTypeFilter,
                    newFilter: filterObject,
                });
            }
        } else {
            const existingPayloadFilter = getExistingFilter('payload');
            const newEntryFilter = {
                payload: {none: undefined, anyAll: 'any', data: [{data: 'activities'}]},
            };
            if (existingPayloadFilter) {
                const updatedFilters = handleFilterUpdate(activeFilters, {
                    oldFilter: existingPayloadFilter,
                    newFilter: newEntryFilter,
                });

                return [...updatedFilters, filterObject];
            } else {
                return [...activeFilters, newEntryFilter, filterObject];
            }
        }
    } else {
        const oldFilter = getExistingFilter(filterName);
        if (oldFilter) {
            return handleFilterUpdate(activeFilters, {
                oldFilter,
                newFilter: filterObject,
            });
        }
    }

    return [...activeFilters, filterObject];
};

export const getDateOptions = (createdTime: string): any[] => {
    const createdYear = moment(parseInt(createdTime) * 1000).year();
    const currentYear = moment().year();
    const allTimeFilter = getAllTimeFilter(createdTime);
    // We add 1 to the createdTime here just so the All time filter and the current
    // year filter can coexist in the list without erroring because they have the same id.
    const createdThisYearFilter = getAllTimeFilter(createdTime + 1);
    const dateOptions = [
        {
            id: allTimeFilter,
            value: 'All time',
        },
    ];

    const startOfCurrentYear = moment(`${currentYear}-01-02`).startOf('year').unix();

    const endOfCreatedYear = moment(`${createdYear}-01-02`).utc().endOf('year').unix();

    // This is the filter for the current year: Jan 1 - current date
    const currentYearFilter = `${startOfCurrentYear} TO ${moment().unix()}`;

    // This is the filter for the created year: created date - Dec 31
    const createdYearFilter = `${createdTime} TO ${endOfCreatedYear}`;

    // For entities created in the current year, we want to use the all time filter
    // to disable dates before it was created or after the current date
    if (createdYear === currentYear) {
        dateOptions.push({
            id: createdThisYearFilter,
            value: `${currentYear}`,
        });
    } else {
        // Current year
        dateOptions.push({
            id: currentYearFilter,
            value: `${currentYear}`,
        });

        // Years between current year and created year
        for (let i = currentYear - 1; i > createdYear; i--) {
            dateOptions.push({
                id: `${i}`,
                value: `${i}`,
            });
        }

        // Created year
        dateOptions.push({
            id: createdYearFilter,
            value: `${createdYear}`,
        });
    }

    return dateOptions.concat({id: 'custom', value: 'Custom'});
};

export const getAllTimeFilter = (createdTime: string): string => {
    const startDate = createdTime;
    const endDate = moment().endOf('day').unix();

    return `${startDate} TO ${endDate}`;
};

export const getMinDate = (createdTime: string): Moment => {
    return moment(Number(createdTime) * 1000);
};

export const getEmailIconVariant = (email: Email | EmailQueued): TimelineEntryIconVariant => {
    if (email.isTicketMessage) {
        return 'ticket';
    } else if (email.isAutomatedReply || email.isEmailAutomation) {
        return 'automated-email';
    } else if (email.isInbound) {
        return 'inbound-email';
    } else {
        return 'outbound-email';
    }
};

// For chats, the first user participant is considered the actor. In the case
// of no user participants (shouldn’t ever happen) we'll just return the
// first participant. If for some reason there aren't particants, try and return
// an actor directly from the event itself.
export function getActorForChat(participants: Object[], actor: Actor) {
    const firstUser = participants.find((participant) => {
        return participant.__typename === 'User';
    });

    return firstUser || participants[0] || actor;
}

export function getParticipantsForChat(participants: ChatParticipants[]): any {
    return participants
        .filter((participant) => participant.__typename !== 'User')
        .map((participant) => ({
            type: getApiTypeFromGraphQLTypename(participant.__typename),
            id: participant.id,
            name: participant.name,
            avatarUrl: participant.avatarUrl,
            initials: typeof participant.initials === 'string' ? participant.initials : undefined,
            htmlUrl: typeof participant.htmlUrl === 'string' ? participant.htmlUrl : undefined,
        }));
}

export function getRelatedLeadsForChat(relatedLeads: ChatRelatedLeads[]): any {
    return relatedLeads.map((lead) => ({
        type: 'leads',
        id: lead.id,
        name: lead.name,
        avatarUrl: lead.avatarUrl,
        number: lead.number,
        status: lead.status,
        priority: lead.priority,
        htmlUrl: lead.htmlUrl,
        relatedPerson: lead.contacts.primaryEdge
            ? {
                  type: 'contacts',
                  id: lead.contacts.primaryEdge.node.id,
                  name: lead.contacts.primaryEdge.node.name,
                  htmlUrl: lead.contacts.primaryEdge.node.htmlUrl,
              }
            : undefined,
        relatedCompany: lead.accounts.primaryEdge
            ? {
                  type: 'accounts',
                  id: lead.accounts.primaryEdge.node.id,
                  name: lead.accounts.primaryEdge.node.name,
                  htmlUrl: lead.accounts.primaryEdge.node.htmlUrl,
              }
            : undefined,
    }));
}

// Emails can be sent from a contact, user, etc but also just from an email address.
// In this case, we need to map the email address to a format that will work with
// our Actor type, having at least an id, name, and __typename
export const getActorForEmail = (sender: EmailSender): EmailActor => {
    const entity = sender.avatarEntity
        ? sender.avatarEntity
        : {
              id: sender.address,
              name: sender.display,
              __typename: 'Origin',
          };

    // $FlowIgnore, see explanation above
    return entity;
};

// Emails can be sent to a contact, user, etc but also just to an email address.
// In this case, we need to map the email address to a format that will work with
// our Participant type, having at least an id, name, and type
export const getParticipantsForEmail = (
    recipients: EmailRecipient[],
    relatedLeads?: RelatedLeads
): Participant[] => {
    const participants = recipients.map((addressee) => {
        const entity = addressee.avatarEntity
            ? {
                  ...addressee.avatarEntity,
                  type: getApiTypeFromGraphQLTypename(addressee.avatarEntity.__typename),
                  emailAddress: addressee.display,
              }
            : {
                  id: addressee.address,
                  name: addressee.display,
                  type: 'emailAddress',
              };

        return entity;
    });

    if (relatedLeads && relatedLeads.edges) {
        relatedLeads.edges.forEach((relatedLead) => {
            if (relatedLead.isMapped) {
                participants.push({
                    type: 'leads',
                    id: relatedLead.node.id,
                    name: relatedLead.node.name,
                    htmlUrl: relatedLead.node.htmlUrl,
                });
            }
        });
    }

    return participants;
};

export const getStatusForEmail = (
    email: Email,
    onRetryFailedEmail: (string) => Promise<*>,
    onUpdateEmail: (string, boolean) => Promise<*>
): TimelineEntryStatus => {
    const status = {};
    if (email.isFailed) {
        status.primary = {level: 'alert', text: 'Failed'};
        status.action = {
            buttonText: 'Retry',
            onClick: (e: SyntheticEvent<*>) => {
                e.stopPropagation(); // Prevent expand action on timeline entry card
                onRetryFailedEmail(email.id);
            },
        };
    } else if (email.isTicketMessage) {
        status.primary = {level: 'info--purple', text: 'Ticket'};
    } else if (email.isInbound) {
        status.primary = {level: 'info', text: 'Inbound email'};
    } else {
        status.primary = {level: 'info', text: 'Outbound email'};
    }

    if (!email.isShared) {
        status.secondary = {level: 'info--grey', text: 'Private'};

        if (email.isShareable) {
            status.action = {
                buttonText: 'Share with everyone',
                onClick: (e: SyntheticEvent<*>) => {
                    e.stopPropagation(); // Prevent expand action on timeline entry card
                    onUpdateEmail(email.id, true);
                },
            };
        }
    }

    return status;
};

export const getSubjectForEmail = (email: Email | EmailQueued, isModal?: boolean) => {
    if (
        email.isTicketMessage &&
        email.ticketId &&
        typeof email.ticketId === 'string' &&
        email.ticketUrl &&
        typeof email.ticketUrl === 'string'
    ) {
        const linkContent = `Zendesk #${email.ticketId}`;

        return (
            <div className='flex'>
                <Link
                    title={linkContent}
                    href={email.ticketUrl}
                    newTab={true}
                    onClick={(e) => e.stopPropagation()}
                >
                    <span style={{whiteSpace: 'nowrap'}}>{linkContent}</span>
                </Link>
                &nbsp;
                <span className={isModal ? '' : 'truncate'}>{email.subject}</span>
            </div>
        );
    } else {
        return email.subject;
    }
};

export const getAdditionalToolbarOptionsForEmail = (
    email: Email,
    options: {
        onReply: () => void,
        onReplyAll: () => void,
        expandable?: {
            isExpanded: boolean,
            setIsExpanded: (boolean) => void,
        },
        relatedLeads?: {
            toolbarButton: React.Node,
        },
    }
) => {
    const toolbarOptions: ToolbarOption[] = [];

    if (email.isReplyable) {
        toolbarOptions.push({
            id: 'reply',
            icon: ReplyIcon,
            onClick: options.onReply,
            tooltipText: 'Reply',
        });
    }
    if (email.isReplyable && email.recipients.length > 1) {
        toolbarOptions.push({
            id: 'reply-all',
            icon: ReplyAllIcon,
            onClick: options.onReplyAll,
            tooltipText: 'Reply all',
        });
    }
    if (options.expandable) {
        const {setIsExpanded} = options.expandable;
        toolbarOptions.push(
            options.expandable.isExpanded
                ? {
                      id: 'collapse',
                      icon: CollapseIcon,
                      onClick: () => setIsExpanded(false),
                      tooltipText: 'Collapse',
                  }
                : {
                      id: 'expand',
                      icon: ExpandIcon,
                      onClick: () => setIsExpanded(true),
                      tooltipText: 'Expand',
                  }
        );
    }

    if (options.relatedLeads) {
        toolbarOptions.push({
            id: 'related-leads',
            component: options.relatedLeads.toolbarButton,
        });
    }

    return toolbarOptions;
};

export const getStatusForQueuedEmail = (
    emailQueued: EmailQueued,
    onRetryQueuedEmail: (string) => Promise<*>
): TimelineEntryStatus => {
    const status = {};
    if (emailQueued.isFailed && emailQueued.canRetry) {
        status.primary = {level: 'alert', text: 'Failed'};
        status.action = {
            buttonText: 'Retry',
            onClick: (e: SyntheticEvent<*>) => {
                e.stopPropagation(); // Prevent expand action on timeline entry card
                onRetryQueuedEmail(emailQueued.id);
            },
        };
    } else if (emailQueued.isFailed) {
        status.primary = {level: 'alert', text: 'Failed'};
    } else {
        status.primary = {level: 'info', text: 'Outbound email'};
    }

    return status;
};

export const isEmailBodyHtmlExpandable = (bodyHtml: string): boolean => {
    // List of some HTML characters that would be included in tje bodyHtml of an email
    // that we would want to be able to expand
    const SPECIAL_HTML_CHARS = ['<br>', '<br />', '<img', '<ul>'];

    let isExpandable = false;

    SPECIAL_HTML_CHARS.forEach((specialChar) => {
        if (bodyHtml.includes(specialChar)) {
            isExpandable = true;
        }
    });

    return isExpandable;
};

export const shouldEmailAppearOnLeadTimeline = (
    emailRelatedLeads: ?RelatedLeads,
    entityPageId: ?string
): boolean => {
    // Email entries will be pulled for lead timelines, but we don't want to show them if the
    // lead is not mapped in the email lead associations mapping for the email
    if (
        emailRelatedLeads &&
        emailRelatedLeads.edges &&
        emailRelatedLeads.edges.length &&
        entityPageId &&
        entityPageId.includes('leads')
    ) {
        const leadAssociation = emailRelatedLeads.edges.find(
            // find lead association that matches current entity page (there can be only one)
            (relatedLeadEdge) => relatedLeadEdge.node.id === entityPageId
        );

        // if the association exists and is mapped, the lead does not need to be filtered out
        return Boolean(leadAssociation && leadAssociation.isMapped);
    }

    // Case shouldn't be hit, but if it is, we'll return true so the email is not filtered out
    return true;
};
