/* @flow */

import * as React from 'react';

import classnames from 'classnames';
import _ from 'underscore';
import moment from 'moment';

import {isEqualDate} from 'nutshell-core/date-time';
import {TickCircleIcon} from '../icon';
import './options.css';

const today = (): Date => moment().startOf('day').toDate();
const tomorrow = (): Date => moment(today()).add(1, 'day').toDate();
const yesterday = (): Date => moment(today()).subtract(1, 'day').toDate();
const endOfWeek = (): Date => moment().endOf('week').startOf('day').toDate();
const endOfMonth = (): Date => moment().endOf('month').startOf('day').toDate();

export type CannedOption = 'yesterday' | 'today' | 'tomorrow' | 'end-of-week' | 'end-of-month';

type Option = {
    id: CannedOption,
    label: string,
    value: () => Date,
};

const OPTIONS: Option[] = [
    {
        id: 'yesterday',
        label: 'Yesterday',
        value: yesterday,
    },
    {
        id: 'today',
        label: 'Today',
        value: today,
    },
    {
        id: 'tomorrow',
        label: 'Tomorrow',
        value: tomorrow,
    },
    {
        id: 'end-of-week',
        label: 'End of week',
        value: endOfWeek,
    },
    {
        id: 'end-of-month',
        label: 'End of month',
        value: endOfMonth,
    },
];

type Props = {
    type: 'creator-modal' | 'popover-picker',
    date: Date,
    onSelect: (Date) => void,
    options: CannedOption[],
};

export class CannedOptions extends React.PureComponent<Props> {
    static defaultProps = {
        options: ['today', 'tomorrow', 'end-of-week', 'end-of-month'],
    };

    render() {
        const optionsToDisplay = OPTIONS.reduce((accum, option) => {
            if (
                this.props.options.includes(option.id) &&
                // Avoid duplicate values
                !accum.find((opt) => isEqualDate(opt.value(), option.value()))
            ) {
                accum.push(option);
            }

            return accum;
        }, []);

        const selectedOption = _.find(optionsToDisplay, (option) => {
            return isEqualDate(option.value(), this.props.date);
        });

        const options = optionsToDisplay.map((option) => {
            const {id, label, value} = option;

            const isSelected = selectedOption ? selectedOption.id === id : false;

            const styleName = classnames('canned-option', {
                option: !isSelected,
                'option--selected': isSelected,
            });

            return (
                <div key={option.id} styleName={styleName}>
                    <input
                        checked={isSelected}
                        id={id}
                        name={id}
                        onChange={(e) => this.props.onSelect(e.target.value)}
                        styleName='radio'
                        type='radio'
                        value={value().toString()}
                    />
                    <label styleName='label' htmlFor={id}>
                        <span>{label}</span>
                        {isSelected ? (
                            <TickCircleIcon styleName='check-icon' size={20} />
                        ) : undefined}
                    </label>
                </div>
            );
        });

        return <div styleName={`container type-${this.props.type}`}>{options}</div>;
    }
}
