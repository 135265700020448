/* @flow */

import {useMutation} from '@apollo/react-hooks';

import type {
    updatePhonecallShouldSummarize as updatePhonecallShouldSummarizeMutation,
    updatePhonecallShouldSummarizeVariables,
    PhoneCallSetShouldSummarizeInput,
} from 'nutshell-graphql-types';

import {safelyGetGraphQLErrorMessage} from 'nutshell-core/utils/graphql-errors';

import UPDATE_PHONECALL_SHOULD_SUMMARIZE from '../mutations/update-phonecall-should-summarize.graphql';

export const useUpdatePhonecallShouldSummarize = (): ({
    updatePhonecallShouldSummarize: (PhoneCallSetShouldSummarizeInput) => Promise<*>,
    isLoading: boolean,
    error: ?string,
}) => {
    const [updatePhonecallShouldSummarize, {loading, error}] = useMutation<
        updatePhonecallShouldSummarizeMutation,
        updatePhonecallShouldSummarizeVariables,
    >(UPDATE_PHONECALL_SHOULD_SUMMARIZE);

    return {
        updatePhonecallShouldSummarize: (input: PhoneCallSetShouldSummarizeInput) =>
            updatePhonecallShouldSummarize({variables: {input}}),
        isLoading: loading,
        error: safelyGetGraphQLErrorMessage(error),
    };
};
