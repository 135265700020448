/* @flow */

import type {Dispatch} from 'redux';
import {createAction} from 'redux-act';

import type {ListSchemaResponse} from 'nutshell-core/schema/schema-types';
import * as Schema from 'nutshell-core/schema';

import type {
    ConnectedFormsSchemaRequestedAction,
    ConnectedFormsSchemaUpdatedAction,
    SetActiveFieldIdAction,
    ReportDataRequestedAction,
} from './types';

/*
 * Action creators
 */
export const setActiveFieldId = (activeFieldId: ?string): SetActiveFieldIdAction => ({
    type: 'ACTIVE_FIELD_ID_SET',
    payload: {activeFieldId},
});

export const requestReportData: (
    payload: $PropertyType<ReportDataRequestedAction, 'payload'>
) => ReportDataRequestedAction = createAction('CONNECTED_FORMS_REPORT_DATA_REQUESTED');
export const updateReportChartData = createAction('CONNECTED_FORMS_REPORT_CHART_DATA_UPDATED');
export const updateReportMaps = createAction('CONNECTED_FORMS_REPORT_MAPS_UPDATED');
export const failReportChartData = createAction('CONNECTED_FORMS_REPORT_CHART_DATA_FAILED');

function fetchConnectedFormsSchema() {
    return Schema.connectedFormsFetch();
}

export const requestConnectedFormsSchema = (): ConnectedFormsSchemaRequestedAction => ({
    type: 'CONNECTED_FORMS_SCHEMA_REQUESTED',
});

export const updateConnectedFormsSchema = (
    schemaResponse: ListSchemaResponse
): ConnectedFormsSchemaUpdatedAction => ({
    type: 'CONNECTED_FORMS_SCHEMA_UPDATED',
    payload: schemaResponse,
});

export function fetchConnectedFormsSchemaAction() {
    return function (dispatch: Dispatch<*>) {
        dispatch(requestConnectedFormsSchema());

        return fetchConnectedFormsSchema().then((json) => {
            dispatch(updateConnectedFormsSchema(json));
        });
    };
}
