/* @flow */

import * as React from 'react';

import {NutAnalyticsClient} from 'nutshell-core/nut-analytics-client';

import {TwoPaneModal} from '../../modal/two-pane-modal';
import {ModalHeader} from '../../typography/modal-header';
import {Body} from '../../typography/body';
import {BulletedList} from '../../bulleted-list';
import {colors} from '../../colors';
import {Button} from '../../button';
import {BankSafe} from '../../illustrations/bank-safe';
import {UpgradeArrowIcon} from '../../icon/svg/upgrade-arrow-icon';
import {featureMessages, type FeatureName} from './features-list';
import {FeatureGateButton} from './feature-gate-button';

import './feature-gate-modal.css';

type Props = {
    isOpen?: boolean,
    onClose: () => void,
    title: string,
    description: string,
    bullets: string[],
    isAdmin: boolean,
    feature: FeatureName,
    shouldShowButton?: boolean,
    icon?: any,
    analyticsClient?: NutAnalyticsClient,
};

export function FeatureGateModal(props: Props) {
    const {isAdmin, feature, analyticsClient} = props;
    const bullets = props.bullets.map((bullet) => <Body key={bullet}>{bullet}</Body>);
    const closingMessage = featureMessages[props.feature];
    // for sms gates, we don't want to direct user to billing page if the
    // company already has licenses, regardless of if they have access to
    // billing.
    const shouldShowButton =
        typeof props.shouldShowButton === 'boolean' ? props.shouldShowButton : isAdmin;

    React.useEffect(() => {
        if (analyticsClient && feature && props.isOpen) {
            analyticsClient.track(analyticsClient.EVENTS.TRACK_FEATURE_GATE_VIEWED, {
                feature,
                isAdmin,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isOpen]);

    return (
        <TwoPaneModal
            isOpen={props.isOpen}
            maxHeight={600}
            onClose={props.onClose}
            closeButton={!props.isAdmin}
            leftSection={
                <div styleName='left'>
                    <div styleName='modal-image'>{props.icon || <BankSafe />}</div>
                </div>
            }
            rightSection={
                <div styleName='container'>
                    <ModalHeader>
                        <div className='flex gap-8 align-center'>
                            <UpgradeArrowIcon size={20} wrapWithDiv={false} color={colors.blue} />
                            <Body size='large' isBold={true}>
                                {props.title}
                            </Body>
                        </div>
                    </ModalHeader>
                    <div className='mb-16'>
                        <Body>{props.description}</Body>
                    </div>
                    <BulletedList bullets={bullets} />
                    <Body>{closingMessage}</Body>
                    {shouldShowButton && (
                        <div styleName='footer'>
                            <Button
                                variant='text-secondary'
                                size='normal'
                                onClick={props.onClose}
                                noPadding={true}
                            >
                                Not right now
                            </Button>
                            <FeatureGateButton onClick={props.onClose} />
                        </div>
                    )}
                </div>
            }
        />
    );
}
