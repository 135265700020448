/* @flow */

import * as React from 'react';
import {components, type SingleValueProps, type OptionProps} from 'react-select';

import type {Assignable} from 'nutshell-core/types';

import {SelectPickerGeneric} from '../select-picker-generic';
import {Avatar} from '../avatar';
import {ContactAssigneeIcon} from '../icon';

import './assignee-select.css';

export type CustomOption = {|id: string, name: string|};

type Props = {|
    inputId?: string,
    options: $ReadOnlyArray<Assignable>,
    customOptions?: CustomOption[],
    value: ?string, // ID
    onChange: (id: ?string) => void,
    isClearable: boolean,
    placeholder?: string,
    isDisabled?: boolean,
    withControlIcon?: boolean,
    hideDividerWhenEmpty?: boolean,
    minHeight?: number,
    menuPlacement?: 'auto' | 'bottom' | 'top',
|};

type Option = {
    value: Assignable | CustomOption,
    label: string,
};

/**
 * Use this to render a dropdown to choose a single assignee.
 */
export class AssigneeSelect extends React.PureComponent<Props> {
    static defaultProps = {
        placeholder: 'Choose an assignee…',
        isClearable: true,
        menuPlacement: 'auto',
    };

    render() {
        let options: Option[] = this.props.options.map((item) => ({
            value: item,
            label: item.name,
            isDisabled: item.isEnabled === false,
        }));

        if (this.props.customOptions) {
            options = this.props.customOptions
                .map((item) => ({
                    value: item,
                    label: item.name,
                }))
                .concat(options);
        }

        const selectedValue = options.find((opt) => opt.value.id === this.props.value);

        let controlIcon;
        if (this.props.withControlIcon) {
            // When selected value is a user, we show the avatar - so no need for another icon
            if (!selectedValue || (selectedValue && selectedValue.value.type === 'teams')) {
                controlIcon = ContactAssigneeIcon;
            }
        }

        return (
            <SelectPickerGeneric
                inputId={this.props.inputId}
                backspaceRemovesValue={false}
                openMenuOnFocus={true}
                menuShouldScrollIntoView={true}
                value={selectedValue && selectedValue.value}
                onChange={this.handleChange}
                onClear={this.props.isClearable ? this.handleClear : undefined}
                options={options}
                isDisabled={this.props.isDisabled}
                blurInputOnSelect={true}
                isOptionSelected={this.isOptionSelected}
                placeholder={this.props.placeholder}
                styles={{
                    menu: (base) => ({...base, zIndex: 5}),
                }}
                components={{
                    Option: OptionComponent,
                    SingleValue: SingleValueComponent,
                }}
                controlIcon={controlIcon}
                hideDividerWhenEmpty={this.props.hideDividerWhenEmpty}
                minHeight={this.props.minHeight}
                menuPlacement={this.props.menuPlacement}
            />
        );
    }

    handleChange = (newOption: Option) => {
        this.props.onChange(newOption.value.id);
    };

    handleClear = () => {
        this.props.onChange(null);
    };

    isOptionSelected = (option: Option, selectedValues: Option[]) => {
        return option.value === selectedValues[0];
    };
}

const SingleValueComponent = (props: SingleValueProps) => {
    return (
        <components.SingleValue {...props}>
            <AssignableComponent assignable={props.data} />
        </components.SingleValue>
    );
};

const OptionComponent = (props: OptionProps) => {
    return (
        <components.Option {...props}>
            <AssignableComponent assignable={props.data.value} />
        </components.Option>
    );
};

type AssignableComponentProps = {
    assignable: Assignable | CustomOption,
};

const AssignableComponent = ({assignable}: AssignableComponentProps) => (
    <div styleName='option-container'>
        {assignable.type && assignable.type === 'users' ? (
            <Avatar
                avatarUrl={assignable.avatarUrl}
                initials={assignable.initials}
                size={20}
                type={assignable.type}
                isDisabled={!assignable.isEnabled}
            />
        ) : null}
        <div
            styleName='option-title'
            className={
                assignable.type === 'users' && !assignable.isEnabled
                    ? 'light-text strikethrough'
                    : undefined
            }
        >
            {assignable.name}
        </div>
    </div>
);
