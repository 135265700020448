import Backbone from 'backbone';
import _ from 'underscore';
import Bacon from 'baconjs';

import Template from './email-template';

export const EmailTemplates = Backbone.Collection.extend({
    remote: true,
    model: Template,
    url: '/rest/emailtemplates',

    initialize: function (items, options) {
        this.options = options;
        this.fetch = this.fetch.bind(this);
    },

    fetch: function (options = {}) {
        const normalizedOptions = _.defaults(options, {
            data: _.pick(this.options || {}, 'userId'),
            processData: true,
        });

        return Backbone.Collection.prototype.fetch.apply(this, [normalizedOptions]);
    },

    comparator: function (model) {
        return (model.get('name') || model.get('subject') || '').toLowerCase();
    },

    parse: function (response) {
        return response.emailTemplates;
    },
});

export function stream(userId) {
    return Bacon.fromBinder((sink) => {
        const emailTemplatesCollection = new EmailTemplates([], {userId});
        sink({fetch: emailTemplatesCollection.fetch});
        emailTemplatesCollection.on('add remove change', () =>
            sink(
                new Bacon.Next(() => ({
                    fetch: emailTemplatesCollection.fetch,
                    models: emailTemplatesCollection.models,
                }))
            )
        );

        return () => emailTemplatesCollection.off('add remove change');
    });
}
