/* @flow */

import * as React from 'react';

import type {GetAccountBilling_accountBilling_currentPlan_crm_coupon as Coupon} from 'nutshell-graphql-types';

import {PopoverInfo} from '../../popover-info';
import {FormattedTimestamp} from '../../timestamp';
import {Stack} from '../../layout';

import './coupon-pill.css';

type Props = {coupon: Coupon};

export function CouponPill(props: Props) {
    const appliedTime = props.coupon.appliedAt;
    const expiresTime = props.coupon.expiresAt;

    const moreInfo = (appliedTime || expiresTime) && (
        <div styleName='info'>
            <Stack spacing={8}>
                {appliedTime && (
                    <div className='flex flex-wrap'>
                        <span>’{props.coupon.name}’ applied on&nbsp;</span>
                        <FormattedTimestamp
                            format={FormattedTimestamp.formats.longMonthDay}
                            datetime={appliedTime}
                        />
                    </div>
                )}
                {expiresTime && (
                    <div className='flex flex-wrap italic'>
                        <span>Expires&nbsp;</span>
                        <FormattedTimestamp
                            format={FormattedTimestamp.formats.longMonthDayWithYear}
                            datetime={expiresTime}
                        />
                    </div>
                )}
            </Stack>
        </div>
    );

    return (
        <div styleName='container'>
            {props.coupon.name}{' '}
            {moreInfo && <PopoverInfo size={16} info={moreInfo} variant='green' bodyColor='dark' />}
        </div>
    );
}
