/* @flow */
import * as React from 'react';

import {Sidebar} from '../sidebar';
import {Button} from '../button';
import {ConfirmationDialog} from '../confirmation-dialog';
import {FlashBanner} from '../flash-banner';

import {CrossBrowserHiddenFormSubmit} from './cross-browser-hidden-form-submit';

type Props = {
    title: string,
    subtitle?: string,
    onClose: () => any,
    deleteProps?: {onDelete: () => Promise<*>, buttonText?: string, title: string, message: string},
    children: React.Node,
    isNewItem: boolean,
    isSaving: boolean,
    saveFailedErrorMessage: ?string,

    // These props come from redux-form
    handleSubmit: (eventOrSubmit: any) => void | Promise<*>,
    invalid: boolean,
    pristine: boolean,
    submitting: boolean,
};

// Generic sidebar form wrapper, expected to be wrapped in a redux-form provider!
export function SidebarFormWrapper(props: Props) {
    const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState<boolean>(false);

    const {deleteProps} = props;

    return (
        <form onSubmit={props.handleSubmit}>
            <Sidebar
                isOpen={true}
                headerTitle={props.title}
                subtitle={props.subtitle}
                preventBodyScroll={false}
                canClickOutsideToClose={false}
                canEscapeToClose={true}
                onClose={props.onClose}
                onSave={props.handleSubmit}
                isSaving={props.submitting || props.isSaving}
                submitDisabled={
                    // True when the form is adding a new item and an option has not been selected from first select
                    (props.pristine && props.isNewItem) ||
                    // True when the form is actively submitting
                    props.submitting ||
                    // True when form is actively saving
                    props.isSaving ||
                    // True when the form is invalid (does not work on first select, or drip delay time)
                    props.invalid
                }
                customPositionAttributes={{
                    display: 'flex',
                    flexDirection: 'column',
                    right: 0,
                    top: 0,
                }}
            >
                {props.saveFailedErrorMessage ? (
                    <FlashBanner type='error' leftAlignContent={true}>
                        {props.saveFailedErrorMessage}
                    </FlashBanner>
                ) : undefined}
                <div className='flex flex-dir-col justify-sb flex-grow'>
                    {props.children}
                    <CrossBrowserHiddenFormSubmit />
                    {deleteProps ? (
                        <div className='full-width flex justify-end pad-16 border-box'>
                            <Button
                                onClick={() => {
                                    setShowDeleteConfirmation(true);
                                }}
                                variant='danger'
                                size='big'
                                isFullWidth={true}
                            >
                                {deleteProps.buttonText || 'Delete'}
                            </Button>
                        </div>
                    ) : undefined}
                </div>
                {showDeleteConfirmation && deleteProps ? (
                    <ConfirmationDialog
                        title={deleteProps.title}
                        message={deleteProps.message}
                        confirmText='Delete'
                        cancelText='Cancel'
                        onCancel={() => {
                            setShowDeleteConfirmation(false);
                        }}
                        onConfirm={() => {
                            setShowDeleteConfirmation(false);
                            deleteProps.onDelete();
                        }}
                        isConfirmDestructive={true}
                    />
                ) : undefined}
            </Sidebar>
        </form>
    );
}
