/* @flow */

import React from 'react';
import {Link as RoutedLink} from 'react-router-dom';

import {Link} from '../../link';
import {colors} from '../../colors';
import {UpgradeArrowIcon} from '../../icon/svg/upgrade-arrow-icon';

type Props = {
    onClick?: () => void,
    variant?: 'upgrade' | 'licenses',
};

export function FeatureGateButton(props: Props) {
    const {variant = 'upgrade'} = props;

    return (
        <Link
            as={RoutedLink}
            size='big'
            variant='button-primary'
            to={variant === 'upgrade' ? '/setup/billing-checkout' : '/setup/user'}
            onClick={props.onClick}
        >
            <div className='flex align-center gap-8'>
                {variant === 'upgrade' && (
                    <UpgradeArrowIcon size={12} wrapWithDiv={false} color={colors.blue} />
                )}
                {variant === 'upgrade' ? 'Upgrade plan' : 'Assign licenses'}
            </div>
        </Link>
    );
}
