/* @flow */

import * as React from 'react';
import _ from 'underscore';
import {Popover} from '../popover';
import './limited-group.css';

type Props = {
    models: React.Node[],
    numDisplayed: number | string,
    isHoverable?: boolean,
    shouldTruncate?: boolean,
    includeMoreWord?: boolean,
    shouldFlex?: boolean,
};

type State = {
    popoverIsOpen: boolean,
};

export class LimitedGroup extends React.PureComponent<Props, State> {
    hiddenAnchor: ?HTMLElement;

    constructor() {
        super();
        this.state = {
            popoverIsOpen: false,
        };
    }

    render() {
        const numDisplayed = Number(this.props.numDisplayed);
        const shownStyle = this.props.shouldTruncate ? 'shown-truncate' : 'shown';
        const containerStyle = this.props.shouldTruncate ? 'container-truncate' : 'container';

        if (this.props.models.length <= numDisplayed) {
            return (
                <div styleName={containerStyle}>
                    <div styleName={shownStyle} className={this.props.shouldFlex ? 'flex' : ''}>
                        {this.props.models}
                    </div>
                </div>
            );
        }

        const shownModels = _.first(this.props.models, numDisplayed);
        const hiddenModels = _.last(this.props.models, this.props.models.length - numDisplayed);

        return (
            <div styleName={containerStyle}>
                <div styleName={shownStyle} className={this.props.shouldFlex ? 'flex' : ''}>
                    {shownModels}
                </div>
                <div styleName={this.props.isHoverable ? 'hidden--hoverable' : 'hidden'}>
                    <span
                        ref={(c) => {
                            this.hiddenAnchor = c;
                        }}
                        onMouseOver={this.handleMouseOver}
                        onMouseOut={this.handleMouseOut}
                    >
                        + {hiddenModels.length}
                        {this.props.includeMoreWord ? ' more' : ''}
                    </span>
                    {this.state.popoverIsOpen ? (
                        <Popover
                            className='ui-popover ui-popover--md'
                            location='bottom'
                            anchor={this.hiddenAnchor}
                        >
                            {hiddenModels}
                        </Popover>
                    ) : undefined}
                </div>
            </div>
        );
    }

    handleMouseOver = () => {
        if (this.props.isHoverable) {
            this.setState({popoverIsOpen: true});
        }
    };

    handleMouseOut = () => {
        this.setState({popoverIsOpen: false});
    };
}
