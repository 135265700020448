/* @flow */

import * as React from 'react';

import type {GetUsageScorecard_usageScorecard_sections_features as Feature} from 'nutshell-graphql-types';

import {UsageScorecardRow} from './usage-scorecard-row';
import {UsageScorecardSectionHeader} from './usage-scorecard-section-header';

type Props = {
    sectionName: string,
    features: Feature[],
};

export function UsageScorecardSection(props: Props) {
    return (
        <div>
            <UsageScorecardSectionHeader primaryText={props.sectionName} />
            {props.features.map((feature) => {
                return <UsageScorecardRow key={feature.displayName} feature={feature} />;
            })}
        </div>
    );
}
