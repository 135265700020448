/* @flow */

import * as React from 'react';

import {PageHeader} from '../page-header';
import {SaveButton} from '../button';
import {ButtonGroup} from '../button-group';
import {Link} from '../link';

import './full-page-editor.css';

type Props = {
    title: string,
    onBack: () => void,
    backButtonText: string,
    previewPath?: string,
    previewButtonText?: string,
    onSaveClick: () => void,
    saveButtonText: string,
    isSaveDisabled?: boolean,
    savingText?: string,
    successText?: string,
    errorSaving?: boolean,
    isSaving: boolean,
    content: React.Node,
};

export function FullPageEditor(props: Props) {
    return (
        <>
            <PageHeader
                backButton={{
                    onClick: props.onBack,
                    text: props.backButtonText,
                }}
                title={props.title}
                actionButtons={
                    <ButtonGroup>
                        {props.previewPath && props.previewButtonText ? (
                            <Link
                                variant='button-secondary'
                                size='big'
                                href={props.previewPath}
                                newTab={true}
                            >
                                {props.previewButtonText}
                            </Link>
                        ) : undefined}
                        <SaveButton
                            isSaving={props.isSaving}
                            onClick={props.onSaveClick}
                            size='big'
                            buttonText={props.saveButtonText}
                            savingText={props.savingText || 'Saving…'}
                            successText={props.successText || 'Success!'}
                            errorSaving={props.errorSaving}
                            disabled={props.isSaveDisabled}
                        />
                    </ButtonGroup>
                }
            />
            <div styleName='content-container'>{props.content}</div>
        </>
    );
}
