/* @flow */

import * as React from 'react';

import {RemoveButton} from '../button';

import './select-two-line-icon.css';

export type RemoveButtonProps = {
    onClick: (id: string) => void,
};

type Props = {
    icon: React.ComponentType<*>,
    label: string,
    sublabel: string,
    isSelected?: boolean,
    shouldWrap?: boolean,
    onRemoveClick?: (e: SyntheticEvent<HTMLButtonElement>) => void,
};

export function IconAndLabel(props: Props) {
    const {icon, label, sublabel, shouldWrap = true} = props;
    const Icon = icon;

    const containerClass = shouldWrap ? '' : 'overflow-hidden';
    let textClass = shouldWrap ? '' : 'truncate';
    const labelTitle = shouldWrap ? undefined : label;
    const sublabelTitle = shouldWrap ? undefined : sublabel;

    // Add margin to the text if there is a remove button to make space for the button
    if (props.onRemoveClick) {
        textClass += ' mr-32';
    }

    return (
        <div className={`flex align-center ${containerClass}`}>
            <div className={`flex align-center ${containerClass}`}>
                {Icon ? (
                    <div styleName='icon-container'>
                        <Icon size={19} />
                    </div>
                ) : undefined}
                <div className={containerClass}>
                    <div className={textClass} styleName='label' title={labelTitle}>
                        {label}
                    </div>
                    <div className={textClass} styleName='sublabel' title={sublabelTitle}>
                        {sublabel}
                    </div>
                </div>
            </div>
            {!props.isSelected && props.onRemoveClick && (
                <div styleName='remove-button'>
                    <RemoveButton size={12} variant='dark' onClick={props.onRemoveClick} />
                </div>
            )}
        </div>
    );
}
