/* @flow */

import {useGetSessionUser} from '../../ui/core/use-get-session-user';

// Used to determine whether to show an onboarding wizard for a given user.
//
// Example usage:
// const {shouldShow} = useShouldShowWizard(flagName);
export const useShouldShowWizard = (
    wizardKey: string
): {
    shouldShow: boolean,
    isLoading: boolean,
} => {
    const {user, isLoading} = useGetSessionUser();

    const shouldShow = Boolean(
        user && user.completedWizards && !user.completedWizards.includes(wizardKey)
    );

    return {shouldShow, isLoading};
};
