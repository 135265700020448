/* @flow */

import * as React from 'react';
import classnames from 'classnames';

type RadioGroupContextProps = {|
    name: string,
    selectedValue: ?string,
    // $FlowFixMe upgrading Flow to v0.110.1
    onChange: (newValue: $ReadOnly<string>) => void,
    inline?: boolean,
    shouldSpaceHorizontally?: boolean,
|};

export type RadioGroupProps = {|
    ...RadioGroupContextProps,
    children: React.Node[],
    size?: 'normal' | 'big',
    spacing?: 8 | 16 | 32,
|};

const defaultContextValue = {name: 'default', selectedValue: null, onChange: () => {}};

export const RadioGroupContext = React.createContext<RadioGroupContextProps>(defaultContextValue);

export function RadioGroup({size = 'normal', children, spacing, ...props}: RadioGroupProps) {
    const className = classnames({
        flex: true,
        'flex-dir-col': !props.inline,
        'font-size-normal': size === 'big',
        [`gap-${spacing || 8}`]: !props.inline || props.shouldSpaceHorizontally,
    });

    return (
        <RadioGroupContext.Provider value={props}>
            <div className={className} role='radiogroup'>
                {children}
            </div>
        </RadioGroupContext.Provider>
    );
}
