/* @flow */

import {createReducer} from 'redux-act';
import {REHYDRATE} from 'redux-persist';
import moment from 'moment';
import get from 'lodash/get';
import _ from 'underscore';

import {getRehydratedValueForDateProperty} from 'nutshell-core/reducer-utils';
import {isToday, isBeforeToday, isTomorrow, isAfterTomorrow} from 'nutshell-core/date-time';
import * as actions from '../../dashboard-actions';
import {removeItemAndShiftIndices} from '../sidebar-helpers';
import {TODAY} from './todos-constants';

const defaultTodosState = {
    items: {},
    counts: {
        total: 0,
    },
    indices: {},
    tempIds: [],
};

const defaultTodosConfigState = {
    filter: '',
    queryString: '',
    displayDate: moment().startOf('day').valueOf(),
    periodSegmentWasManuallySelected: true,
    lastUpdated: null,
    periodSegmentSelected: TODAY,
};

const defaultTodoState = {
    id: undefined,
    type: undefined,
    title: undefined,
    completedTime: null,
    lastUpdated: 0,
    errorMessage: undefined,
    didInvalidate: false,
    isCompleting: false,
    isUncompleting: false,
    isSkipping: false,
    isDelaying: false,
};

export const todoReducer = createReducer(
    {
        [actions.requestTodoCompleteData]: (state) => {
            return {...state, isCompleting: true};
        },
        [actions.requestTodoUncompleteData]: (state) => {
            return {...state, isUncompleting: true};
        },
        [actions.requestTodoSkipData]: (state) => {
            return {...state, isSkipping: true};
        },
        [actions.requestTodoDelayData]: (state) => {
            return {...state, isDelaying: true};
        },
        [actions.clearTodoError]: (state) => {
            return {...state, errorMessage: undefined};
        },
        [actions.failTodoCompleteData]: (state, payload = 'Could not complete step') => {
            return {...state, isCompleting: false, errorMessage: payload};
        },
        [actions.failTodoUncompleteData]: (state, payload = 'Could not uncomplete step') => {
            return {...state, isUncompleting: false, errorMessage: payload};
        },
        [actions.failTodoSkipData]: (state, payload = 'Could not skip step') => {
            return {...state, isSkipping: false, errorMessage: payload};
        },
        [actions.failTodoDelayData]: (state, payload = 'Could not delay step') => {
            return {...state, isDelaying: false, errorMessage: payload};
        },
        [actions.updateTodoData]: (state, payload) => {
            return {
                ...state,
                isCompleting: false,
                isUncompleting: false,
                isDelaying: false,
                isSkipping: false,
                errorMessage: undefined,
                lastUpdated: new Date().getTime(),
                didInvalidate: false,
                ...payload,
            };
        },
    },
    defaultTodoState
);

export const sidebarTodosConfigReducer = createReducer(
    {
        [actions.updateTodoFilter]: (state, payload) => {
            const todayStart = moment().startOf('day').valueOf();

            return {
                ...state,
                filter: payload,
                displayDate: todayStart,
                periodSegmentSelected: TODAY,
                periodSegmentWasManuallySelected: true,
            };
        },
        [actions.updateTodoDisplayDate]: (state, payload) => {
            return {
                ...state,
                displayDate: payload.getTime(),
                lastUpdated: moment().valueOf(),
            };
        },
        [actions.updatePeriodSegmentWasManuallySelected]: (state, payload) => {
            return {
                ...state,
                periodSegmentWasManuallySelected: payload ? payload : false,
            };
        },
        [actions.updateTodoQueryString]: (state, payload) => {
            const todayStart = moment().startOf('day').valueOf();

            return {
                ...state,
                queryString: payload,
                displayDate: todayStart,
                periodSegmentSelected: TODAY,
                periodSegmentWasManuallySelected: true,
            };
        },
        [actions.updateTodoPeriodSegmentSelected]: (state, payload) => {
            return {...state, periodSegmentSelected: payload};
        },
        [REHYDRATE]: (state, persistedState) => {
            if (
                !persistedState ||
                !persistedState.dashboard ||
                !persistedState.dashboard.todosConfig
            ) {
                return state;
            }

            return {
                ...state,
                ...persistedState.dashboard.todosConfig,
                displayDate: getRehydratedValueForDateProperty(
                    persistedState.dashboard.todosConfig,
                    'displayDate'
                ),
                periodSegmentWasManuallySelected:
                    persistedState.dashboard.todosConfig.periodSegmentWasManuallySelected,
            };
        },
    },
    defaultTodosConfigState
);

export const todosReducer = createReducer(
    {
        [actions.updateTodosForDay]: (state, todos) => {
            const newTodos = todos.reduce(
                (newState, current) => {
                    newState[current.id] = todoReducer(
                        state.items[current.id],
                        actions.updateTodoData(current)
                    );

                    return newState;
                },
                {...state.items}
            );

            return {...state, items: newTodos};
        },
        [actions.updateTodoIndices]: (state, todoIdsByIndex) => {
            const indices = Object.keys(todoIdsByIndex);
            const newTodoIndices = indices.reduce(
                (newState, index) => {
                    newState[index] = get(todoIdsByIndex, index);

                    return newState;
                },
                {...state.indices}
            );

            const newTempIds = state.tempIds.filter((id) => {
                return !indices.find((index) => todoIdsByIndex[index] === id);
            });

            return {...state, indices: newTodoIndices, tempIds: newTempIds};
        },
        [actions.updateTodoCounts]: (state, payload) => {
            return {...state, counts: payload};
        },
        [actions.requestTodoCompleteDataById]: (state, todoId) => {
            const newTodos = {
                ...state.items,
                [todoId]: todoReducer(state.items[todoId], actions.requestTodoCompleteData()),
            };

            return {...state, items: newTodos};
        },
        [actions.requestTodoUncompleteDataById]: (state, todoId) => {
            const todo = state.items[todoId];

            const newTodos = {
                ...state.items,
                [todoId]: todoReducer(todo, actions.requestTodoUncompleteData()),
            };
            let newTempIds = state.tempIds;
            const newCounts = {...state.counts};

            // Uncompleting a step should remove the chain of subsequent steps from
            // the list as well.
            let nextStepId = todo.nextStepId;
            while (typeof nextStepId !== 'undefined') {
                const nextTodo = state.items[nextStepId];

                delete newTodos[nextStepId];
                newTempIds = newTempIds.filter((id) => id !== nextStepId); // eslint-disable-line no-loop-func

                newCounts.total = newCounts.total - 1;

                const dueTime = moment.unix(nextTodo.dueTime);
                if (isBeforeToday(dueTime)) newCounts.past = newCounts.past - 1;
                else if (isToday(dueTime)) newCounts.today = newCounts.today - 1;
                else if (isTomorrow(dueTime)) newCounts.tomorrow = newCounts.tomorrow - 1;
                else if (isAfterTomorrow(dueTime)) newCounts.future = newCounts.future - 1;

                nextStepId = nextTodo.nextStepId;
            }

            return {...state, counts: newCounts, tempIds: newTempIds, items: newTodos};
        },
        [actions.requestTodoSkipDataById]: (state, todoId) => {
            const newTodos = {
                ...state.items,
                [todoId]: todoReducer(state.items[todoId], actions.requestTodoSkipData()),
            };

            return {...state, items: newTodos};
        },
        [actions.requestTodoDelayDataById]: (state, todoId) => {
            const newTodos = {
                ...state.items,
                [todoId]: todoReducer(state.items[todoId], actions.requestTodoDelayData()),
            };

            return {...state, items: newTodos};
        },
        [actions.updateTodoDataById]: (state, todo) => {
            const todoAlreadyExists = state.items[todo.id];

            // If the todo was deleted, we need to remove it from the store.
            // Unfortunately we can't just request a new list from the server right away,
            // because it needs some time to reindex, which can take up to 5 seconds.
            if (todo.deletedTime) {
                // Remove deleted todo from items
                const newTodos = _.omit(state.items, todo.id);

                // Also remove todo from temporary ids
                const newTempIds = _.without(state.tempIds, todo.id);

                // Calculate new indices
                const indexToRemove = _.findKey(state.indices, (x) => x === todo.id);

                const newIndices =
                    typeof indexToRemove !== 'undefined'
                        ? removeItemAndShiftIndices(state.indices, indexToRemove)
                        : {...state.indices};

                // Calculate new counts
                const newCounts = {...state.counts, total: state.counts.total - 1};
                const dueTime = moment.unix(todo.dueTime);
                if (isBeforeToday(dueTime)) newCounts.past = newCounts.past - 1;
                else if (isToday(dueTime)) newCounts.today = newCounts.today - 1;
                else if (isTomorrow(dueTime)) newCounts.tomorrow = newCounts.tomorrow - 1;
                else if (isAfterTomorrow(dueTime)) newCounts.future = newCounts.future - 1;

                return {
                    ...state,
                    items: newTodos,
                    indices: newIndices,
                    counts: newCounts,
                    tempIds: newTempIds,
                };
            } else {
                // If the todo is not deleted, update its details
                const newTodos = {
                    ...state.items,
                    [todo.id]: todoReducer(state.items[todo.id], actions.updateTodoData(todo)),
                };

                if (todoAlreadyExists) {
                    return {...state, items: newTodos};
                } else {
                    const tempIds = state.tempIds.concat([todo.id]);
                    const newCounts = {...state.counts, total: state.counts.total + 1};
                    const dueTime = moment.unix(todo.dueTime);
                    if (isBeforeToday(dueTime)) newCounts.past = newCounts.past + 1;
                    else if (isToday(dueTime)) newCounts.today = newCounts.today + 1;
                    else if (isTomorrow(dueTime)) newCounts.tomorrow = newCounts.tomorrow + 1;
                    else if (isAfterTomorrow(dueTime)) newCounts.future = newCounts.future + 1;

                    return {
                        ...state,
                        items: newTodos,
                        tempIds: tempIds,
                        counts: newCounts,
                    };
                }
            }
        },
        [actions.failTodoCompleteDataById]: (state, {id, errorMessage}) => {
            const newTodos = {
                ...state.items,
                [id]: todoReducer(state.items[id], actions.failTodoCompleteData(errorMessage)),
            };

            return {...state, items: newTodos};
        },
        [actions.failTodoUncompleteDataById]: (state, {id, errorMessage}) => {
            const newTodos = {
                ...state.items,
                [id]: todoReducer(state.items[id], actions.failTodoUncompleteData(errorMessage)),
            };

            return {...state, items: newTodos};
        },
        [actions.failTodoSkipDataById]: (state, {id, errorMessage}) => {
            const newTodos = {
                ...state.items,
                [id]: todoReducer(state.items[id], actions.failTodoSkipData(errorMessage)),
            };

            return {...state, items: newTodos};
        },
        [actions.failTodoDelayDataById]: (state, {id, errorMessage}) => {
            const newTodos = {
                ...state.items,
                [id]: todoReducer(state.items[id], actions.failTodoDelayData(errorMessage)),
            };

            return {...state, items: newTodos};
        },
        [actions.clearTodoErrorById]: (state, todoId) => {
            const newTodos = {
                ...state.items,
                [todoId]: todoReducer(state.items[todoId], actions.clearTodoError()),
            };

            return {...state, items: newTodos};
        },
        [actions.updateTodoFilter]: (state) => {
            return {...state, indices: {}, items: {}, counts: {}, tempIds: []};
        },
        [actions.updateTodoQueryString]: (state) => {
            return {...state, indices: {}, items: {}, counts: {}, tempIds: []};
        },
        [REHYDRATE]: (state, persistedState) => {
            if (!persistedState || !persistedState.dashboard || !persistedState.dashboard.todos)
                return state;

            return {
                ...state,
                counts: persistedState.dashboard.todos.counts,
            };
        },
    },
    defaultTodosState
);
