/* @flow */

import * as React from 'react';
import {StarIcon, FxIcon, LoadingIcon} from 'shells/icon';
import {colors, type ColorName} from 'shells/colors';

import './option-with-count.css';

export type ListOptionWithCount = {
    value: string,
    label: string,
    favorite?: boolean,
    isFx?: boolean,
    count: ?number,
    isSyncing?: boolean,
    requiresSync?: boolean,
    __isNew__?: boolean,
    colorName?: ColorName,
};

type Props = {
    title: string,
    label: string,
    favorite?: boolean,
    isFx?: boolean,
    isSyncing?: boolean,
    count: ?number,
};

/**
 * Option component for a GraphQL select object that contains
 * a count for each item in the dropdown.
 */
export function OptionWithCount(props: Props) {
    return (
        <div styleName='option'>
            {props.favorite !== undefined ? (
                <div styleName='favorite-icon' style={{opacity: props.favorite ? 1 : 0}}>
                    <StarIcon />
                </div>
            ) : undefined}
            {props.isFx ? (
                <div styleName='fx-icon' style={{opacity: props.isFx ? 1 : 0}}>
                    <FxIcon size={19} />
                </div>
            ) : undefined}
            <div styleName='label'>{props.label}</div>
            {typeof props.count === 'number' && !props.isSyncing ? (
                <div title={props.title} styleName='count'>
                    {props.count.toLocaleString()}
                </div>
            ) : undefined}
            {props.isSyncing ? (
                <div
                    styleName='loading-icon'
                    title='We’re currently processing this audience and it should be ready for sending shortly'
                    style={{opacity: props.isSyncing ? 1 : 0}}
                >
                    <LoadingIcon fill={colors.greyLt} size={19} />
                </div>
            ) : undefined}
        </div>
    );
}
