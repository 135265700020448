/* @flow */

import * as React from 'react';

import {FileUploadButton} from '../button';
import {getFileIcon} from '../icon';

import './file-upload.css';

type File = {
    name: string,
    size: string,
    type: string,
};

type Props = {
    id: string,
    name: string,
    accept: string,
    maxFileSize?: string,
    onChange?: (newFile: File) => void,
    enableDropping?: boolean,
};

export function FileUpload(props: Props) {
    const [file, setFile] = React.useState('');

    return (
        <div styleName='file-upload'>
            <FileUploadButton
                accept={props.accept}
                name={props.name}
                id={props.id}
                enableDropping={props.enableDropping}
                onChange={(uploadedFile) => {
                    setFile(uploadedFile);

                    if (props.onChange) {
                        props.onChange(uploadedFile);
                    }
                }}
            />
            {file ? (
                <SelectedFile file={file} />
            ) : (
                <div styleName='file-text'>
                    <i>No file chosen</i>
                    {props.maxFileSize ? (
                        <i>&nbsp;– Max file size: {props.maxFileSize}</i>
                    ) : undefined}
                </div>
            )}
        </div>
    );
}

function SelectedFile({file}: {file: File}) {
    const IconComponent = getFileIcon(file.type);

    return (
        <div styleName='selected-file'>
            <IconComponent size={14} alignCenter={true} /> {file.name}
        </div>
    );
}
