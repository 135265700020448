/* @flow */

import {Observable} from 'rxjs/Observable';
import type {ActionsObservable} from 'redux-observable';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';

import type {ContactsApiResponse} from '../types';
import {fetchContactById} from './contacts-api';
import {
    ActionTypes,
    resolveFetchById,
    failFetchById,
    type ContactsAction,
    type ActionFetchByIdRequest,
} from './contacts-actions';

type FetchContactById = (contactId: string) => Promise<ContactsApiResponse>;

// Need this to be a factory so we can inject a different api method for the chrome extension
export const makeFetchContactByIdEpic =
    (fetch: FetchContactById) => (action$: ActionsObservable<ContactsAction>) =>
        action$
            .ofType(ActionTypes.CONTACTS_FETCH_BY_ID_REQUEST)
            .switchMap((action: ActionFetchByIdRequest) => {
                return Observable.fromPromise(fetch(action.payload))
                    .map((response: ContactsApiResponse) => resolveFetchById(response))
                    .catch((error) => Observable.of(failFetchById(error)));
            });

export const fetchContactByIdEpic = makeFetchContactByIdEpic(fetchContactById);
