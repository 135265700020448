/* @flow */

import {useMutation} from '@apollo/react-hooks';

import type {
    SelectCreateTag as SelectCreateTagMutation,
    SelectCreateTagVariables as SelectCreateTagMutationVariables,
    CreateTagInput,
} from 'nutshell-graphql-types';
import {safelyGetGraphQLErrorMessage} from 'nutshell-core/utils/graphql-errors';

import {bustManagedCollectionCache} from '../../../../util/bust-managed-collection-cache';

import CREATE_TAG from '../mutations/create-tag.graphql';
import GET_TAGS from '../queries/tags.graphql';

import {GET_TAGS_LIMIT} from './use-get-tags';

export function useCreateTag(): {
    createTag: (input: CreateTagInput) => Promise<any>,
    isCreating: boolean,
    errorMessage: ?string,
} {
    const [tagCreate, {loading, error}] = useMutation<
        SelectCreateTagMutation,
        SelectCreateTagMutationVariables,
    >(CREATE_TAG);

    return {
        createTag: (input: CreateTagInput) => {
            return tagCreate({
                variables: {tag: input},
                update: (proxy, mutationResults) => {
                    const newTag =
                        mutationResults &&
                        mutationResults.data &&
                        mutationResults.data.createTag &&
                        mutationResults.data.createTag.tag;

                    const entityType = input.tag.entityType;

                    const cacheResult = proxy.readQuery({
                        query: GET_TAGS,
                        variables: {
                            first: GET_TAGS_LIMIT,
                            input: {
                                entityType,
                            },
                        },
                    });

                    if (cacheResult && newTag) {
                        proxy.writeQuery({
                            query: GET_TAGS,
                            variables: {
                                first: GET_TAGS_LIMIT,
                                input: {
                                    entityType,
                                },
                            },
                            data: {
                                ...cacheResult,
                                tags: {
                                    ...cacheResult.tags,
                                    edges: [
                                        ...cacheResult.tags.edges,
                                        {
                                            __typename: 'TagsEdge',
                                            node: newTag,
                                        },
                                    ],
                                },
                            },
                        });
                    }
                },
            }).then(() => {
                bustManagedCollectionCache('tags');
            });
        },
        isCreating: loading,
        errorMessage: safelyGetGraphQLErrorMessage(error),
    };
}
