/* @flow */

import * as React from 'react';

import type {EventFragmentSparse} from 'nutshell-graphql-types';

import {ViewFormSubmissionModal} from '../../peep/form-submissions/view-form-submission-modal';
import {TimelineBanner} from './timeline-banner';

import './timeline-banner.css';

type Props = {|event: EventFragmentSparse|};

export function TimelineBannerFormSubmission(props: Props) {
    const {event} = props;

    const [isShowingModal, setIsShowingModal] = React.useState<boolean>(false);

    /**
     * We need to include this check, even though we are only passing an event
     * with a payload.__typename of 'Lead' because Apollo doesn't generate a specific
     * enough type when using a union type (like the TimelineEventPayload).
     * See this issue for more detail:
     * https://github.com/apollographql/apollo-tooling/issues/1223
     */
    if (event.payload.__typename === 'Submission') {
        const form = event.payload.formVersion.form;
        const submitter = event.payload.primaryEntity;

        const action = (
            <span>
                submitted&nbsp;
                <span
                    styleName='target--button'
                    role='button'
                    tabIndex='0'
                    onClick={() => setIsShowingModal(true)}
                >
                    {form.name}
                </span>
            </span>
        );

        return (
            <>
                <TimelineBanner
                    id={event.id}
                    iconVariant='form-submitted'
                    timestamp={event.changeTime}
                    // $FlowIgnore this will be a contact or account, but types don't line up perfectly
                    actor={submitter}
                    action={action}
                />
                {isShowingModal ? (
                    <ViewFormSubmissionModal
                        onClose={() => setIsShowingModal(false)}
                        submitter={submitter}
                        form={form}
                        submissionId={event.payload.id}
                        submissionTime={event.changeTime}
                    />
                ) : undefined}
            </>
        );
    }

    return null;
}
