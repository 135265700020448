/* @flow */

import * as React from 'react';

import {EntityPillSelectWrapperSmall} from '../entity-pill';

import './select-inline.css';

export type TokenStyle =
    | 'leads'
    | 'users'
    | 'teams'
    | 'contacts'
    | 'accounts'
    | 'dark'
    | 'light'
    | 'colorName'
    | 'none';

type Props = {
    data: Object,
    onRemove?: () => void,
    shouldStyleValueByType?: boolean,
    tokenizedStyle: TokenStyle,
    labelKey: string,
    valueKey: string,
    isTwoLine?: boolean,
};

export function ValueComponent(props: Props) {
    const {data, onRemove, shouldStyleValueByType, tokenizedStyle, labelKey, valueKey, isTwoLine} =
        props;
    // EntityPill expects id and name, but technically we
    // can pass any combination of label and valueKey to
    // this component. We'll map those values here.
    const entityPillData = {
        ...data,
        id: data[valueKey],
        name: data[labelKey],
    };

    if (shouldStyleValueByType) {
        return (
            <EntityPillSelectWrapperSmall
                type={data.type}
                value={entityPillData}
                sublabel={isTwoLine ? data.description : undefined}
                onRemove={onRemove}
            />
        );
    }

    if (tokenizedStyle) {
        return (
            <EntityPillSelectWrapperSmall
                type={tokenizedStyle}
                value={entityPillData}
                sublabel={isTwoLine ? data.description : undefined}
                onRemove={onRemove}
                colorName={tokenizedStyle === 'colorName' ? data.colorName : undefined}
            />
        );
    } else {
        // There is no removal option for untokenized items.
        // Only use untokenized styling for non-removable options
        return <span styleName='untokenized'>{entityPillData.name}</span>;
    }
}
