/* @flow */

import * as React from 'react';

import type {CouponFragment} from 'nutshell-graphql-types';

import {Body} from '../../typography';
import {Stack} from '../../layout';

import {CouponPill} from './coupon-pill';

type Props = {
    description: string,
    detail?: ?string,
    coupons?: CouponFragment[],
    customRow?: ?React.Node,
};

export function DescriptionCell(props: Props) {
    return (
        <td>
            <Stack spacing={4}>
                <Body as='div' size='large'>
                    {props.description}
                </Body>
                {props.detail && (
                    <Body as='div' color='light' size='small'>
                        {props.detail}
                    </Body>
                )}
                {props.coupons &&
                    props.coupons.map((coupon) => {
                        return <CouponPill key={coupon.code} coupon={coupon} />;
                    })}
                {props.customRow}
            </Stack>
        </td>
    );
}
